import { useEffect, useState } from "react";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  apiGroupDetailCondition, apiGroupMachinesDetail,
  apiGroupMachineManufacturers, apiGroupMachineTypes,
  apiGetMachineDetailInfo, apiUpdateMachineInGroup, apiUpdateMachineInGroupStatus, apiPostMachineInGroup
} from "@app/api";
import {
  IconCheck,
  IconWarning,
  IconError,
  IconInvalidUrl,
  IconEdit,
  IconDeleteRow,
  IconInputError,
  IconPlus,
} from "@app/components/icons";
import {
  ToggleButtonGroup,
  ToggleButton,
  Avatar,
  Button,
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  TextField,
  FormControl,
} from "@mui/material";
import { DataTable, DataPagination, DataDialog } from "@app/components/atoms";
import { GroupMachinesRequestDef } from "@app/types";
import {
  CURRENT_PAGE,
  LIMIT_PAGE,
  StorageKey,
  TOTAL_PAGE,
  TYPE_FILE_UPLOAD,
  UploadFileType,
} from "@app/constants";
import {
  formatNumberWithCommas,
  handleUploadStore,
  isMobile,
  resetIsContinuousLoading,
  scrollToTop,
  setItem,
} from "@app/helpers";
import { useSelector } from "react-redux";
import { RootState, setShowAlert, store } from "@app/store";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { MediaUploadMachine } from "../Components/InspectionForm/MediaUpload/MediaUploadMachine";
import { UploadOutlined, DownloadOutlined } from "@mui/icons-material";
import * as XLSX from "xlsx";

enum MACHINE_CONDITION {
  NORMAL = "NORMAL",
  WARNING = "WARNING",
  ERROR = "ERROR",
}

type MachineType = {
  machineCount: number;
  normalStatusCount: number;
  warningStatusCount: number;
  errorStatusCount: number;
};

type MachineFilterType = {
  label: React.ReactNode;
  key: keyof MachineType;
  machineCondition: MACHINE_CONDITION | "";
  count: number;
};

type machineType = {
  id: number;
  pictureUrl?: string;
  machineName?: string;
  machineCondition?: string;
  machineManufacturerName?: string;
  machineType?: string;
  modelAndType?: string;
  serialNumber?: string;
  serviceMeter?: number;
  reportCount?: number;
  serialNumberPlatePictureUrl?: string;
  actions?: number;

};

const dataMachineFilter: MachineFilterType[] = [
  {
    label: "all",
    key: "machineCount",
    machineCondition: "",
    count: 0,
  },
  {
    label: <IconCheck />,
    key: "normalStatusCount",
    machineCondition: MACHINE_CONDITION.NORMAL,
    count: 0,
  },
  {
    label: <IconWarning />,
    machineCondition: MACHINE_CONDITION.WARNING,
    key: "warningStatusCount",
    count: 0,
  },
  {
    label: <IconError />,
    machineCondition: MACHINE_CONDITION.ERROR,
    key: "errorStatusCount",
    count: 0,
  },
];

export function MachineTab() {
  const [dataMachineTabList, setDataMachineTabList] =
    useState(dataMachineFilter);
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [machineCondition, setMachineCondition] = useState<
    MACHINE_CONDITION | ""
  >("");
  const [dataCell, setDataCell] = useState<machineType>();
  const [rows, setRow] = useState<machineType[]>();
  const [field, setField] = useState<string>("machineName");
  const [order, setOrder] = useState<string | undefined>();
  const [pageInfo, setPageInfo] = useState({
    total: TOTAL_PAGE,
    page: CURRENT_PAGE,
    limit: LIMIT_PAGE,
  });
  const [open, setOpen] = useState(false);
  const [isInit, setIsInit] = useState(true);


  useEffect(() => {

  }, [rows]);

  useEffect(() => {

  }, [rows]);


  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<machineType | null>(null);

  const [openMachineDetails, setOpenMachineDetails] = useState(false);
  const [openMachineDetailsStatus, setOpenMachineDetailsStatus] = useState(false);
  const [recordId, setRecordId] = useState<string | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>, record: machineType) => {
    if (!id) {

      return;
    }

    const groupId = id;

    fetchMachineDetail(groupId, record.id.toString())
    fetchMachineManufacturers(groupId);
    fetchMachineTypes(groupId);
    setAnchorEl(event.currentTarget);
    setSelectedRow(record);
    setRecordId(record.id.toString())
  };

  useEffect(() => {

  }, [selectedRow]);

  const [isProcessing, setIsProcessing] = useState(false);

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEditMachine = () => {
    if (selectedRow) {
      setOpenMachineDetails(true);

    }
    handleCloseMenu();
  };

  const handleEditMachineStatus = () => {
    if (selectedRow) {
      setOpenMachineDetailsStatus(true)

    }
    handleCloseMenu();
  };


  const columns = [
    {
      key: "id",
      title: "",
      dataIndex: "id",
      render: (_: any, record: any) => {
        return (
          <>
            {record.pictureUrl ? (
              <Avatar
                variant="square"
                src={record.pictureUrl}
                imgProps={{
                  onError: () => {
                    const newRows = rows?.map((item) => {
                      if (item.id === record.id) {
                        item.pictureUrl = undefined;
                      }
                      return item;
                    });
                    setRow(newRows);
                  },
                  sx: {
                    objectFit: "contain",
                  },
                }}
              />
            ) : (
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconInvalidUrl />
              </Box>
            )}
          </>
        );
      },
      className: "pr-8",
      tableHeadingClassName: "d-none-imp",
      width: 56,
    },
    {
      key: "machineName",
      title: "machineTable.machineName",
      dataIndex: "machineName",
      sorted: true,
      tableHeadingColspan: 2,
      className: "pl-0 txt-pre-wrap",
      whiteSpace: "nowrap",
      render: (value: any, record: any) => {
        return (
          <Link
            style={{ paddingLeft: 0 }}
            className="link-style"
            to={`/groups/${id}/machines/${record.machineId}/inspection-history`}
          >
            {value}
          </Link>
        );
      },
      style: {
        minWidth: isMobile() ? 232 : 203,
      },
    },
    {
      key: "machineCondition",
      title: "machineTable.machineCondition",
      dataIndex: "machineCondition",
      align: "center",
      render: (value: MACHINE_CONDITION) => {
        switch (value) {
          case MACHINE_CONDITION.NORMAL:
            return <IconCheck />;
          case MACHINE_CONDITION.WARNING:
            return <IconWarning />;
          default:
            return <IconError />;
        }
      },
      whiteSpace: "nowrap",
      style: {
        minWidth: 105,
      },
    },
    {
      key: "machineManufacturerName",
      title: "machineTable.machineManufacturerName",
      dataIndex: "machineManufacturerName",
      sorted: true,
      whiteSpace: "nowrap",
      style: {
        minWidth: 154,
      },
      className: "txt-pre-wrap",
    },
    {
      key: "machineType",
      title: "machineTable.machineType",
      dataIndex: "machineType",
      sorted: true,
      style: {
        minWidth: isMobile() ? 217 : 205,
      },
      className: "txt-pre-wrap",
    },
    {
      key: "modelAndType",
      title: "machineTable.modelAndType",
      dataIndex: "modelAndType",
      sorted: true,
      style: {
        minWidth: 196,
      },
      className: "txt-pre-wrap",
    },
    {
      key: "serialNumber",
      title: "machineTable.serialNumber",
      dataIndex: "serialNumber",
      render: (value: string, record: any) => (
        <span
          onClick={() => handleCellClick(record)}
          className={
            record.serialNumberPlatePictureUrl && "has-text-link pointer"
          }
        >
          {value}
        </span>
      ),
      className: "serial-number txt-pre-wrap",
      style: {
        minWidth: isMobile() ? 193 : 200,
      },
    },
    {
      key: "serviceMeter",
      title: "machineTable.serviceMeter",
      dataIndex: "serviceMeter",
      align: "right",
      whiteSpace: "nowrap",
      style: {
        minWidth: 200,
      },
      forceRender: true,
      render: (value: number) =>
        value !== null && formatNumberWithCommas(value),
    },
    {
      key: "reportCount",
      title: "machineTable.countReport",
      dataIndex: "reportCount",
      render: (value: number, record: any) => {
        return (
          <div className={`flex align-items-center txt-nowrap`}>
            <Link
              className="link-style"
              to={`/groups/${id}/machines/${record.machineId}/report`}
            >
              {value}
            </Link>
            {!!record.reportOpenCount && (
              <div>
                <span className="report-open">{record.reportOpenCount}</span>
              </div>
            )}
          </div>
        );
      },
      style: {
        minWidth: 85,
      },
    },
    // {
    //   key: "actions",
    //   title: "",
    //   dataIndex: "id",
    //   align: "center",
    //   render: (_: any, record: machineType) => (
    //     <>
    //       <IconButton
    //         aria-label="more"
    //         aria-controls="action-menu"
    //         aria-haspopup="true"
    //         onClick={(event) => handleOpenMenu(event, record)}
    //       >

    //         <MoreHorizIcon />
    //       </IconButton>
    //       <Menu
    //         id="action-menu"
    //         anchorEl={anchorEl}
    //         open={Boolean(anchorEl && selectedRow?.id === record.id)}
    //         onClose={handleCloseMenu}
    //       >
    //         <MenuItem onClick={handleEditMachine}>
    //           <ListItemIcon>
    //             <IconEdit />
    //           </ListItemIcon>
    //           <ListItemText primary={t("machineTable.editMachine")} />

    //         </MenuItem>


    //         <MenuItem onClick={handleEditMachineStatus}>
    //           <ListItemIcon>
    //             {machineStatus in STATUS_ICONS ? STATUS_ICONS[machineStatus as MACHINE_CONDITION] : <IconError />}
    //           </ListItemIcon>
    //           <ListItemText primary={t("machineTable.editMachineStatus")} />
    //         </MenuItem>
    //       </Menu>
    //     </>
    //   ),
    //   style: {
    //     minWidth: 85,
    //   },
    // },



  ];

  const STATUS_ICONS = {
    NORMAL: <IconCheck />,
    WARNING: <IconWarning />,
    ERROR: <IconError />,
  };


  const { isRequestedGroupDetail } = useSelector(
    (state: RootState) => state.groupDetail
  );
  const [isRequestedGroupDetailCondition, setIsRequestedGroupDetailCondition] =
    useState(false);

  const handleClickMachineCondition = (
    _: React.MouseEvent<HTMLElement>,
    machineCondition: MACHINE_CONDITION | ""
  ) => {
    setPageInfo({
      ...pageInfo,
      page: CURRENT_PAGE,
    });
    setMachineCondition(machineCondition);
  };

  const handleChangePage = (page: number) => {
    setPageInfo({
      ...pageInfo,
      page,
    });
  };

  const handleCellClick = (column: any) => {
    if (!column.serialNumberPlatePictureUrl) {
      return;
    }
    setDataCell(column);
    setOpen(true);
  };

  const handleSort = (order: string | undefined, property: string) => {
    setOrder(order);
    setField(property);
  };

  const initScrollToTop = () => {
    if (isInit) {
      scrollToTop();
      setIsInit(false);
    }
  };

  const convertDataRow = (data: machineType[]) => {
    return data.map((row: any) => ({
      ...row,
      id: row?.machineId,
    }));
  };

  useEffect(() => {

  }, [selectedRow]);

  const getGroupDetailCondition = (id: string) => {
    return apiGroupDetailCondition(id)
      .then((res) => {
        const data = res.data.data as MachineType;
        const newDataMachineTabs = dataMachineTabList.map((tab) => {
          tab.count = data[tab.key as keyof MachineType];
          return tab;
        });
        setDataMachineTabList(newDataMachineTabs);
      })
      .finally(() => {
        setIsRequestedGroupDetailCondition(true);
      });
  };

  const groupMachinesDetail = (id: string, param: GroupMachinesRequestDef) => {
    return apiGroupMachinesDetail(id, param).then((e) => {
      const { data, meta } = e.data;
      const formatData = convertDataRow(data);
      setRow(formatData);
      setPageInfo(meta.pageInfo);
      if (!data?.length) {
        initScrollToTop();
        resetIsContinuousLoading();
      }
    });
  };

  useEffect(() => {
    if (isRequestedGroupDetail) {
      getGroupDetailCondition(`${id}`);
    }
  }, [isRequestedGroupDetail]);

  useEffect(() => {
    if (isRequestedGroupDetailCondition) {
      setItem(StorageKey.IS_CONTINUOUS_LOADING, "true");
      groupMachinesDetail(`${id}`, {
        limit: LIMIT_PAGE,
        page: pageInfo.page,
        ...(machineCondition ? { machineCondition } : {}),
        ...(field && order ? { orderBys: [{ field, order }] } : {}),
      });
    }
  }, [
    pageInfo.page,
    field,
    order,
    machineCondition,
    isRequestedGroupDetailCondition,
  ]);

  useEffect(() => {
    return () => {
      resetIsContinuousLoading();
    };
  }, []);
  const uploadDescription = [
    `${t("textDesUploadFile.pleaseUploadAFileShowingTheMachinePoints")}`,
    `${t("textDesUploadFile.fileFormatsThatCanBeUploadedAre")}`,
  ];

  const [manufacturer, setmanufacturer] = useState<string | null>(null);
  const [machinetypes, setmachinetypes] = useState<string | null>(null);
  const [machinetype, setmachinetype] = useState<any[]>([]);

  const [manufacturers, setManufacturers] = useState<any[]>([]);


  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedImageSerial, setSelectedImageSerial] = useState<string | null>(null);

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [fileError, setFileError] = useState<null | string>(null);

  const [menuAnchorSerial, setMenuAnchorSerial] = useState<null | HTMLElement>(null);
  const [fileErrorSerial, setFileErrorSerial] = useState<null | string>(null);

  const [customerMachineNumber, setCustomerMachineNumber] = useState<string | null>(null);
  const [makerOther, setmakerOther] = useState<string | null>(null);
  const [machineTypeOther, setmachineTypeOther] = useState<string | null>(null);
  const [modelType, setmodelType] = useState<string | null>(null);
  const [serialNo, setserialNo] = useState<string | null>(null);


  const fetchMachineManufacturers = async (groupId: string) => {
    try {
      const response = await apiGroupMachineManufacturers(groupId);

      setManufacturers(response.data.data || []);
    } catch (error) {
      console.error("Error fetching machine manufacturers:", error);
    }
  };

  const fetchMachineTypes = async (groupId: string) => {
    try {
      const response = await apiGroupMachineTypes(groupId);

      setmachinetype(response.data.data || []);
    } catch (error) {
      console.error("Error fetching machine types:", error);
    }
  };
  const [mediaFiles, setMediaFiles] = useState<File[]>([]);
  const fetchMachineDetail = async (groupId: string, machineId: string) => {
    try {
      const response = await apiGetMachineDetailInfo(groupId, machineId);
      const data = response.data;


      setCustomerMachineNumber(data.data.machineName || "");
      setmakerOther(data.data.customMachineManufacturerName || "");
      setmachineTypeOther(data.data.customTypeName || "");
      setmodelType(data.data.modelAndType || "");
      setserialNo(data.data.serialNumber || "");
      setmanufacturer(data.data.machineManufacturerId || "");
      setmachinetypes(data.data.machineTypeId || "");
      setSelectedImage(data.data.pictureUrl || "");

      setSelectedImageSerial(data.data.serialNumberPlatePictureUrl || "");

      setMachineStatus(data.data.machineCondition || "");

      if (data.data.serialNumberPlatePictureUrl) {
        const url = data.data.serialNumberPlatePictureUrl;


        const fileName = url.substring(url.lastIndexOf("/") + 1, url.indexOf("?"));
        const fileNameParts = fileName.split(".");
        const name = fileNameParts.slice(0, -1).join(".");
        const originalExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();


        const response = await fetch(url);
        const blob = await response.blob();
        const mimeType = blob.type;
        const mimeExtension = mimeType.split("/")[1];


        const finalExtension =
          originalExtension === mimeExtension || mimeExtension === "jpeg"
            ? originalExtension
            : mimeExtension;


        const file = new File([blob], `${name}.${finalExtension}`, { type: mimeType });


        setMediaFiles([file]);
      }


    } catch (error) {
      console.error("Error fetching machine details:", error);
    }
  };




  useEffect(() => {
    if (id && manufacturers.length === 0) {
      fetchMachineManufacturers(id);
    }
  }, [id, manufacturers]);

  useEffect(() => {
    if (openMachineDetails) {

    }
  }, [openMachineDetails, manufacturers]);

  useEffect(() => {
    if (id && machinetype.length === 0) {
      fetchMachineManufacturers(id);
    }
  }, [id, machinetype]);

  useEffect(() => {
    if (openMachineDetails) {

    }
  }, [openMachineDetails, machinetype]);




  useEffect(() => {

    setFileCount(mediaFiles.length);

  }, [mediaFiles]);

  const [refreshKey, setRefreshKey] = useState(0);
  const [fileCount, setFileCount] = useState(0);
  const refreshMediaUpload = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };
  const [isImageRemoved, setIsImageRemoved] = useState(false);
  const [isImageRemovedSerial, setIsImageRemovedSerial] = useState(false);
  const [isSerialImageRemoved, setIsSerialImageRemoved] = useState(false);

  async function updateMachine(
    groupId: string,
    machineId: string,
    input: {
      machineName: string;
      machineManufacturerId: string;
      machineTypeId: string;
      modelAndType: string;
      serialNumber: string;
      customMachineManufacturerName?: string | null;
      customTypeName?: string | null;
    },
    pictureFiles: File[],
    pictureFilesSerial: File[],
    refreshMachines: () => void,

  ) {

    const pictureMedia = await handleConvertMedia(pictureFiles);
    const serialNumberMedia = await handleConvertMedia(pictureFilesSerial);



    const dynamicInput = {
      ...input,
      ...(isImageRemoved ? { pictureUrl: null } : pictureMedia[0]?.mediaUrl && { pictureUrl: pictureMedia[0].mediaUrl }),
      ...(isImageRemovedSerial ? { serialNumberPlatePictureUrl: null } : serialNumberMedia[0]?.mediaUrl && { serialNumberPlatePictureUrl: serialNumberMedia[0].mediaUrl }),

      customMachineManufacturerName:
        input.machineManufacturerId !== "0665TE1NFG6ZXQXXYYNY15V0NW"
          ? null
          : input.customMachineManufacturerName,

      customTypeName:
        input.machineTypeId !== "0661J7JX6ZDP4HXM46349GPFHW"
          ? null
          : input.customTypeName,

    };


    try {
      const response = await apiUpdateMachineInGroup(groupId, machineId, dynamicInput);

      //store.dispatch(setShowAlert("Machine updated successfully."));
      store.dispatch(setShowAlert(t("machineupdated")));




      refreshMachines();
      setActiveStatus("");

      setSelectedImage(null);
      setSelectedImageSerial(null);
      setFileError(null);
      setFileErrorSerial(null);

      setmanufacturer(null);
      setmachinetypes(null);
      setmakerOther(null);
      setmachineTypeOther(null);
      setmodelType(null);
      setserialNo(null);
      setCustomerMachineNumber(null);

      setOpenMachineDetails(false);
      setMediaFiles([]);
      setPictureFiles([]);


      setPictureFilesSerial([]);


      setSerialNumberFiles([]);
      setIsImageRemoved(false);
      setIsImageRemovedSerial(false);
    } catch (error) {
      console.error("Error updating machine:", error);
    }
  }

  async function updateMachineStatus(
    groupId: string,
    machineId: string,
    input: {
      machineCondition: string;
    },
    refreshMachines: () => void,
    refreshConditions: (groupId: string) => void
  ) {
    try {
      const response = await apiUpdateMachineInGroupStatus(groupId, machineId, input);
      //store.dispatch(setShowAlert("Machine status updated successfully."));
      store.dispatch(setShowAlert(t("machineupdatedstatus")));
      setOpenMachineDetailsStatus(false);
      refreshMachines();
      setActiveStatus("");
      refreshConditions(groupId);
      setSelectedImage(null);
      setSelectedImageSerial(null);
      setFileError(null);
      setFileErrorSerial(null);

      setmanufacturer(null);
      setmachinetypes(null);
      setmakerOther(null);
      setmachineTypeOther(null);
      setmodelType(null);
      setserialNo(null);
      setCustomerMachineNumber(null);

      setOpenMachineDetails(false);
      setMediaFiles([]);
      setPictureFiles([]);


      setPictureFilesSerial([]);


      setSerialNumberFiles([]);
      setIsImageRemoved(false);
      setIsImageRemovedSerial(false);
    } catch (error) {
      console.error("Error updating machine status:", error);
    }
  }

  // async function postMachineInGroup(groupId: string) {

  //   const staticPayload = {
  //     machineName: "Testing machine API ",
  //     machineTypeId: "0661J7JX5D7J7BH8MS4M14KEEW",
  //     machineManufacturerId: "0661ATBN7XH5EB1NXCFDF4ZTMG",
  //     modelAndType: "12345678",
  //     serialNumber: "12345678",
  //     pictureUrl: null,
  //     serialNumberPlatePictureUrl: null,
  //     customMachineManufacturerName: null,
  //     customTypeName: null
  //   };

  //   try {

  //     const response = await apiPostMachineInGroup(groupId, staticPayload);
  //     store.dispatch(setShowAlert("Machine posted successfully."));
  //   } catch (error) {
  //     console.error("Error posting machine:", error);
  //   }
  // }



  const refreshMachineList = async () => {
    if (!id) return;
    try {
      const response = await apiGroupMachinesDetail(id, {
        limit: LIMIT_PAGE,
        page: pageInfo.page,
        ...(machineCondition ? { machineCondition } : {}),
        ...(field && order ? { orderBys: [{ field, order }] } : {}),
      });
      const { data, meta } = response.data;
      const formattedData = convertDataRow(data);
      setRow(formattedData);
      setPageInfo(meta.pageInfo);
    } catch (error) {
      console.error("Error refreshing machine list:", error);
    }
  };

  const handleConvertMedia = async (files: File[]) => {
    const mediaArray = [];
    for await (const file of files) {
      if (file instanceof File) {
        const uploadedMedia = await handleUploadStore(UploadFileType.MACHINE, file);
        if (uploadedMedia) {
          mediaArray.push({
            fileName: uploadedMedia.name || file.name,
            filePath: uploadedMedia.filePath,
            mediaUrl: uploadedMedia.filePath,
            mimeType: uploadedMedia.type || file.type,
          });
        }
      }
    }
    return mediaArray.filter((media) => media.fileName);
  };
  const [pictureFiles, setPictureFiles] = useState<File[]>([]);

  const [pictureFilesSerial, setPictureFilesSerial] = useState<File[]>([]);
  const [serialNumberFiles, setSerialNumberFiles] = useState<File[]>([]);


  const [machineStatus, setMachineStatus] = useState<MACHINE_CONDITION | "">("");
  const [activeStatus, setActiveStatus] = useState<MACHINE_CONDITION | "">("");
  useEffect(() => {
    setActiveStatus(machineStatus);
  }, [machineStatus, openMachineDetailsStatus]);

  useEffect(() => {
    if (openMachineDetailsStatus) {
      console.log("Active Status on Dialog Open:", activeStatus);
    }
  }, [openMachineDetailsStatus, activeStatus]);




  useEffect(() => {
    if (openMachineDetails) {
      console.log("File count on dialog open:", fileCount);
    }
  }, [openMachineDetails, fileCount]);

  useEffect(() => {
    if (fileCount === 0 && isSerialImageRemoved) {
      console.log("Removing serial number plate picture due to file count 0.");

    }
  }, [fileCount, isSerialImageRemoved]);

  useEffect(() => {
    console.log("Current file count:", fileCount);
    console.log("Is serial image removed:", isSerialImageRemoved);
  }, [fileCount, isSerialImageRemoved]);


  interface ExcelRow {
    machineName?: string;
    machineTypeId?: string;
    machineManufacturerId?: string;
    modelAndType?: string;
    serialNumber?: string;
    customMachineManufacturerName?: string | null;
    customTypeName?: string | null;
  }

  const processExcelFile = async (file: Blob, groupId: string) => {
    try {
      const reader = new FileReader();

      reader.onload = async (e) => {
        if (!e.target?.result) {
          console.error("Error reading file: target is null or undefined.");
          return;
        }
        const data = new Uint8Array(e.target.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const rows: ExcelRow[] = XLSX.utils.sheet_to_json<ExcelRow>(worksheet);

        setIsProcessing(true);

        // Process each row
        for (const [index, row] of rows.entries()) {
          // Check if the row is empty
          const isEmptyRow = !row.machineName && !row.machineTypeId && !row.machineManufacturerId && !row.modelAndType && !row.serialNumber;

          if (isEmptyRow) {
            console.warn(`Empty row encountered at index ${index + 1}. Stopping further processing.`);
            break; // Stop processing further rows if an empty row is found
          }

          const payload = {
            machineName: row.machineName || "",
            machineTypeId: row.machineTypeId || "",
            machineManufacturerId: row.machineManufacturerId || "",
            modelAndType: row.modelAndType || "",
            serialNumber: row.serialNumber || "",
            customMachineManufacturerName: row.customMachineManufacturerName || null,
            customTypeName: row.customTypeName || null,
          };

          console.log(`Processing row ${index + 1}:`, payload);
          try {
            await postMachineInGroup(groupId, payload);
          } catch (error) {
            console.error(`Error processing row ${index + 1}:`, error);
          }
        }

        setIsProcessing(false);
        console.log("All rows processed.");
      };

      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error("Error processing Excel file:", error);
      setIsProcessing(false);
    }
  };

  // Handle file selection
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (id) {
      const files = event.target.files;
      if (files && files[0]) {
        processExcelFile(files[0], id).then(() => {
          // Reset the file input value after processing
          event.target.value = "";
        });
      } else {
        console.error("No file selected.");
      }
    } else {
      console.error("Group ID is undefined.");
    }
  };

  const postMachineInGroup = async (groupId: string, payload: any) => {
    try {
      const response = await apiPostMachineInGroup(groupId, payload);
      //store.dispatch(setShowAlert("Machine posted successfully."));
      store.dispatch(setShowAlert(t("machinepostedstatus")));
      refreshMachineList();
    } catch (error) {
      console.error("Error posting machine:", error);
    }
  };



  return (
    <div className="machine-tab">

      <div
        className="toggle-btn-group"
        style={{
          display: "flex",
          justifyContent: !!rows?.length ? "space-between" : "flex-end",
          alignItems: "center",
          flexWrap: "wrap",
          marginTop: "24px",
        }}
      >
        {!!rows?.length && (
          <ToggleButtonGroup
            style={{ overflow: "auto", display: "flex", flexWrap: "wrap" }}
            value={machineCondition}
            exclusive
          >
            {dataMachineTabList.map((tab, index) => (
              <ToggleButton
                disabled={!tab.count}
                key={index}
                value={tab.machineCondition}
                className="flex-centered filter-tab"
                onClick={handleClickMachineCondition}
              >
                {typeof tab.label === "string" ? t(tab.label) : tab.label}
                <span style={{ marginLeft: "8px" }}>({tab.count})</span>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        )}

        {/* <div style={{ display: "flex", gap: "10px", flexWrap: "wrap", marginTop: "20px", marginLeft: "20px", marginRight: "5px" }}>
          <Button
            className="btn btn-primary btn-save"
            startIcon={<UploadOutlined />}
            component="label"
            style={{
              height: "45px", 
              fontSize: "14px", 
            }}
          >
            {t("formAction.uploadexcel")}
            <input
              type="file"
              accept=".xlsx, .xls"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </Button>

          <Button
            className="btn btn-primary btn-save"
            startIcon={<DownloadOutlined />}
            style={{
              height: "45px",
              fontSize: "14px", 
            }}
          >
            {t("formAction.downloadexcel")}
          </Button>
        </div> */}
      </div>





      <div className="machine-table">
        <DataTable
          columns={columns}
          dataSource={rows}
          textEmptyData="noGroupDetailMachines"
          onRequestSort={handleSort}
          onCellClick={handleCellClick}
          didScrollTop={() => {
            initScrollToTop();
            resetIsContinuousLoading();
          }}
        />
        <DataPagination
          isHidden={!rows?.length}
          className="mt-25 sp-p24"
          currentPage={pageInfo.page}
          limitPage={pageInfo.limit}
          totalPage={pageInfo.total}
          handleChangePage={handleChangePage}
        />
      </div>


      {dataCell && (
        <DataDialog
          title={t("serialNumberPlate")}
          open={open}
          dialogActions={
            <Button onClick={() => setOpen(false)} className="btn btn-white">
              {t("close")}
            </Button>
          }
        >
          {dataCell.serialNumberPlatePictureUrl ? (
            <img
              className="img-dialog"
              src={`${dataCell.serialNumberPlatePictureUrl}`}
              alt={dataCell.machineManufacturerName}
              loading="lazy"
              onError={() => {
                setDataCell({
                  ...dataCell,
                  serialNumberPlatePictureUrl: undefined,
                });
              }}
            />
          ) : (
            <Box
              sx={{
                width: 512,
                height: 512,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconInvalidUrl />
            </Box>
          )}
        </DataDialog>
      )}

      <DataDialog
        open={openMachineDetails}
        setOpen={setOpenMachineDetails}
        title={t("machineInfo.title")}

      >
        <div className="form-edit-name">


          <div className="wrapper-text-field mt-8">
            <FormControl fullWidth>

              <div
                className="image-box-container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "0px",
                }}
              >
                <div
                  className="image-box"
                  style={{
                    width: "100%",
                    maxWidth: "150px",
                    height: "150px",
                    border: "2px dashed #ccc",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "4px",
                    backgroundColor: "transparent",
                    position: "relative",
                  }}
                >
                  {selectedImage ? (
                    <>
                      <img
                        src={selectedImage}
                        alt="Preview"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "contain",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                        onClick={(event) => setMenuAnchor(event.currentTarget)}

                      />
                      <Menu
                        anchorEl={menuAnchor}
                        open={Boolean(menuAnchor)}
                        onClose={() => setMenuAnchor(null)}
                      >
                        <MenuItem

                          onClick={() => {
                            setPictureFiles([]);
                            setSelectedImage(null);
                            setIsImageRemoved(true);
                            setMenuAnchor(null);
                          }}
                        >
                          <IconDeleteRow />

                          <span style={{ marginLeft: "4px" }}>{t("machineInfo.removeimage")}</span>
                        </MenuItem>
                      </Menu>
                    </>
                  ) : fileError ? (
                    <span
                      style={{
                        color: "red",
                        fontSize: "12px",
                        textAlign: "center",
                        position: "absolute",
                      }}
                    >
                      {t("machineInfo.errormessage")}

                    </span>
                  ) : (
                    <span style={{ color: "#ccc" }}>{t("machineInfo.noimage")}</span>
                  )}
                </div>
              </div>

              <label htmlFor="image-upload" style={{ display: "block", marginBottom: "8px" }}>

              </label>





              <input
                id="image-upload"
                type="file"
                accept=".png, .jpg, .jpeg, .gif, .heic"
                onChange={(event) => {
                  const file = event.target.files?.[0];
                  if (file) {
                    const validImageTypes = [
                      "image/png",
                      "image/jpeg",
                      "image/jpg",
                      "image/gif",
                      "image/heic",
                    ];
                    if (validImageTypes.includes(file.type)) {
                      setPictureFiles([file]);
                      setSelectedImage(URL.createObjectURL(file));
                      setIsImageRemoved(false);
                      setFileError(null);
                    } else {
                      setPictureFiles([]);
                      setSelectedImage(null);
                      setFileError(t("machineInfo.errormessage"));
                      event.target.value = "";
                    }
                  }
                  event.target.value = "";
                }}
                style={{ display: "none" }}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  className="btn btn-primary"
                  htmlFor="image-upload"
                  style={{
                    width: "160px",
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    backgroundColor: "#e2f2ff",
                    color: "#1e2c39",
                    marginBottom: "16px"
                  }}
                >
                  <UploadOutlined className="mr-5" />
                  {t("machineInfo.uploadimage")}
                </Button>
              </div>


            </FormControl>
          </div>



          <div className="wrapper-text-field mt-8" style={{ marginBottom: "16px" }}>
            <TextField
              label={`${t("machineInfo.customerMachineNumber")}*`}
              error={!customerMachineNumber}
              value={customerMachineNumber}
              onChange={(event) => setCustomerMachineNumber(event.target.value)}
              helperText={
                !customerMachineNumber && (
                  <span style={{ color: "red", fontSize: "12px", display: "flex", alignItems: "center", gap: "4px" }}>
                    {t("required")}*
                  </span>
                )
              }
              InputProps={{
                endAdornment: !customerMachineNumber && (
                  <IconInputError />
                ),
              }}
              fullWidth
            />
          </div>



          <div className="wrapper-text-field mt-8" style={{ marginBottom: "16px" }}>
            <FormControl fullWidth>
              <TextField
                select
                label={`${t("machineInfo.maker")}*`}
                value={manufacturer || ""}
                onChange={(event) => {
                  const selectedId = event.target.value;
                  setmanufacturer(selectedId);

                }}
                error={!manufacturer}
                helperText={
                  !manufacturer && (
                    <span style={{ color: "red", fontSize: "12px", display: "flex", alignItems: "center", gap: "4px" }}>
                      {t("required")}*
                    </span>
                  )
                }
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        width: 250,
                      },
                    },
                  },
                }}
              >

                {manufacturers.map((manufacturer) => (
                  <MenuItem
                    key={manufacturer.machine_manufacturer_id}
                    value={manufacturer.machine_manufacturer_id}
                  >
                    {manufacturer.machine_manufacturer_name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </div>



          {manufacturer === "0665TE1NFG6ZXQXXYYNY15V0NW" && (
            <div className="wrapper-text-field mt-8" style={{ marginBottom: "16px" }}>
              <TextField
                label={`${t("machineInfo.makerOther")}`}
                value={makerOther}
                onChange={(event) => setmakerOther(event.target.value)}
                fullWidth
              />
            </div>
          )}


          <div className="wrapper-text-field mt-8" style={{ marginBottom: "16px" }}>
            <FormControl fullWidth>
              <TextField
                select
                label={`${t("machineInfo.machineType")}*`}
                value={machinetypes || ""}
                onChange={(event) => {
                  const selectedId = event.target.value;
                  setmachinetypes(selectedId);

                }}
                error={!machinetypes}
                helperText={
                  !machinetypes && (
                    <span style={{ color: "red", fontSize: "12px", display: "flex", alignItems: "center", gap: "4px" }}>

                      {t("required")}*
                    </span>
                  )
                }
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        width: 250,
                      },
                    },
                  },
                }}
              >
                {machinetype.map((machinetypes) => (
                  <MenuItem
                    key={machinetypes.machine_type_id}
                    value={machinetypes.machine_type_id}
                  >
                    {machinetypes.type_name}
                  </MenuItem>
                ))}

              </TextField>
            </FormControl>
          </div>
          {machinetypes === "0661J7JX6ZDP4HXM46349GPFHW" && (
            <div className="wrapper-text-field mt-8" style={{ marginBottom: "16px" }}>
              <TextField
                label={`${t("machineInfo.machineTypeOther")}`}

                value={machineTypeOther}
                onChange={(event) => setmachineTypeOther(event.target.value)}

                fullWidth
              />
            </div>
          )}

          <div className="wrapper-text-field mt-8" style={{ marginBottom: "16px" }}>
            <TextField
              label={`${t("machineInfo.modelType")}*`}
              error={!modelType}
              value={modelType}
              onChange={(event) => setmodelType(event.target.value)}
              helperText={
                !modelType && (
                  <span style={{ color: "red", fontSize: "12px", display: "flex", alignItems: "center", gap: "4px" }}>
                    {t("required")}*
                  </span>
                )
              }
              InputProps={{
                endAdornment: !modelType && (
                  <IconInputError />
                ),
              }}
              fullWidth

            />
          </div>


          <div className="wrapper-text-field mt-8" style={{ marginBottom: "16px" }}>
            <TextField
              label={`${t("machineInfo.serialNo")}*`}
              error={!serialNo || (serialNo.length > 8 && manufacturer === "0661ATBN7XH5EB1NXCFDF4ZTMG")}
              value={serialNo}
              onChange={(event) => {
                const value = event.target.value.toUpperCase();
                if (manufacturer === "0661ATBN7XH5EB1NXCFDF4ZTMG") {
                  if (/^[A-Z0-9]*$/.test(value) && value.length <= 8) {
                    setserialNo(value);
                  }
                } else {
                  if (/^[A-Z0-9]*$/.test(value) && value.length <= 20) {
                    setserialNo(value);
                  }
                }
              }}
              onKeyDown={(event) => {
                const regex = /^[A-Z0-9]$/;
                if (
                  !regex.test(event.key.toUpperCase()) &&
                  event.key !== "Backspace" &&
                  event.key !== "Delete"
                ) {
                  event.preventDefault();
                }
              }}
              helperText={
                !serialNo ? (
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    {t("required")}*
                  </span>
                ) : serialNo.length > 8 && manufacturer === "0661ATBN7XH5EB1NXCFDF4ZTMG" ? (
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    {t("machineInfo.serialNoTooLong")}
                  </span>
                ) : null
              }
              InputProps={{
                endAdornment:
                  (!serialNo) && <IconInputError />,
              }}
              fullWidth
            />
          </div>



          {/* <div className="mt-16">
            <MediaUploadMachine
              key={refreshKey}

              setFileCount={(count: number) => {
                console.log("File count from MediaUploadMachine:", count);
                setFileCount(count);
              }}
              medias={mediaFiles}
              setMedias={(files: File[]) => {
                setMediaFiles(files);
                setSerialNumberFiles(files);
                setIsSerialImageRemoved(false);
              }}
              typeFile={TYPE_FILE_UPLOAD.image}
              uploadDescription={uploadDescription}
              onRemoveMedia={() => {
                setMediaFiles([]);
                setSerialNumberFiles([]);
                setIsSerialImageRemoved(true);
              }}
            />
          </div> */}



          <div className="wrapper-text-field mt-8">
            <FormControl fullWidth>

              <div
                className="image-box-container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "0px",
                }}
              >
                <div
                  className="image-box"
                  style={{
                    width: "100%",
                    maxWidth: "550px",
                    height: "150px",
                    border: "2px dashed #ccc",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "4px",
                    backgroundColor: "transparent",
                    position: "relative",
                  }}
                >
                  {selectedImageSerial ? (
                    <>
                      <img
                        src={selectedImageSerial}
                        alt="Preview"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "contain",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                        onClick={(event) => setMenuAnchorSerial(event.currentTarget)}

                      />
                      <Menu
                        anchorEl={menuAnchorSerial}
                        open={Boolean(menuAnchorSerial)}
                        onClose={() => setMenuAnchorSerial(null)}
                      >
                        <MenuItem

                          onClick={() => {
                            setPictureFilesSerial([]);
                            setSelectedImageSerial(null);
                            setIsImageRemovedSerial(true);
                            setMenuAnchorSerial(null);
                          }}
                        >
                          <IconDeleteRow />

                          <span style={{ marginLeft: "4px" }}>{t("machineInfo.removeimage")}</span>
                        </MenuItem>
                      </Menu>
                    </>
                  ) : fileErrorSerial ? (
                    <span
                      style={{
                        color: "red",
                        fontSize: "12px",
                        textAlign: "center",
                        position: "absolute",
                      }}
                    >
                      {t("machineInfo.errormessage")}

                    </span>
                  ) : (
                    <div style={{ textAlign: "center" }}>


                      <span>{t("textDesUploadFile.pleaseUploadAFileShowingTheMachinePoints")}</span>
                      <br />
                      <span>{t("textDesUploadFile.fileFormatsThatCanBeUploadedAre")}</span>
                    </div>
                  )}
                </div>
              </div>

              <label htmlFor="image-upload-serial" style={{ display: "block", marginBottom: "8px" }}>

              </label>





              <input
                id="image-upload-serial"
                type="file"
                accept=".png, .jpg, .jpeg, .gif, .heic"
                onChange={(event) => {
                  const file = event.target.files?.[0];
                  if (file) {
                    const validImageTypes = [
                      "image/png",
                      "image/jpeg",
                      "image/jpg",
                      "image/gif",
                      "image/heic",
                    ];
                    if (validImageTypes.includes(file.type)) {
                      setPictureFilesSerial([file]);
                      setSelectedImageSerial(URL.createObjectURL(file));
                      setIsImageRemovedSerial(false);
                      setFileErrorSerial(null);
                    } else {
                      setPictureFilesSerial([]);
                      setSelectedImageSerial(null);
                      setFileErrorSerial(t("machineInfo.errormessage"));
                      event.target.value = "";
                    }
                  }
                  event.target.value = "";
                }}
                style={{ display: "none" }}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  className="btn btn-primary"
                  htmlFor="image-upload-serial"
                  style={{
                    width: "160px",
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    backgroundColor: "#e2f2ff",
                    color: "#1e2c39",
                    marginBottom: "16px"
                  }}
                >
                  <UploadOutlined className="mr-5" />
                  {t("machineInfo.uploadimage")}
                </Button>
              </div>


            </FormControl>
          </div>

          <div className="flex justify-content-end btn-group-footer mt-8">
            <Button onClick={() => {

              setSelectedImage(null);
              setSelectedImageSerial(null);
              setFileError(null);
              setFileErrorSerial(null);

              setmanufacturer(null);
              setmachinetypes(null);
              setmakerOther(null);
              setmachineTypeOther(null);
              setmodelType(null);
              setserialNo(null);
              setCustomerMachineNumber(null);

              setOpenMachineDetails(false);
              setMediaFiles([]);
              setPictureFiles([]);


              setPictureFilesSerial([]);


              setSerialNumberFiles([]);
              setIsImageRemoved(false);
              setIsImageRemovedSerial(false);
            }} className="btn btn-no-border">

              {t("cancel")}
            </Button>

            <Button
              className="btn btn-primary btn-save ml-10"


              onClick={() => {
                if (id && recordId) {
                  updateMachine(id, recordId, {
                    machineName: customerMachineNumber || "",
                    machineManufacturerId: manufacturer || "",
                    machineTypeId: machinetypes || "",
                    modelAndType: modelType || "",
                    serialNumber: serialNo || "",
                    customMachineManufacturerName: makerOther || null,
                    customTypeName: machineTypeOther || null,

                  },
                    pictureFiles,
                    pictureFilesSerial,
                    refreshMachineList,

                  );
                } else {
                  console.error("Group ID or Machine Id is undefined");
                }
              }}
              disabled={

                !customerMachineNumber ||
                !manufacturer ||
                !machinetype ||

                !modelType ||
                !serialNo ||
                (manufacturer === "0661ATBN7XH5EB1NXCFDF4ZTMG" && serialNo.length > 8)
              }
            >
              {t("formAction.update")}
            </Button>

          </div>

        </div>
      </DataDialog>

      <DataDialog
        open={openMachineDetailsStatus}
        setOpen={setOpenMachineDetailsStatus}
        title={t("machineInfo.status")}
      >
        <div className="form-edit-name">

          <div className="icon-group justify-content-center">
            <div
              className={`icon success ${activeStatus === "NORMAL" ? "active" : ""}`}
              onClick={() => {
                setActiveStatus(MACHINE_CONDITION.NORMAL);
                console.log("Selected Status: NORMAL");
              }}
            >
              <IconCheck />
            </div>
            <div
              className={`icon warning ${activeStatus === "WARNING" ? "active" : ""}`}
              onClick={() => {
                setActiveStatus(MACHINE_CONDITION.WARNING);
                console.log("Selected Status: WARNING");
              }}
            >
              <IconWarning />
            </div>
            <div
              className={`icon error ${activeStatus === "ERROR" ? "active" : ""}`}
              onClick={() => {
                setActiveStatus(MACHINE_CONDITION.ERROR);
                console.log("Selected Status: ERROR");
              }}
            >
              <IconError />
            </div>
          </div>


          <div className="flex justify-content-end btn-group-footer mt-8">
            <Button
              onClick={() => {
                setOpenMachineDetailsStatus(false);
                setActiveStatus("");
              }}
              className="btn btn-no-border"
            >
              {t("cancel")}
            </Button>

            <Button
              className="btn btn-primary btn-save ml-10"
              onClick={() => {
                if (id && recordId) {
                  updateMachineStatus(
                    id,
                    recordId,
                    { machineCondition: activeStatus },
                    refreshMachineList,
                    () => getGroupDetailCondition(id)
                  );
                } else {
                  console.error("Group ID or Machine ID is undefined");
                }
              }}
              disabled={!activeStatus}
            >
              {t("formAction.update")}
            </Button>
          </div>
        </div>


        <style>{`
    .icon-group {
    margin-top: -20px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 40px; /* Adjust spacing between icons */
      margin-bottom: 20px;
    }
    .icon-group .icon {
      font-size: 50px; /* Increase icon size */
      cursor: pointer;
      opacity: 0.5; /* Default state */
      transition: transform 0.2s, opacity 0.2s; /* Add smooth hover effect */
      transform: scale(2.0);
    }
    .icon-group .icon.active {
      opacity: 1; /* Highlight active icon */
      transform: scale(2.2); /* Slightly enlarge active icon */
    }
    .icon-group .icon.success.active {
      color: green;
    }
    .icon-group .icon.warning.active {
      color: orange;
    }
    .icon-group .icon.error.active {
      color: red;
    }
    .icon-group .icon.warning {
  background: none; /* Remove square or background */
  border: none; /* Remove border if present */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0; /* Ensure no extra space is added */
}
  `}</style>
      </DataDialog>


    </div>
  );
}

