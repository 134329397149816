import { AxiosResponse } from "axios";
import { api, ENDPOINTS } from "@app/api";
import { InspectionsType } from "@app/types";
import {
  DATE_TIME_FORMAT_YEAR_AND_MONTH_1,
  DATE_TIME_FORMAT_YEAR_AND_MONTH_2,
} from "@app/constants";
import { format } from "date-fns";

export const apiInspectionFormTemplates = (
  groupId: string,
  machineId: string
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.inspectionFormTemplates(groupId, machineId));
};

export const getCustomInspectionFormTemplatess = (
  groupId: string,
  machineId: string,
  customInspectionFormId: string
) => {
  return apiCustomInspectionTemplatesDetails(
    groupId,
    machineId,
    customInspectionFormId
  ).then((db) => {
    const { data } = db.data;
    return data;
  });
};

export const apiCustomInspectionTemplatesDetails = (
  groupId: string,
  machineId: string,
  customInspectionFormId: string
): Promise<AxiosResponse> => {
  return api.get(
    ENDPOINTS.customInspectionFormDetails(
      groupId,
      machineId,
      customInspectionFormId
    )
  );
};

export const apiInspectionForms = (
  groupId: string,
  machineId: string
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.inspectionForms(groupId, machineId));
};

export const apiInspectionFormsWeb = (
  groupId: string,
  machineId: string
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.inspectionFormsWeb(groupId, machineId));
};

export const apiInspectionTemplatesDetail = (
  groupId: string,
  machineId: string,
  inspectionFormId: string
): Promise<AxiosResponse> => {
  return api.get(
    ENDPOINTS.inspectionFormDetail(groupId, machineId, inspectionFormId)
  );
};

export const apiPutCustomInspectionForm = (
  groupId: string,
  machineId: string,
  inspectionFormId: string,
  payload: InspectionsType
): Promise<AxiosResponse> => {
  return api.put(
    ENDPOINTS.customInspectionFormDetail(groupId, machineId, inspectionFormId),
    { ...payload }
  );
};

export const apiCustomInspectionTemplatesDetail = (
  groupId: string,
  machineId: string,
  customInspectionFormId: string
): Promise<AxiosResponse> => {
  return api.get(
    ENDPOINTS.customInspectionFormDetail(
      groupId,
      machineId,
      customInspectionFormId
    )
  );
};

export const apiPostInspectionForms = (
  groupId: string,
  machineId: string,
  payload: any
): Promise<AxiosResponse> => {
  return api.post(ENDPOINTS.inspectionForm(groupId, machineId), {
    ...payload,
  });
};

export const apiGetInspectionForms = (
  groupId: string,
  machineId: string
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.inspectionForm(groupId, machineId));
};

export const apiUploadUrls = (payload: any): Promise<AxiosResponse> => {
  return api.post(ENDPOINTS.uploadUrls, {
    ...payload,
  });
};

export const apiUploadStore = (file: File): Promise<AxiosResponse> => {
  return api.put(ENDPOINTS.uploadStore, file);
};

export const apiGetInspections = (
  groupId: string,
  machineId: string,
  params: {
    monthYear: string;
    customInspectionFormId: string;
  }
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.inspections(groupId, machineId), { params });
};

export const apiGetInspectionsPDF = (
  groupId: string,
  machineId: string,
  params: {
    monthYear: string;
    customInspectionFormId: string;
  }
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.inspectionPdf(groupId, machineId), {
    params,
  });
};







export const getInspectionFormTemplates = (
  groupId: string,
  machineId: string
) => {
  return apiInspectionFormTemplates(groupId, machineId).then((db) => {
    const { data } = db.data;
    return data;
  });
};

export const getInspectionForms = (groupId: string, machineId: string) => {
  return apiInspectionForms(groupId, machineId).then((db) => {
    const { data } = db.data;
    return data;
  });
};

export const getInspectionFormsWeb = (groupId: string, machineId: string) => {
  return apiInspectionFormsWeb(groupId, machineId).then((db) => {
    const { data } = db.data;
    return data;
  });
};

export const getInspectionFormTemplatesDetail = (
  groupId: string,
  machineId: string,
  inspectionFormId: string
) => {
  return apiInspectionTemplatesDetail(
    groupId,
    machineId,
    inspectionFormId
  ).then((db) => {
    const { data } = db.data;
    return data;
  });
};

export const getCustomInspectionFormTemplates = (
  groupId: string,
  machineId: string,
  customInspectionFormId: string
) => {
  return apiCustomInspectionTemplatesDetail(
    groupId,
    machineId,
    customInspectionFormId
  ).then((db) => {
    const { data } = db.data;
    return data;
  });
};

export const postInspectionFormTemplates = (
  groupId: string,
  machineId: string,
  payload: InspectionsType
) => {
  return apiPostInspectionForms(groupId, machineId, payload);
};

export const getInspectionForm = (groupId: string, machineId: string) => {
  return apiGetInspectionForms(groupId, machineId).then((db) => {
    const { data } = db.data;
    return data;
  });
};

export const postUploadUrls = (payload: { type: string; fileName: string }) => {
  return apiUploadUrls(payload)
    .then((db) => {
      const { data } = db.data;
      return data;
    })
    .catch(() => {
      throw new Error();
    });
};

export const putCustomInspectionForm = (
  groupId: string,
  machineId: string,
  inspectionFormId: string,
  payload: InspectionsType
) => {
  return apiPutCustomInspectionForm(
    groupId,
    machineId,
    inspectionFormId,
    payload
  );
};

export const putUploadStore = (file: File) => {
  return apiUploadStore(file)
    .then((db) => {
      const { data } = db.data;
      return data;
    })
    .catch(() => {
      throw new Error();
    });
};

export const getInspections = (
  groupId: string,
  machineId: string,
  params: {
    monthYear: string;
    customInspectionFormId: string;
    utc?: string;
  }
) => {
  return apiGetInspections(groupId, machineId, params).then((db) => {
    const { data } = db.data;
    return data;
  });
};

const arrayBufferToFile = (arrayBuffer: any, fileName: string) => {
  const blob = new Blob([new Uint8Array(arrayBuffer)]);
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");

  a.style.display = "none";
  a.href = url;
  a.download = fileName;

  document.body.appendChild(a);
  a.click();
};





export const getInspectionsPDF = (
  groupId: string,
  machineId: string,
  params: {
    monthYear: Date;
    customInspectionFormId: string;
    utc?: string;
    //InspectionId: string; // Added InspectionId
  }
) => {
  return apiGetInspectionsPDF(groupId, machineId, {
    ...params,
    monthYear: format(
      new Date(params?.monthYear),
      DATE_TIME_FORMAT_YEAR_AND_MONTH_1
    ),
  }).then((db) => {
    const { data } = db.data.data;
    const arrayBuffer = new Uint8Array(data).buffer;
    arrayBufferToFile(
      arrayBuffer,
      `${format(
        new Date(params?.monthYear),
        DATE_TIME_FORMAT_YEAR_AND_MONTH_2
      )}_InspectionResult.pdf`
    );
  });
};




export const apiGetInspectionPDF = (
  groupId: string,
  machineId: string,
  inspectionId: string,
  timeZone: string,
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.inspectionsPdf(groupId, machineId, inspectionId), {
    params: { timeZone }, // Pass the timeZone as a query parameter
  });
}

export const getInspectionPDF = (
  groupId: string,
  machineId: string,
  inspectionId: string,
  timeZone: string,
) => {
  return apiGetInspectionPDF(groupId, machineId, inspectionId, timeZone)
    .then((response) => {
      const { data } = response.data; // Get base64 data from response
      const byteCharacters = atob(data); // Decode Base64 string
      const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      // Create download link
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `Inspection_${inspectionId}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url); // Free memory
    })
    .catch((error) => {
      console.error('Error downloading inspection PDF:', error);
      throw new Error('Failed to download the inspection PDF.');
    });
};



export const apiGetMachineReoportsPDF = (
  groupId: string,
  machineId: string,
  machineReportId: string,
  timeZone: string,
): Promise<AxiosResponse> => {
  // Pass timeZone as a query parameter
  return api.get(ENDPOINTS.machinesPdf(groupId, machineId, machineReportId), {
    params: { timeZone }, // Ensure the backend is configured to receive this
  });
};

export const getMachinePDF = (
  groupId: string,
  machineId: string,
  machineReportId: string,
  timeZone: string,
) => {
  return apiGetMachineReoportsPDF(groupId, machineId, machineReportId, timeZone)
    .then((response) => {
      const { data } = response.data; // Get base64 data from response
      const byteCharacters = atob(data); // Decode Base64 string
      const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      // Create download link
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `Report_${machineReportId}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url); // Free memory
    })
    .catch((error) => {
      console.error('Error downloading machine report PDF:', error);
      throw new Error('Failed to download the machine report PDF.');
    });
};

export const apiGetMachineMaintenanceReoportsPDF = (
  groupId: string,
  machineId: string,
  machineReportId: string,
  timeZone: string,
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.machinesMaintenancePdf(groupId, machineId, machineReportId),{
    params: { timeZone }, // Pass the timeZone as a query parameter
  });
};

export const getMachineMaintenancePDF = (
  groupId: string,
  machineId: string,
  machineReportId: string,
  timeZone: string,
) => {
  return apiGetMachineMaintenanceReoportsPDF(groupId, machineId, machineReportId, timeZone)
    .then((response) => {
      const { data } = response.data; 
      const byteCharacters = atob(data);
      const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `Maintenance_Report_${machineReportId}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url); 
    })
    .catch((error) => {
      console.error('Error downloading machine report PDF:', error);
      throw new Error('Failed to download the machine report PDF.');
    });
};





export const apiGetInspectionItems = (
  groupId: string,
  machineId: string,
  customInspectionFormId: string
): Promise<AxiosResponse> => {
  return api.get(
    ENDPOINTS.customInspectionFormDetail(groupId, machineId, customInspectionFormId)
  );
};

export const getInspectionItems = (
  groupId: string,
  machineId: string,
  customInspectionFormId: string
) => {
  return apiGetInspectionItems(groupId, machineId, customInspectionFormId).then((response) => {
    //console.log("Full API Response:", response.data); 
    const { data } = response.data;
    return data.inspectionItems.map((item: any) => ({
      id: item.inspectionItemId,
      name: item.name,
      description: item.description || "No description available",
      inspectionItemMedias: item.inspectionItemMedias || [],
      isRequired: item.isRequired,
      position:item.position,
      itemCode: item.itemCode
    }));
  });
};




export const apiGetInspectionItemsFormStatus = (
  groupId: string,
  machineId: string,
  customInspectionFormId: string
): Promise<AxiosResponse> => {
  return api.get(
    ENDPOINTS.customInspectionFormDetail(groupId, machineId, customInspectionFormId)
  );
};

export const getInspectionItemsFormStatus = (
  groupId: string,
  machineId: string,
  customInspectionFormId: string
) => {
  return apiGetInspectionItemsFormStatus(groupId, machineId, customInspectionFormId).then((response) => {
    //console.log("Full API Response:", response.data); // Log the full response for debugging

    const fullData = response.data; // Directly return the full response data
    return fullData;
  });
};







// API call to delete an inspection
export const apiDeleteInspection = (
  groupId: string,
  machineId: string,
  inspectionId: string
): Promise<AxiosResponse> => {
  // Construct and call the DELETE endpoint
  return api.delete(
    ENDPOINTS.inspectionDetail(groupId, machineId, inspectionId)
  );
};

// Helper function to use the delete API and handle response
export const deleteInspection = (
  groupId: string,
  machineId: string,
  inspectionId: string
) => {
  return apiDeleteInspection(groupId, machineId, inspectionId)
    .then((response) => {
      console.log("Delete successful:", response.data);
      return {
        //message: "Inspection deleted successfully",
        deletedInspectionId: inspectionId,
      };
    })
    .catch((error) => {
      console.error("Error deleting inspection:", error.response?.data || error.message);
      throw new Error("Failed to delete inspection");
    });
};

export const apiCreateInspection = (
  groupId: string,
  machineId: string,
  payload: any
): Promise<AxiosResponse> => {
  return api.post(ENDPOINTS.createInspection(groupId, machineId), payload);
};


export const apiGetInspectionDetail = (
  groupId: string,
  machineId: string,
  inspectionId: string,
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.getInspectionDetail(groupId, machineId, inspectionId));
};

export const apiGetInspectionItemDetail = (
  groupId: string,
  machineId: string,
  inspectionId: string,
  inspectionItemId: string,
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.getInspectionItemDetail(groupId, machineId, inspectionId, inspectionItemId));
};

export const apiUpdateInspection = (
  groupId: string,
  machineId: string,
  inspectionId: string,
  payload: any
): Promise<AxiosResponse> => {
  return api.put(
    ENDPOINTS.updateInspection(groupId, machineId, inspectionId),
    payload
  );
};

export const apiUpdateInspectionFiltered = (
  groupId: string,
  machineId: string,
  inspectionId: string,
  payload: any
): Promise<AxiosResponse> => {
  return api.put(
    ENDPOINTS.updateInspectionFiltered(groupId, machineId, inspectionId),
    payload
  );
};


export const apiUpdateInspectionItem = (
  groupId: string,
  machineId: string,
  inspectionId: string,
  inspectionResultId: string,
  payload: any 
): Promise<AxiosResponse> => {
  const endpoint = ENDPOINTS.updateInspectionItem(groupId, machineId, inspectionId, inspectionResultId);
  return api.put(endpoint, payload);
};

export const apiGetInspectionAt = (
  groupId: string,
  machineId: string,
  inspectionId: string
): Promise<AxiosResponse> => {
  const endpoint = ENDPOINTS.getInspectionAt(groupId, machineId, inspectionId);
  return api.get(endpoint);
};



export const apiUpdateInspectionAt = (
  groupId: string,
  machineId: string,
  inspectionId: string,
  payload: { inspectionAt: string }
): Promise<AxiosResponse> => {
  return api.put(
    ENDPOINTS.updateInspectionAt(groupId, machineId, inspectionId),
    payload
  );
};