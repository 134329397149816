import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import heic2any from "heic2any";
import Compressor from "compressorjs";
import update from "immutability-helper";
import { MediaUploadMenu } from "@app/pages/Features";
import { FileUploadButton } from "@app/components/atoms";
import { TYPE_FILE_UPLOAD } from "@app/constants";
import { setErrorsUploadFile, setIsLoading } from "@app/store";
import { iOSversion, isMobileDevice } from "@app/helpers";
import { format } from "date-fns";

const VIDEO_DURATION = 120;
const IMAGE_SIZE = 10485760;
const SUPPORT_EXTENSION_MAP = {
  video: "^.*.(mp4|mov)$",
  image: "^.*.(png|jpg|jpeg|gif|heic)$",
};
const CONVERT_TYPE = ".jpeg";
const MIME_TYPE = "image/jpeg";
const THUMBNAIL_CONVERT_TYPE = ".jpg";
const THUMBNAIL_MIME_TYPE = "image/jpg";

const validateExtension = (extensionRegExp: string, text: string) => {
  const regExp = new RegExp(extensionRegExp, "i");
  return regExp.test(text);
};

export const MediaUpload = ({
  medias,
  setMedias,
  id = "",
  typeFile = TYPE_FILE_UPLOAD.imageAndVideo,
  uploadDescription,
  onPreviewMedia,
  errMessage = "",
  setFileCount,
}: any) => {
  const dispatch = useDispatch();
  const [media, setMedia] = useState<
    File[] | (File & { duration: number })[] | any[]
  >([]);
  const [uploadErrMessage, setUploadErrMessage] = useState("");
  const { t } = useTranslation();

  const formatDate = (timestamp: number) => {
    return format(new Date(timestamp), "yyyy-MM-dd HH:mm:ss");
  };

  const setVideo = (
    e: React.ChangeEvent<HTMLInputElement>,
    action: string,
    index: number | undefined
  ) => {
    const validateVideo = validateExtension(
      SUPPORT_EXTENSION_MAP.video,
      e.target.value
    );

    // video type validation
    if (!validateVideo) {
      setUploadErrMessage(t("messages.wrongVideo"));
      dispatch(setIsLoading(false));
      e.target.value = "";
      return;
    }

    // create video dom
    const videoElem = document.createElement("video");
    // create blob data
    const video = URL.createObjectURL(e.target.files![0]);
    // set blob on video dom
    videoElem.src = video;
    videoElem.preload = "metadata";
    // override onloadmetadata to check & set duration on obj then set on state
    videoElem.onloadedmetadata = async () => {
      const duration = videoElem.duration;

      // video duration validation
      if (duration >= VIDEO_DURATION) {
        setUploadErrMessage(t("messages.errOver2min"));
        dispatch(setIsLoading(false));
        e.target.value = "";
        return;
      }
      const videoFile = e.target.files![0] as File & { duration: number };
      videoFile.duration = duration;

      const convertThumbnailFile = (videoThumpUrl: string) => {
        const file = new File(
          [b64toBlob(videoThumpUrl)],
          `${videoFile.name.slice(
            0,
            videoFile.name.length - 4
          )}${CONVERT_TYPE}`,
          {
            type: MIME_TYPE,
          }
        );
        let quality = 0.6;
        if (
          isMobileDevice &&
          iOSversion() > 0 &&
          iOSversion() < 16 &&
          file.size < IMAGE_SIZE / 5
        ) {
          quality = 1;
        }
        new Compressor(file, {
          mimeType: THUMBNAIL_MIME_TYPE,
          quality,
          resize: "cover",
          success(result) {
            // convert blob to File
            const lastDotIndex = videoFile.name.lastIndexOf(".");
            const file = new File(
              [result],
              `${videoFile.name.slice(
                0,
                lastDotIndex
              )}${THUMBNAIL_CONVERT_TYPE}`,
              {
                type: THUMBNAIL_MIME_TYPE,
              }
            );
            const formatVideoFile = Object.defineProperties(videoFile, {
              thumbnail: {
                value: file,
                configurable: true,
              },
              poster: {
                value: videoThumpUrl,
                configurable: true,
              },
            });
            if (action === "replace" && typeof index === "number") {
              setMedia((prev) => {
                const newMedia = update(prev, {
                  [index]: { $set: formatVideoFile },
                });
                setMedias(newMedia);
                return newMedia;
              });
            } else {
              setMedia((prev) => {
                const newMedia = [...prev, formatVideoFile];
                setMedias(newMedia);
                return newMedia;
              });
            }
            dispatch(setIsLoading(false));
          },
          error() {
            setUploadErrMessage(t("messages.errConmpressImg"));
            dispatch(setIsLoading(false));
          },
        });
      };
      const videoThumpUrl = await getThumbnailForVideo(video);
      convertThumbnailFile(videoThumpUrl);
      e.target.value = "";
    };
  };

  const getThumbnailForVideo = async (videoUrl: string) => {
    const video = document.createElement("video");
    const canvas = document.createElement("canvas");
    video.style.display = "none";
    canvas.style.display = "none";
    // Trigger video load
    await new Promise<void>((resolve) => {
      video.preload = "metadata";
      video.addEventListener("loadedmetadata", () => {
        video.width = video.videoWidth;
        video.height = video.videoHeight;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        // Play the video
        video.play();
      });
      video.addEventListener("timeupdate", () => resolve());
      video.src = videoUrl;
      video.muted = true;
      video.playsInline = true;
    });
    // Draw the thumbnail
    canvas
      .getContext("2d")
      ?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    const imageUrl = canvas.toDataURL("image/jpeg");
    return imageUrl;
  };

  const b64toBlob = (dataURI: string) => {
    var byteString = atob(dataURI.split(",")[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: "image/jpeg" });
  };

  const setImage = (
    e: React.ChangeEvent<HTMLInputElement>,
    action: string,
    index: number | undefined
  ) => {
    const validateImage = validateExtension(
      SUPPORT_EXTENSION_MAP.image,
      e.target.value
    );

    // image type validation
    if (!validateImage) {
      setUploadErrMessage(t("messages.wrongImage"));
      dispatch(setIsLoading(false));
      e.target.value = "";
      return;
    }
    const fileTarget = e.target.files![0];
    const imageSize = fileTarget.size;
    // size validation 10mb
    if (imageSize >= IMAGE_SIZE) {
      setUploadErrMessage(t("messages.errSizeImage"));
      dispatch(setIsLoading(false));
      e.target.value = "";
      return;
    }

    //convert file
    const convertFile = (file: File) => {
      let quality = 0.6;
      if (
        isMobileDevice &&
        iOSversion() > 0 &&
        iOSversion() < 16 &&
        imageSize < IMAGE_SIZE / 5
      ) {
        quality = 1;
      }
      new Compressor(file, {
        mimeType: MIME_TYPE,
        quality,
        resize: "cover",
        success(result) {
          handleUploadStore(file, result, action, index);
          dispatch(setIsLoading(false));
        },
        error() {
          setUploadErrMessage(t("messages.errConmpressImg"));
          dispatch(setIsLoading(false));
        },
      });
    };

    if (fileTarget.type === "image/heic") {
      heic2any({
        blob: fileTarget,
        toType: MIME_TYPE,
      }).then((result) => {
        const file = new File(
          [result as Blob],
          `${fileTarget.name.slice(
            0,
            fileTarget.name.length - 3
          )}${CONVERT_TYPE}`,
          {
            type: MIME_TYPE,
          }
        );
        convertFile(file);
      });
    } else {
      // compresse image
      // leave until decide validate size before/after compress
      convertFile(fileTarget);
    }
    e.target.value = "";
  };

  const handleUploadStore = (
    fileTarget: File,
    result: BlobPart,
    action: string,
    index: number | undefined
  ) => {
    
    const lastDotIndex = fileTarget.name.lastIndexOf(".");
    const file = new File(
      [result],
      `${fileTarget.name.slice(0, lastDotIndex)}${CONVERT_TYPE}`,
      {
        type: MIME_TYPE,
      }
    );
    
    if (action === "replace" && typeof index === "number") {
      setMedia((prev) => {
        const newMedia = update(prev, { [index]: { $set: file } });
        setMedias(newMedia);
        return newMedia;
      });
    } else {
      setMedia((prev) => {
        const newMedia = [...prev, file];
        setMedias(newMedia);
        return newMedia;
      });
    }
  };

 

  const handleUploadFile = (
    e: React.ChangeEvent<HTMLInputElement>,
    action: string,
    index?: number
  ) => {
    e.preventDefault();
    if (uploadErrMessage) setUploadErrMessage("");
  
    if (!!e.target.files?.length) {
      const files = Array.from(e.target.files); // Convert FileList to an array
      console.log("Number of files selected:", files.length);
  
      dispatch(setIsLoading(true));
  
      const existingFiles = [...media]; // Get current media
      const allFiles = [...existingFiles, ...files]; // Combine current media with new files
  
      setFileCount?.(allFiles.length); // Set combined count
      console.log("Total file count:", allFiles.length);
  
      files.forEach((file) => {
        const isVideo = file.type.includes("video");
        const isImage = file.type.includes("image");
  
        if (isVideo || isImage) {
          if (isVideo && typeFile !== TYPE_FILE_UPLOAD.image) {
            const fileEvent = { target: { files: [file], value: file.name } };
            setVideo(fileEvent as any, action, index); // Process video
          } else {
            const fileEvent = { target: { files: [file], value: file.name } };
            setImage(fileEvent as any, action, index); // Process image
          }
        } else {
          setUploadErrMessage(t("messages.wrongFileType"));
          dispatch(setIsLoading(false));
        }
      });
  
      e.target.value = ""; // Clear the file input
    }
  };
  
  
  
  const handleDeleteFile = (index: number) => {
    const newImages = [...media];
    newImages.splice(index, 1);
    setMedia(newImages);
    setMedias(newImages);
    setUploadErrMessage("");
    // if (setFileCount) {
    //   setFileCount((prevCount: number) => Math.max(prevCount - 1, 0));
    // }
    if (setFileCount) {
      setFileCount((prevCount: number) => {
        const newCount = Math.max(prevCount - 1, 0);
        console.log('Previous file count:', prevCount);
        console.log('New file count:', newCount);
        return newCount;
      });
    }
    
  };

  useEffect(() => {
    dispatch(setErrorsUploadFile(uploadErrMessage));
  }, [uploadErrMessage]);

  useEffect(() => {
    setMedia(medias || []);
  }, [medias]);

  useEffect(() => {
    if (errMessage) {
      setUploadErrMessage(errMessage);
    }
  }, [errMessage]);

  return (
    <div className="flex-direction-column wrapper-upload-file">
      <div
        className={`wrapper-upload flex-centered ${
          media?.length && "justify-content-start"
        }`}
      >
        {media &&
          media.map((media, index) => (
            <MediaUploadMenu
              key={`${media.name}_${media?.lastModified}_${index}`}
              media={media}
              index={index}
              onUploadFile={handleUploadFile}
              deleteFile={handleDeleteFile}
              typeFile={typeFile}
            />
            
          ))}
        <div
          className={`${media?.length > 1 ? "d-none" : ""} ${
            media?.length === 1 && "txt-sp-left"
          } text-center w-sp-100`}
        >
          {!media?.length &&
            uploadDescription?.map((description: string, index: number) => (
              <p
                key={index}
                className="txt-gray-1 fs-14 m-0 mt-8"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              ></p>
            ))}
          {(media?.length < 2 || media === null) && (
            <FileUploadButton
              className={`upload-button btn-secondary ${
                !media?.length && "mt-8"
              }`}
              htmlFor={`upload-file-${id}`}
            >
              {t("uploadFile")}
              <input
                type="file"
                accept={typeFile}
                className="btn-secondary"
                id={`upload-file-${id}`}
                hidden
                //multiple
                onChange={(e) => handleUploadFile(e, "add")}
              />
            </FileUploadButton>
          )}
        </div>
      </div>
      {uploadErrMessage && (
        <p
          className={`${
            media?.length ? "text-left" : ""
          } upload-err-message fs-12`}
        >
          {uploadErrMessage}
        </p>
      )}
    </div>
  );
};
