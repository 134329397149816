export function IconDeleteGray({ className = "" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 16 19"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0.5V1.5H16V3.5H15V16.5C15 17.6 14.1 18.5 13 18.5H3C1.9 18.5 1 17.6 1 16.5V3.5H0V1.5H5V0.5H11ZM3 16.5H13V3.5H3V16.5ZM5 5.5H7V14.5H5V5.5ZM11 5.5H9V14.5H11V5.5Z"
        fill="#4C5459"
      />
    </svg>
  );
}
