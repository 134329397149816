import { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { Locale, enUS, ja } from "date-fns/locale";
import {
  DATE_TIME_FORMAT_1,
  DATE_TIME_FORMAT_JP,
  DATE_TIME_FORMAT_YEAR_AND_MONTH,
  MIN_DATE_REPORT_SUMMARY,
} from "@app/constants";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { getMaxDate, isMobile } from "@app/helpers";

interface locales {
  [key: string]: Locale;
}
const locales: locales = { en: enUS, ja };

export interface DatePickerInputProps {
  label: string;
  value: Date;
  onChange: (newValue: any) => void;
  onAccept: (newValue: any) => void;
  views?: ("year" | "month" | "day")[]; // Add views prop
  minDate?: Date; // Optional minDate
  maxDate?: Date; // Optional maxDate
  disableFuture?: boolean;
  
}

export const DatePickerInput: FC<DatePickerInputProps> = ({
  label,
  value,
  onChange,
  onAccept,
  views = ["year", "month", "day"], // Default to all views
  minDate = new Date("2025-01-01"), // Default minDate
  maxDate = new Date("2025-12-31"), // Default maxDate
  disableFuture = false, // Default disableFuture to false
}: DatePickerInputProps) => {
  const { i18n } = useTranslation();
  const locale = locales[i18n?.language];
  const calendarInputRef = useRef<HTMLInputElement>(null); 


  

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <div className="input-datePicker">
        <DatePicker
          inputRef={calendarInputRef}
          label={label}
          value={value}
          onChange={isMobile() ? undefined : onChange}
          onAccept={isMobile() ? onAccept : undefined}
          views={views} // Pass views prop to DatePicker
          minDate={minDate} // Pass minDate
          maxDate={maxDate} // Pass maxDate
          disableFuture={disableFuture}
          slots={{
            openPickerIcon: CalendarMonthOutlinedIcon,
          }}
          slotProps={{
            toolbar: {
              toolbarFormat:
                locale === locales.ja ? DATE_TIME_FORMAT_JP : undefined,
            },
            calendarHeader: {
              format: DATE_TIME_FORMAT_YEAR_AND_MONTH,
            },
            field: {
              readOnly: true,
             
            },
            dialog: {
              sx: {
                "& .MuiPaper-root": {
                  minWidth: "unset",
                  width: "unset",
                },
                "& .MuiPickersCalendarHeader-label": {
                  fontWeight: 700,
                },
                "& .MuiTypography-overline": {
                  color: "#fff",
                },
                "& .MuiPickersLayout-contentWrapper": {
                  borderTop: "1px solid #e4e4e4",
                },
              },
            },
          }}
          //disableFuture
          //minDate={new Date(MIN_DATE_REPORT_SUMMARY)}
          //maxDate={getMaxDate()}
          format={DATE_TIME_FORMAT_1}
        />
        {/* Add icon for IPad and SP */}
        <CalendarMonthOutlinedIcon
          onClick={() => {
            calendarInputRef.current?.click();
          }}
          className="d-none-imp icon-calendar"
        />
      </div>
    </LocalizationProvider>
  );
};

