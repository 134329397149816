import { useEffect, useState } from "react";
import { Button } from "@app/components/atoms";
import { IconInvalidUrl, IconSpeaker, IconUser } from "@app/components/icons";
import { Avatar, Box } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Machine } from "@app/types";
import { INVALID_URL, ReportType } from "@app/constants";
import { scrollToTop } from "@app/helpers";

type MachineOpenProps = {
  dbMachineOpen?: Machine[];
  machineReportIdCard: (id: string) => void;
};



export function MachineOpen({
  dbMachineOpen,
  machineReportIdCard,
}: MachineOpenProps) {
  const { t } = useTranslation();
  const [isShowFullData, setIsShowFullData] = useState(false);
  const [machineOpens, setMachineOpens] = useState<Machine[] | undefined>();

  const handleClick = (id: string) => {
    machineReportIdCard(id);
    console.log(id)
  };

  useEffect(() => {
    setMachineOpens(dbMachineOpen);
  }, [dbMachineOpen]);

  return (
    
    <div className="machine-open pt-16 bg-white w-100">
      
      <div className="flex align-items-center ml-16 mr-16">
        <IconSpeaker />
        <h4 className="fs-16 txt-gray m-0 ml-5">
          {t("machineOpenNumberTitle.report_interval", {
            postProcess: "interval",
            count: dbMachineOpen?.length,
          })}
        </h4>
      </div>
      <ul className="machine-open-list">
        {machineOpens
          ?.slice(0, isShowFullData ? machineOpens.length : 3)
          .map((db: any) => (
            <li
              key={db?.id}
              className={`machine-open-item ${
                !db?.isRead ? "has-icon-badge" : ""
              }`}
              onClick={() => handleClick(db?.id)}
            >
              <div className="flex align-items-start justify-content-between">
                <div className="wrapper-content-left">
                  <div className="flex align-items-center">
                    <>
                      {db?.userPictureUrl ? (
                        db.userPictureUrl === INVALID_URL ? (
                          <Box
                            sx={{
                              width: 20,
                              height: 20,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <IconInvalidUrl />
                          </Box>
                        ) : (
                          <Avatar
                            sx={{
                              width: 20,
                              height: 20,
                            }}
                            alt="avatar"
                            src={db.userPictureUrl}
                            className="item-image"
                            imgProps={{
                              onError: () => {
                                const newMachineOpens = machineOpens.map(
                                  (item) => {
                                    if (
                                      item.machineReportId ===
                                      db.machineReportId
                                    ) {
                                      item.userPictureUrl = INVALID_URL;
                                    }
                                    return item;
                                  }
                                );
                                setMachineOpens(newMachineOpens);
                              },
                            }}
                          />
                        )
                      ) : (
                        <Box
                          sx={{
                            width: 20,
                            height: 20,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <IconUser />
                        </Box>
                      )}
                    </>
                    <div className="item-name ml-5 fs-14 txt-gray-1 word-break txt-pre-wrap">
                      {db?.reporterName}
                    </div>
                    <span
                      className={`item-label ml-12 txt-nowrap ${
                        db?.reportType === ReportType.INSPECTION
                          ? "label-inquire"
                          : "label-report"
                      }`}
                    >
                      {db?.reportType === ReportType.INSPECTION
                        ? t("labelInspection")
                        : t("labelReport")}
                    </span>
                  </div>
                  <p className="item-txt m-0 fs-16 txt-gray word-break txt-pre-wrap">
                    {db?.reportTitle}
                  </p>
                  <p className="item-des m-0 fs-14 txt-gray-1 word-break txt-pre-wrap">
                    {db?.firstReportComment}
                  </p>
                </div>
                <span className="item-txt-right txt-gray-1 txt-nowrap ml-12 fs-12">
                  {db?.timeSinceCommentCreation}
                </span>
              </div>
            </li>
          ))}
      </ul>
      {dbMachineOpen && dbMachineOpen?.length > 3 && (
        <div className="text-center">
          <Button
            className="btn-no-border btn-see-more"
            onClick={() => {
              if (isShowFullData) {
                scrollToTop();
              }
              setIsShowFullData(!isShowFullData);
            }}
          >
            <KeyboardArrowDown
              className={`icon-arrow ${isShowFullData ? "rotate-180" : ""}`}
            />
            {!isShowFullData ? t("seeMore") : t("close")}
          </Button>
        </div>
      )}
    </div>
  );
}
