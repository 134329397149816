export function IconPublishedRow() {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 4.75H4C3.59 4.75 3.25 5.09 3.25 5.5V8.5C3.25 8.91 3.59 9.25 4 9.25H20C20.41 9.25 20.75 8.91 20.75 8.5V5.5C20.75 5.09 20.41 4.75 20 4.75ZM18.75 7.75H5.25V6.25H18.75V7.75Z"
          fill="#28A745"
        />
        <path
          d="M6 10.75V19.75C6 20.85 6.9 21.75 8 21.75H16C17.1 21.75 18 20.85 18 19.75V10.75H6ZM12 12.25L14.5 15.25L10.5 15.25L12 12.25ZM12 17.25C11.45 17.25 11 17.7 11 18.25C11 18.8 11.45 19.25 12 19.25C12.55 19.25 13 18.8 13 18.25C13 17.7 12.55 17.25 12 17.25Z"
          fill="#28A745"
        />
      </svg>
    );
  }