import { useEffect, useRef, useState } from "react";
import { FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { format, formatISO, getDaysInMonth, getMonth, getYear, parse } from "date-fns";
import {
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_1,
  INVALID_URL,
  ItemInspectionEnum,
  ItemResultType,
  DevicePlatform,
  UploadFileType,

} from "@app/constants";
import {
  IconInvalidUrl,
  IconLocationMachine,
  IconReportCircle,
  IconTick,
  IconUser,
  IconDeleteRow,
  IconInputError
} from "@app/components/icons";
import { useTranslation } from "react-i18next";
import {  DataDialog, DataTooltip, Video } from "@app/components/atoms";
import {
  formatNumberWithCommas,
  formatTime,
  iOSversion,
  isMobile,
  resetScroll,
  REGEX,
  handleUploadStore,

} from "@app/helpers";
import {
  Avatar, Box, TextField, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Inspection, Machine } from "@app/types";
import { Swiper, SwiperSlide } from "swiper/react";
import { useParams } from "react-router-dom";
import {
  getMachineReportDetail,
  updateMachineReportStatus,
  deleteInspection,
  apiGetInspectionDetail,
  apiGetInspectionItemDetail,
  apiUpdateInspectionItem,
  apiUpdateInspectionAt,
  apiGetInspectionAt,

} from "@app/api";
import { useDispatch } from "react-redux";
import {
  setIsUpdatedMachineReportResponses, store,
  setShowAlert,

} from "@app/store";
import { useOutletContext } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  ConfirmDialogProps,
  MachineOpen,
  MediaUpload,
} from "@app/pages/Features";
import {

  PictureAsPdfOutlined,
} from "@mui/icons-material";
import {
  DatePickerInput,
} from "@app/components/atoms";
import {

  IconWarningFormEdit,

} from "@app/components/icons";



import {
  apiGetMachineReportResponses,
  apiGroupDetail,
  apiMachineReportActionChoices,
  apiPostMachineReportResponses,
  getMachineInfo,
  getMachineReports,

  apiGetMachineOperationReport,
  getMachinePDF,
} from "@app/api";
import {

  DialogTypes,

  ReportActions,
  ResponseStatus,
  StorageKey,

  MACHINE_OPERATION_REPORTS,

  INCIDENT_REPORTS,
  INSPECTION,
  MAINTENANCE_REPORTS,
  STATUS_UPDATES,
} from "@app/constants";
import {
  ConfirmDialog,




} from "@app/pages/Features";
import {

  MachineInfoType,
  machineReportActionChoice,
  screenPermission,
} from "@app/types";

import { Outlet } from "react-router-dom";
import {


  Checkbox,

  FormGroup,
  Switch,

} from "@mui/material";

import { useSelector } from "react-redux";
import {
  RootState,
  setIdReport,
  setIsMachinePage,
  setIsRequestedMachineInfo,

  setReportType,
} from "@app/store";
import {
  IconUpdate,


} from "@app/components/icons";
import {

  KeyboardArrowDown,

} from "@mui/icons-material";
import {

  resetIsContinuousLoading,
  scrollTo,
  setItem,
} from "@app/helpers";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Button } from "@mui/material";
const TABLE_MAX_HEIGHT = 450;
const TABLE_MIN_WIDTH = 864;

type MachineOpenProps = {
  dbMachineOpen?: Machine[];
  machineReportIdCard: (id: string) => void;
};

interface TabPanelProps {
  label: string;
  route: string;
  disabled?: boolean;
  className?: string;
}

type TableInspectionFormTabProps = {
  displayData: any;
  currentDate: Date;
  didScrollTop?: () => void;
  showDeleteRow: boolean;
  isEditing: boolean;
  onHeaderCellClick?: (inspectionIds: string[]) => void;
  setDisplayData: (data: any) => void;
  refreshInspectionData?: () => void;
  forceTableRefresh: number;
  onDownloadPdf: (inspectionId: string) => void;
}

export function TableInspectionFormTab({
  displayData,
  currentDate,
  didScrollTop,
  showDeleteRow,
  isEditing,
  onHeaderCellClick,
  setDisplayData,
  forceTableRefresh,
  refreshInspectionData,
  onDownloadPdf,
}: TableInspectionFormTabProps) {
  const { t } = useTranslation();

  const [dataCell, setDataCell] = useState<Inspection>();

  const [open, setOpen] = useState(false);

  const [openDialogReport, setOpenDialogReport] = useState(false);

  const [dbMachine, setDbMachine] = useState<any>();

  const { groupId, machineId } = useParams();

  const tableContainerRef = useRef<HTMLTableElement>(null);

  const dispatch = useDispatch();

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [selectedDate, setSelectedDate] = useState("");

  const convertHour = (time: Date) => {
    if (formatTime(time, "hh") === "12") {
      return `12:${formatTime(time, "mm")}`;
    }
    return formatTime(time, "hh:mm");
  };

  const handleMediaOnError1 = (index: number, mediaIndex: number) => {
    const newDbMachine = {
      ...dbMachine,
    };
    newDbMachine.machineReportResponses[index].machineReportMedias[
      mediaIndex
    ].mediaUrl = INVALID_URL;
    setDbMachine(newDbMachine);
  };

  const handleClickIconReport = async (machineReportId: string | null) => {
    console.log(machineReportId)
    if (!machineReportId) {
      console.warn("No machine report available for this item.");

      return;

    }

    if (groupId && machineId) {
      try {


        const db = await getMachineReportDetail(groupId, machineId, machineReportId);


        setDbMachine(db);
        setOpenDialogReport(true);

        if (!db?.isRead) {

          await updateMachineReportStatus(groupId, machineId, machineReportId, true);

          dispatch(setIsUpdatedMachineReportResponses(`${new Date()}`));
        }
      } catch (error) {
        console.error("Failed to fetch machine report details. Error:", error);
      }
    } else {
      console.error("Missing groupId or machineId");
    }
  };

  useEffect(() => {
    tableContainerRef.current &&
      resetScroll(tableContainerRef.current, () => {
        didScrollTop?.();
      });
  }, [displayData]);

  const [selectedInspectionId, setSelectedInspectionId] = useState<string | null>(null);

  const [openFormDelete, setOpenFormDelete] = useState(false);

  const { refreshMachineData } = useOutletContext<{ refreshMachineData: () => void }>();

  const [InspectiondialogOpen, setInspectionDialogOpen] = useState(false);

  const resetDialogs = () => {
    setDialogData(null);

    setInspectionDialogOpen(false);
  };

  const handleDeleteClick = (inspectionId: string) => {
    setSelectedInspectionId(inspectionId);
    setOpenFormDelete(true);
  };

  const [dialogData, setDialogData] = useState<{
    inspectionId: string;
    field: string;
    value: string;
    inspectionItemId?: string;
    result?: string;
    itemName?: string;
    comment?: string;
    resultType?: string;
    inspectionResultId?: string;
  } | null>(null);

  useEffect(() => {

  }, [dialogData]);

  useEffect(() => {

  }, [displayData]);

  useEffect(() => {

  }, [forceTableRefresh]);

  const confirmDelete = async () => {


    if (!selectedInspectionId || !groupId || !machineId) {
      console.error("Missing required parameters to delete inspection.");
      return;
    }

    try {

      const response = await deleteInspection(groupId, machineId, selectedInspectionId);


      store.dispatch(setShowAlert(t("deleteinspectionsuccess")));

      setDisplayData((prevData: any) => {

        return prevData.map((row: any) => {
          const updatedRow = { ...row };
          Object.keys(updatedRow).forEach((key) => {
            if (Array.isArray(updatedRow[key])) {
              updatedRow[key] = updatedRow[key].filter(
                (record: any) => record.inspectionId !== selectedInspectionId
              );
            }
          });
          return updatedRow;
        });
      });


      refreshMachineData();
      //refreshMachineData1();
      if (refreshInspectionData) {
        refreshInspectionData();
      }
      setOpenFormDelete(false);



    } catch (error) {
      console.error("Failed to delete inspection");

    }
  }

  const handleCellClick = async (
    inspectionId: string,
    field: string,
    value: string,
    inspectionItemId?: string,
    inspectionResultId?: string
  ) => {
    const fieldMapping: Record<string, string> = {
      "Service meter/SMR (h)": "SERVICE_METER",
      "サービスメーター/SMR（h）": "SERVICE_METER",
      "Odometer (km)": "ODOMETER",
      "オドメーター（km）": "ODOMETER",
    };

    const reverseFieldMapping: Record<string, string> = {
      SERVICE_METER: field,
      ODOMETER: field,
    };

    if (field in fieldMapping) {
      try {
        const inspectionItemsResponse = await apiGetInspectionDetail(groupId ?? "", machineId ?? "", inspectionId);
        const inspectionItems = inspectionItemsResponse.data.data.inspectionItems;

        const matchingItem = inspectionItems.find((item: any) => item.itemCode === fieldMapping[field]);

        if (!matchingItem) {
          console.error("Matching item not found for field:", field);
          return;
        }

        setInspectionDialogOpen(false);

        console.log(inspectionResultId);


        const localizedField = Object.keys(fieldMapping).find(
          key => fieldMapping[key] === matchingItem.itemCode && key === field
        ) || field;


        setDialogData({
          inspectionId,
          field: localizedField,
          value,
          inspectionItemId,
          inspectionResultId,
        });
        setInspectionDialogOpen(false);
      } catch (error) {
        console.error("Failed to fetch inspection details:", error);
      }
    }
  };

  const handleUpdateInspection = async () => {
    if (!dialogData || !groupId || !machineId) {
      console.error("Missing required parameters for update:", { groupId, machineId, dialogData });
      return;
    }

    try {
      if (!dialogData.inspectionItemId) {
        console.error("Missing inspectionResultId:", dialogData.inspectionResultId);
        return;
      }
      if (!dialogData.inspectionResultId) {
        console.error("Missing inspectionResultId:", dialogData.inspectionResultId);
        return;
      }


      const inspectionItemDetailResponse = await apiGetInspectionItemDetail(
        groupId,
        machineId,
        dialogData.inspectionId,
        dialogData.inspectionItemId
      );

      const inspectionItemDetail = inspectionItemDetailResponse.data.data.inspectionItems;


      const updatedPayload = {
        ...inspectionItemDetail,
        result: dialogData.value,
      };


      const updateResponse = await apiUpdateInspectionItem(
        groupId,
        machineId,
        dialogData.inspectionId,
        dialogData.inspectionResultId,
        updatedPayload
      );

      console.log("Update successful:", updateResponse.data);

      store.dispatch(setShowAlert(t("updateinspectionsuccess")
      ));




      const updatedResponse = await apiGetInspectionItemDetail(
        groupId,
        machineId,
        dialogData.inspectionId,
        dialogData.inspectionItemId
      );

      const updatedInspectionItem = updatedResponse.data.data.inspectionItems;


      setDisplayData((prevData: any) =>
        prevData.map((row: any) => {
          const updatedRow = { ...row };
          Object.keys(updatedRow).forEach((key) => {
            if (Array.isArray(updatedRow[key])) {
              updatedRow[key] = updatedRow[key].map((record: any) => {
                if (record.inspectionId === dialogData.inspectionId) {
                  const updatedResults = record.inspectionResults.map((result: any) => {
                    if (result.inspectionResultId === dialogData.inspectionResultId) {
                      return { ...updatedInspectionItem };
                    }
                    return result;
                  });
                  return { ...record, inspectionResults: updatedResults };
                }
                return record;
              });
            }
          });
          return updatedRow;
        })
      );

      refreshMachineData();
      //refreshMachineData1();
      if (refreshInspectionData) {
        refreshInspectionData();
      }
      resetDialogs();
    } catch (error: any) {
      if (error.response) {
        console.error("API Error Response:", error.response);
      } else if (error.request) {
        console.error("API Error Request:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  type MachineReportMedia = {
    filePath: string;
    fileName: string;
    mediaUrl: string;
    mimeType: string;
  };
  const [fileCount, setFileCount] = useState(0);

  const [removedMediaUrls, setRemovedMediaUrls] = useState<string[]>([]);

  const [selectedInspection, setSelectedInspection] = useState<{
    inspectionId: string;
    inspectionAt: string;
    machineId: string;
    groupId: string;
    resultType: string;
    resultValue: string;
    itemName: string;
    comment: string;
    inspectionItemId: string;
    machineReport?: {
      machineReportId: string;
      reportTitle: string;
      reportComment: string;
      currentStatus: string;
      machineReportMedias?: Array<{
        machineReportMediaId: any;
        fileName: string;
        filePath: string;
        mediaUrl: string;
        mimeType: string;
      }>;
    } | null;
  } | null>(null);

  const [inspectionName, setInspectionName] = useState("");

  const [inspectionTime, setInspectionTime] = useState("");

  const [inspectionComment, setInspectionComment] = useState("");

  const [resultValue, setResultValue] = useState("");

  const [mediaFiles, setMediaFiles] = useState<File[]>([]);

  const InspectionItemDialogOpen = async (
    record: { inspectionId: string; inspectionResults: any[] },
    row: { customInspectionItemId: string },
    isEditing: boolean,
    groupId: string | undefined,
    machineId: string | undefined
  ) => {
    if (!isEditing) return;

    const inspectionId = record.inspectionId;

    if (inspectionId && groupId && machineId) {
      try {

        setInspectionName("");
        setInspectionTime("");
        setInspectionComment("");
        setResultValue("");
        setMediaFiles([]);
        setFileCount(0);
        setSelectedInspection(null);

        setMediaFiles([]);
        const response = await apiGetInspectionDetail(groupId, machineId, inspectionId);
        const inspectionAt = response?.data?.data?.lastStatusUpdatedAt;
        const formattedInspectionAt = format(new Date(inspectionAt), "yyyy-MM-dd HH:mm:ss");
        const inspectionItems = response.data.data.inspectionItems;
        console.log(inspectionItems)
        console.log(inspectionAt)

        const inspectionItem = inspectionItems.find(
          (item: { inspectionItemId: string }) =>
            item.inspectionItemId === row.customInspectionItemId
        );

        if (inspectionItem) {

          const mediaFiles =
            inspectionItem.machineReport?.machineReportMedias?.map(
              (media: { fileName: string; mediaUrl: string; mimeType: string; filePath: string; thumbnailUrl: string }) => ({
                fileName: media.fileName,
                mediaUrl: media.mediaUrl,
                filePath: media.filePath,
                mimeType: media.mimeType,
                thumbnailUrl: media.thumbnailUrl


              })
            ) || [];
          //setMediaFilesNew(mediaFiles);
          //console.log(mediaFiles)
          console.log("Media files from API:", mediaFiles); // Log here to confirm data

          setMediaFiles(mediaFiles);

          const { name, machineReport, result } = inspectionItem;


          setInspectionName(name || "");
          setInspectionTime(formattedInspectionAt || "");
          setInspectionComment(machineReport?.reportComment || "");
          setResultValue(result || "");
          setSelectedInspection({
            ...inspectionItem,
            machineReport: {
              ...machineReport,
              machineReportMedias: mediaFiles,
            },
          });
          setMediaFiles(mediaFiles);
          setFileCount(mediaFiles.length);


          setInspectionDialogOpen(true);
        } else {
          console.warn("Inspection item not found for the selected row.");
        }
      } catch (error) {
        console.error("Error fetching inspection details:", error);
      }
    }
  };

  const handleUpdateInspectionItem = async () => {
    if (
      !selectedInspection ||
      !selectedInspection.inspectionId ||
      !selectedInspection.inspectionItemId
    ) {
      return;
    }
    if (!groupId || !machineId) {
      return;
    }

    const { inspectionId, inspectionItemId } = selectedInspection;
    console.log(removedMediaUrls)
    try {
      const response = await apiGetInspectionDetail(groupId, machineId, inspectionId);
      const inspectionItems = response.data.data.inspectionItems;

      const selectedInspectionItem = inspectionItems.find(
        (item: { inspectionItemId: string }) => item.inspectionItemId === inspectionItemId
      );
      console.log(selectedInspectionItem)
      console.log(removedMediaUrls)

      if (!selectedInspectionItem) {
        return;
      }

      const existingFilePaths =
        selectedInspectionItem.machineReport?.machineReportMedias?.map(
          (media: { filePath: string }) => media.filePath
        ) || [];

      const hasMatch = removedMediaUrls.some((removedUrl) =>
        existingFilePaths.includes(removedUrl)
      );

      console.log(hasMatch); // Output: true or false

      //   const existingFilePaths =
      //   selectedInspectionItem.machineReport?.machineReportMedias?.map(
      //     (media: { filePath: string }) => media.filePath
      //   ) || [];

      // console.log("Existing File Paths:", existingFilePaths);

      // Compare with removedMediaUrls
      const matchedRemovedFiles = removedMediaUrls.filter((removedUrl) =>
        existingFilePaths.includes(removedUrl)
      );

      console.log("Matched Removed Files:", matchedRemovedFiles);

      let machineReportMedias: MachineReportMedia[] =
        selectedInspectionItem.machineReport?.machineReportMedias || [];

      //       let machineReportMedias: MachineReportMedia[] =
      // selectedInspectionItem.machineReport?.machineReportMedias || [];

      // Filter out media objects with matching file paths
      machineReportMedias = machineReportMedias.filter(
        (media) => !removedMediaUrls.includes(media.filePath)
      );

      console.log("Updated Media List after Removal:", machineReportMedias);


      if (mediaFiles.length > 0) {
        const uploadedMedias = await handleConvertMedia1(mediaFiles);
        machineReportMedias = [...machineReportMedias, ...uploadedMedias];
      } else if (fileCount === 0) {

        machineReportMedias = [];
      }


      const machineReport =
        machineReportMedias.length > 0 || inspectionComment || resultValue !== "OK"
          ? {
            reportTitle: selectedInspectionItem.name,
            reportComment: inspectionComment || "",
            inspectionResultId: selectedInspectionItem.inspectionResultId,
            machineReportMedias,
          }
          : null;

      const updatedPayload = {
        ...selectedInspectionItem,
        result: resultValue,
        machineReport,
      };

      await apiUpdateInspectionItem(
        groupId,
        machineId,
        inspectionId,
        selectedInspectionItem.inspectionResultId,
        updatedPayload
      );
      store.dispatch(setShowAlert(t("updateinspectionsuccess")));

      setInspectionDialogOpen(false);
      setFileCount(0);
      setMediaFiles([]);
      setInspectionName("");
      setInspectionTime("");
      setInspectionComment("");
      setResultValue("");
      setSelectedInspection(null);
      setRemovedMediaUrls([])

      refreshMachineData();
      //refreshInspectionData();
      //refreshMachineData1();
      //refreshInspectionData();
      if (refreshInspectionData) {
        refreshInspectionData();
      }
    } catch (error) {
      console.error("Error updating inspection item:", error);
    }
  };

  const handleConvertMedia1 = async (files: File[]): Promise<MachineReportMedia[]> => {
    const array: (MachineReportMedia | null)[] = [];
    for await (const item of files) {
      const newItem = (await handleUploadStore(
        UploadFileType.MACHINE_REPORT,
        item
      )) as any;
      array.push(
        newItem
          ? {
            fileName: newItem?.name || newItem?.fileName,
            filePath: newItem?.filePath,
            mediaUrl: newItem?.filePath,
            mimeType: newItem?.type || newItem?.mimeType,
          }
          : null
      );
    }


    return array.filter((item): item is MachineReportMedia => item !== null);
  };

  const updateInspectionAt = async () => {
    try {
      if (!selectedInspectionId) {

        return;
      }
      if (!groupId || !machineId) {

        return;
      }
      const formattedDateTime = getFormattedDateTime();
      if (!formattedDateTime) {

        return;
      }
      const response = await apiUpdateInspectionAt(
        groupId,
        machineId,
        selectedInspectionId,
        { inspectionAt: formattedDateTime }
      );
      store.dispatch(setShowAlert(t("updateinspectionsuccesstime")));
      //store.dispatch(setShowAlert{t("Inspection updated successfully.")});
      { t("formAction.update") }
      //refreshMachineData1();
      refreshMachineData();
      if (refreshInspectionData) {
        refreshInspectionData();
      }
      handleClosePopup();
    } catch (error) {

    }
  };

  const [refreshKey, setRefreshKey] = useState(0);

  const uploadDescription1 = [
    `${t("dialogReport.pleaseUploadFile")}`,
    `<span class="fs-12">${t("dialogReport.thatCanBeUploadedAre")}</span>`,
    `<span class="fs-12">${t("dialogReport.fileFormats")}</span>`,
    `<span class="fs-12">${t("dialogReport.videosCanBeUploaded")}</span>`,
  ];

  const [downloadingMap, setDownloadingMap] = useState<{ [key: string]: boolean }>({});

  const handleDownloadClick = async (inspectionId: string) => {
    if (downloadingMap[inspectionId]) {
      console.warn(`Download already in progress for Inspection ID: ${inspectionId}`);
      return;
    }

    if (!inspectionId || !groupId || !machineId) {
      console.error("Missing required parameters");
      return;
    }


    setDownloadingMap((prev) => ({ ...prev, [inspectionId]: true }));
    console.log(`Downloading PDF for Inspection ID: ${inspectionId}`);

    try {
      await onDownloadPdf(inspectionId);
    } catch (error) {
      console.error(`Error downloading PDF for Inspection ID: ${inspectionId}`, error);
    } finally {

      setDownloadingMap((prev) => ({ ...prev, [inspectionId]: false }));
    }
  };

  const [selectedTime, setSelectedTime] = useState(() => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedTime = `${formattedHours}:${formattedMinutes} ${period}`;
    return formattedTime;
  });

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedDate("");
    setSelectedTime("");
    setSelectedInspectionId(null);
  };

  const [valueStartDate, setValueStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate());
    return date;
  });

  const handleChangeStartDate = (newValue: Date) => {
    const formattedDate = new Intl.DateTimeFormat("en-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(newValue);
    setSelectedDate(formattedDate);
  };

  const getFormattedDateTime = () => {
    if (!selectedDate || !selectedTime) {
      console.error("Invalid date or time:", { selectedDate, selectedTime });
      return "";
    }

    const combinedDate = new Date(`${selectedDate}T${selectedTime}`);
    if (isNaN(combinedDate.getTime())) {
      console.error("Invalid combined date/time value:", combinedDate);
      return "";
    }

    return formatISO(combinedDate, { representation: "complete" });
  };

  const handleOpenPopup = async (inspectionId: string, groupId: string, machineId: string) => {
    try {
      const response = await apiGetInspectionAt(groupId, machineId, inspectionId);
      const inspectionAt = response.data.data.inspectionAt;

      if (!inspectionAt) {
        throw new Error("Invalid inspectionAt value received from API.");
      }


      const inspectionAtUTC = inspectionAt.includes("Z") ? inspectionAt : `${inspectionAt}Z`;
      const parsedDate = new Date(inspectionAtUTC);

      if (isNaN(parsedDate.getTime())) {
        throw new Error("Failed to parse inspectionAt into a valid Date object.");
      }


      const formattedDate = parsedDate.toLocaleDateString("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })


      const hours = parsedDate.getHours().toString().padStart(2, "0");
      const minutes = parsedDate.getMinutes().toString().padStart(2, "0");
      const formattedTime = `${hours}:${minutes}`;


      console.log(`Formatted Date: ${formattedDate}`);
      console.log(`Formatted Time: ${formattedTime}`);


      setValueStartDate(parsedDate);
      setSelectedDate(formattedDate);
      setSelectedTime(formattedTime);
      setSelectedInspectionId(inspectionId);
      setIsPopupOpen(true);
    } catch (error) {
      console.error("Error in handleOpenPopup:", error);
    }
  };

  const [zoomedImage, setZoomedImage] = useState<string | null>(null);

  const handleImageClick = (imageUrl: string) => {
    setZoomedImage(imageUrl);
  };

  const handleCloseZoom = () => {
    setZoomedImage(null);
  };

  const handlePreviewMedia = (mediaUrls: string[]) => {
    console.log("Previewing media:", mediaUrls);
  };

  const [error, setError] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    const isInvalid =
      !REGEX.commonNameFieldCharacterMuiltiLineRegex.test(value) ||
      !REGEX.emojiRegex.test(value);

    setInspectionComment(value);

    if (isInvalid) {
      setError(true);
    } else {
      setError(false);
    }
  };





  // const [machineInfo, setMachineInfo] = useState<MachineInfoType | undefined>();
  // const [groupName, setGroupName] = useState<string>("");
  // const [machineReportsOpen, setMachineReportsOpen] = useState<Machine[]>();
  // const [isRequestedReports, setIsRequestedReports] = useState(false);
  // const [isRequestedGroupDetails, setIsRequestedGroupDetails] = useState(false);
  // const [machineReportId, setMachineReportId] = useState<string>();
  // const [openFormReport, setOpenFormReport] = useState<string>(INSPECTION);
  // const [targetRow, setTargetRow] = useState<any>(null);
  // const [openUpdateStatus, setOpenUpdateStatus] = useState(false);
  // const [screenPermission, setScreenPermission] = useState<screenPermission>();
  // const [mediaUploadErrorMsg, setMediaUploadErrorMsg] = useState("");
  // const [currentMachineReportResponseIds, setCurrentMachineReportResponseIds] =
  //   useState<string[]>([]);
  // const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  // const [machineReportActionChoices, setMachineReportActionChoices] =
  //   useState<machineReportActionChoice[]>();
  // const [isResolved, setIsResolved] = useState(false);
  // const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogProps>({});
  // const idReport = useSelector((state: RootState) => state?.report?.idReport);
  // const reportType = useSelector(
  //   (state: RootState) => state?.reportType?.reportType
  // );
  // const { isUpdatedMachineReportResponses } = useSelector(
  //   (state: RootState) => state.inspection
  // );
  // const [isRead, setIsRead] = useState(false);
  // const [reportTypeDialog, setReportTypeDialog] = useState(INSPECTION);
  // const [contentRef, setContentRef] = useState<any>(null);
  // const {
  //   register,
  //   setValue,
  //   reset,
  //   getValues,
  //   handleSubmit,
  //   watch,
  //   formState: { errors },
  // } = useForm({
  //   mode: "onChange",
  //   defaultValues: {
  //     reportComment: "",
  //     lat: null,
  //     lng: null,
  //     locationAccuracy: "string",
  //     devicePlatform: DevicePlatform.WEB,
  //     machineReportMedias: [] as File[],
  //     reportActionChoiceIds: [] as string[],
  //     status: "",
  //   },
  // });

  // const handleOutputPdf = () => {
  //   if (!!groupId && !!machineId && !!machineReportId) {
  //     const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //     console.log(`User Time Zone: ${userTimeZone}`);
  //     getMachinePDF(groupId, machineId, machineReportId, userTimeZone);
  //   } else {
  //     console.error("Missing required parameters: groupId, machineId, or machineReportId");
  //   }
  // };

  // const [isReportCommentInputFocus, setIsReportCommentInputFocus] =
  //   useState(false);

  // const [prevReportComment, setPrevReportComment] = useState("");

  // const uploadDescription = [
  //   `${t("dialogReport.pleaseUploadFile")}`,
  //   `<span class="fs-12">${t("dialogReport.thatCanBeUploadedAre")}</span>`,
  //   `<span class="fs-12">${t("dialogReport.fileFormats")}</span>`,
  //   `<span class="fs-12">${t("dialogReport.videosCanBeUploaded")}</span>`,
  // ];

  // const updateBtnDisableStatus = () => {
  //   const {
  //     reportComment,
  //     machineReportMedias,
  //     reportActionChoiceIds,
  //     status,
  //   } = watch();
  //   return (
  //     (!!reportComment ||
  //       !!reportActionChoiceIds?.length ||
  //       !!machineReportMedias?.length ||
  //       (!!status && status !== targetRow?.reportResponseStatus)) &&
  //     !errors?.reportComment?.type
  //   );
  // };

  // const resetForm = (isCancelUpdate = false) => {
  //   if (!isCancelUpdate) {
  //     setOpenFormReport(INSPECTION);
  //     setTargetRow(null);
  //     setMachineReportId("");
  //     dispatch(setIdReport(""));
  //   }
  //   setOpenUpdateStatus(false);
  //   setIsResolved(false);
  //   reset({
  //     reportComment: "",
  //   });
  //   setIsRead(false);
  // };

  // const handleClickConfirm = () => {
  //   if (openUpdateStatus) {
  //     setOpenDialogConfirm(true);
  //     setConfirmDialog({
  //       classBtnSubmit: "btn-primary",
  //       typeDialog: DialogTypes.CONFIRM,
  //       title: "titleConfirm",
  //       content: "",
  //       btnSubmitText: "ok",
  //       onCancel() {
  //         setOpenDialogConfirm(false);
  //       },
  //       onSubmit() {
  //         setOpenDialogConfirm(false);
  //         resetForm(true);
  //       },
  //     });
  //   } else {
  //     resetForm();
  //   }
  // };

  // const subTitleDialog = (targetRow: any) => {
  //   return (
  //     !!targetRow && (
  //       <div
  //         className={`sub-title-dialog ${openFormReport === MAINTENANCE_REPORTS ? "fw-400" : ""
  //           }`}
  //       >
  //         {format(new Date(targetRow.reportedAt), DATE_TIME_FORMAT)}
  //         {openFormReport === INCIDENT_REPORTS && (
  //           <div
  //             className={`status-resolved ml-10 ${targetRow.reportResponseStatus === ResponseStatus.RESOLVED
  //               ? "active"
  //               : ""
  //               }`}
  //           >
  //             <span>
  //               {targetRow?.reportResponseStatus === ResponseStatus.RESOLVED
  //                 ? t("resolved")
  //                 : t("open")}
  //             </span>
  //           </div>
  //         )}
  //       </div>
  //     )
  //   );
  // };

  // const handleOnChangeReportActionChoice = (e: any) => {
  //   const reportActionChoiceIds = getValues("reportActionChoiceIds") || [];
  //   const { checked, value: choiceId } = e.target;
  //   if (checked) {
  //     reportActionChoiceIds.push(choiceId);
  //   } else {
  //     const targetIndex = reportActionChoiceIds.findIndex(
  //       (id) => id === choiceId
  //     );
  //     if (targetIndex > -1) {
  //       reportActionChoiceIds.splice(targetIndex, 1);
  //     }
  //   }
  //   setValue("reportActionChoiceIds", reportActionChoiceIds);
  // };

  // const getMachineReportId = (id: string) => {
  //   setMachineReportId(id);
  //   setReportTypeDialog(INCIDENT_REPORTS);
  // };

  // const getGroupDetail = (id: string) => {
  //   return apiGroupDetail(id).then((db) => {
  //     const { data } = db.data;
  //     setGroupName(data?.groupName);
  //   });
  // };

  // const convertDataRow = (data: Machine[]) => {
  //   return data.map((row: any) => ({
  //     ...row,
  //     id: row?.machineReportId,
  //     reporterName: `${row?.surname} ${row?.givenName}`,
  //   }));
  // };

  // const handleMediaOnError = (index: number, mediaIndex: number) => {
  //   const newTargetRow = {
  //     ...targetRow,
  //   };
  //   newTargetRow.machineReportResponses[index].machineReportMedias[
  //     mediaIndex
  //   ].mediaUrl = INVALID_URL;
  //   setTargetRow(newTargetRow);
  // };

  // const handleMediaOnMaintenanceError = (
  //   mediaIndex: number,
  //   machineReportResponseIndex?: number
  // ) => {
  //   const newTargetRow = {
  //     ...targetRow,
  //   };
  //   if (machineReportResponseIndex !== undefined) {
  //     newTargetRow.machineReportResponses[
  //       machineReportResponseIndex
  //     ].machineReportMedias[mediaIndex].mediaUrl = INVALID_URL;
  //   } else {
  //     newTargetRow.machineReportMedias[mediaIndex].mediaUrl = INVALID_URL;
  //   }
  //   setTargetRow(newTargetRow);
  // };

  // const handleUserPictureOnError = (index?: number) => {
  //   if (index !== undefined) {
  //     const newMachineReportResponses = [...targetRow.machineReportResponses];
  //     newMachineReportResponses[index].userPictureUrl = INVALID_URL;
  //     const newTargetRow = {
  //       ...targetRow,
  //       machineReportResponses: newMachineReportResponses,
  //     };
  //     setTargetRow(newTargetRow);
  //   } else {
  //     const newTargetRow = { ...targetRow };
  //     newTargetRow.userPictureUrl = INVALID_URL;
  //     setTargetRow(newTargetRow);
  //   }
  // };

  // const getUserPicture = (
  //   item: any,
  //   machineReportResponseIndex?: number
  // ): JSX.Element => {
  //   const hasUserPictureUrl = item?.userPictureUrl;
  //   const isUserPictureUrlInvalid = item.userPictureUrl === INVALID_URL;
  //   let userPicture = (

  //     <Box
  //       sx={{
  //         width: 40,
  //         height: 40,
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <IconUser size={40} />
  //     </Box>
  //   );
  //   if (hasUserPictureUrl) {

  //     if (isUserPictureUrlInvalid) {
  //       userPicture = (
  //         <Box
  //           sx={{
  //             minWidth: 40,
  //             height: 40,
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center",
  //           }}
  //         >
  //           <IconInvalidUrl />
  //         </Box>
  //       );
  //     } else {

  //       userPicture = (
  //         <Avatar
  //           sx={{
  //             width: 40,
  //             height: 40,
  //           }}
  //           alt="avatar-reporter"
  //           src={item.userPictureUrl}
  //           imgProps={{
  //             onError: () =>
  //               handleUserPictureOnError(machineReportResponseIndex),
  //           }}
  //         />
  //       );
  //     }
  //   }
  //   return userPicture;
  // };

  // const handleShowReportAction = (actions: string[]) => {
  //   return actions?.map((action: any) => {
  //     const actionCode =
  //       action?.reportActionChoiceCode || action?.reportActionCode;
  //     switch (actionCode) {
  //       case ReportActions.SELF_REPAIR:
  //         return (
  //           <span
  //             key={"selfRepair"}
  //             className={`label-action label-purple fs-12 ${action?.reportActionChoiceCode ? "ml-5" : ""
  //               } `}
  //           >
  //             {t("selfRepair")}
  //           </span>
  //         );
  //       case ReportActions.REPAIR_REQUEST:
  //         return (
  //           <span
  //             key={"repairRequest"}
  //             className={`label-action label-pink fs-12 ${action?.reportActionChoiceCode ? "ml-5" : ""
  //               } `}
  //           >
  //             {t("repairRequest")}
  //           </span>
  //         );
  //       default:
  //         return null;
  //     }
  //   });
  // };

  // const handleConvertMedia = async (files: File[]) => {
  //   const array = [];
  //   for await (const item of files) {
  //     const newItem = (await handleUploadStore(
  //       UploadFileType.MACHINE_REPORT,
  //       item
  //     )) as any;
  //     array.push(
  //       newItem
  //         ? {
  //           fileName: newItem?.name || newItem?.fileName,
  //           filePath: newItem?.filePath,
  //           mediaUrl: newItem?.filePath,
  //           mimeType: newItem?.type || newItem?.mimeType,
  //         }
  //         : null
  //     );
  //   }
  //   return array;
  // };

  // const getMachineReportResponses = (
  //   groupId: string,
  //   machineId: string,
  //   machineReportId: string
  // ) => {
  //   return apiGetMachineReportResponses(groupId, machineId, machineReportId)
  //     .then((res) => {
  //       const { data, meta } = res.data;
  //       const { machineReportResponses, reportResponseStatus } = data;
  //       const machineReportResponseIds = machineReportResponses.map(
  //         (item: any) => item.machineReportResponseId
  //       );
  //       setValue("status", "");
  //       setIsResolved(reportResponseStatus === ResponseStatus.RESOLVED);
  //       setOpenFormReport(INCIDENT_REPORTS);
  //       setIsRead(!data.isRead && data.isRead !== null);
  //       setTargetRow(data);
  //       setScreenPermission(meta.screenPermission);
  //       setCurrentMachineReportResponseIds(machineReportResponseIds);
  //     })
  //     .catch(() => {
  //       setMachineReportId("");
  //     });
  // };

  // const getMachineOperationReport = (
  //   groupId: string,
  //   machineId: string,
  //   machineReportId: string
  // ) => {
  //   return apiGetMachineOperationReport(groupId, machineId, machineReportId)
  //     .then((res) => {
  //       const { data } = res.data;
  //       setOpenFormReport(MACHINE_OPERATION_REPORTS);
  //       setTargetRow(data);
  //     })
  //     .catch(() => {
  //       setMachineReportId("");
  //     });
  // };

  // const onSubmit = async (data: any) => {
  //   if (groupId && machineId && machineReportId) {
  //     setMediaUploadErrorMsg("");
  //     const newMachineReportMedias = await handleConvertMedia(
  //       data.machineReportMedias || []
  //     );
  //     if (newMachineReportMedias?.some((item) => item === null)) {
  //       setMediaUploadErrorMsg(t("messages.errConmpressImg"));
  //       return;
  //     }
  //     const payload = {
  //       ...data,
  //       machineReportMedias: newMachineReportMedias,
  //     };
  //     if (!data.status) {
  //       delete payload.status;
  //     }
  //     return apiPostMachineReportResponses(
  //       groupId,
  //       machineId,
  //       machineReportId,
  //       payload
  //     ).then((res) => {
  //       dispatch(setShowAlert(res?.data?.meta?.successMessage));
  //       reset({ reportActionChoiceIds: [], reportComment: "" });
  //       setOpenUpdateStatus(false);
  //       getMachineReportResponses(groupId, machineId, machineReportId).then(
  //         () => {
  //           dispatch(setIsUpdatedMachineReportResponses(`${new Date()}`));
  //         }
  //       );
  //     });
  //   }
  // };

  // const getMachineReportActionChoices = () => {
  //   return apiMachineReportActionChoices()
  //     .then((res) => {
  //       setMachineReportActionChoices(res.data.data);
  //     })
  //     .finally(() => {
  //       scrollToDialogContentBottom();
  //     });
  // };

  // const scrollToDialogContentBottom = () => {
  //   const contentEl = document.querySelector(
  //     ".dialog-report .MuiDialogContent-root .content"
  //   );
  //   contentEl &&
  //     scrollTo(contentEl, () => {
  //       scrollToDialogBottom();
  //     });
  // };

  // const renderMedia = (
  //   media: any,
  //   mediaIndex: number,
  //   machineReportResponseIndex?: number
  // ) => {
  //   const isInvalidMediaUrl = media?.mediaUrl === INVALID_URL;
  //   const isMediaMimeTypeVideo = media?.mimeType.includes("video");
  //   if (isInvalidMediaUrl) {
  //     return (
  //       <Box
  //         sx={{
  //           width: "100%",
  //           height: "100%",
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //         }}
  //       >
  //         <IconInvalidUrl />
  //       </Box>
  //     );
  //   }
  //   if (!isMediaMimeTypeVideo && !isInvalidMediaUrl) {
  //     return (
  //       <Box
  //         className="swiper-image img-report"
  //         component="img"
  //         alt="swiper-image"
  //         src={media.mediaUrl}
  //         onError={() =>
  //           machineReportResponseIndex
  //             ? handleMediaOnError(machineReportResponseIndex, mediaIndex)
  //             : handleMediaOnMaintenanceError(
  //               mediaIndex,
  //               machineReportResponseIndex
  //             )
  //         }
  //       />
  //     );
  //   }
  //   return (
  //     <Video
  //       media={media}
  //       onError={() =>
  //         machineReportResponseIndex
  //           ? handleMediaOnError(machineReportResponseIndex, mediaIndex)
  //           : handleMediaOnMaintenanceError(
  //             mediaIndex,
  //             machineReportResponseIndex
  //           )
  //       }
  //     />
  //   );
  // };

  // const scrollToDialogBottom = () => {
  //   const dialogContentEl = document.querySelector(
  //     ".dialog-report .MuiDialogContent-root"
  //   );
  //   dialogContentEl && scrollTo(dialogContentEl);
  // };

  // const getMachineInfoPage = (groupId: string, machineId: string) => {
  //   getMachineReports(groupId, machineId, {
  //     responseStatus: ResponseStatus.UNADDRESSED,
  //   })
  //     .then((db: { data: Machine[]; meta: any }) => {
  //       setMachineReportsOpen(convertDataRow(db?.data));
  //     })
  //     .finally(() => {
  //       setIsRequestedReports(true);
  //     });
  // };

  // const refreshMachineData = async () => {
  //   if (!groupId || !machineId) return;

  //   try {
  //     const updatedReports = await getMachineReports(groupId, machineId, {
  //       responseStatus: ResponseStatus.UNADDRESSED,
  //     });

  //     setMachineReportsOpen(convertDataRow(updatedReports?.data || []));
  //   } catch (error) {
  //     console.error("Failed to refresh machine data:", error);
  //   }
  // };

  // useEffect(() => {
  //   if (!!groupId && !!machineId) {
  //     setItem(StorageKey.IS_CONTINUOUS_LOADING, "true");
  //     getGroupDetail(groupId).finally(() => {
  //       setIsRequestedGroupDetails(true);
  //     });
  //   }
  // }, [groupId, machineId]);

  // useEffect(() => {
  //   !!groupId &&
  //     !!machineId &&
  //     !!isRequestedGroupDetails &&
  //     !!isUpdatedMachineReportResponses &&
  //     getMachineInfoPage(groupId, machineId);
  // }, [
  //   groupId,
  //   machineId,
  //   isRequestedGroupDetails,
  //   isUpdatedMachineReportResponses,
  // ]);

  // useEffect(() => {
  //   !!isRequestedReports &&
  //     groupId &&
  //     machineId &&
  //     getMachineInfo(groupId, machineId)
  //       .then((db) => {
  //         setMachineInfo({
  //           ...db,
  //           machineManufacturerName: db.isOtherMachineManufacturer
  //             ? db.customMachineManufacturerName || db.machineManufacturerName
  //             : db.machineManufacturerName,
  //           groupName,
  //         } as MachineInfoType);
  //       })
  //       .finally(() => {
  //         dispatch(setIsRequestedMachineInfo(`${new Date()}`));
  //       });
  // }, [isRequestedReports]);

  // useEffect(() => {
  //   setMachineReportId(idReport);
  // }, [idReport]);

  // useEffect(() => {
  //   setReportTypeDialog(reportType);
  // }, [reportType]);

  // useEffect(() => {
  //   if (groupId && machineId && machineReportId && reportTypeDialog) {
  //     switch (reportTypeDialog) {
  //       case INCIDENT_REPORTS:
  //         getMachineReportResponses(groupId, machineId, machineReportId);
  //         break;
  //       case MACHINE_OPERATION_REPORTS:
  //         getMachineOperationReport(groupId, machineId, machineReportId);

  //         break;

  //       default:
  //         setOpenFormReport(INSPECTION);
  //     }
  //   }
  // }, [machineReportId, reportTypeDialog]);

  // useEffect(() => {
  //   if (contentRef && targetRow) {
  //     scrollToDialogBottom();
  //   }
  // }, [contentRef, targetRow]);

  // useEffect(() => {
  //   if (groupId && machineId && machineReportId && isRead) {
  //     updateMachineReportStatus(
  //       groupId,
  //       machineId,
  //       machineReportId,
  //       isRead
  //     ).then(() => {
  //       if (!!machineReportsOpen) {
  //         const newMachineReportsOpen = [...machineReportsOpen];
  //         const index = machineReportsOpen?.findIndex(
  //           (item) => item.machineReportId === machineReportId
  //         );
  //         if (index > -1) {
  //           newMachineReportsOpen[index].isRead = true;
  //           setMachineReportsOpen(newMachineReportsOpen);
  //         }
  //       }
  //     });
  //   }
  // }, [isRead]);

  // useEffect(() => {
  //   if (openUpdateStatus) {
  //     setIsResolved(targetRow.reportResponseStatus === ResponseStatus.RESOLVED);
  //     getMachineReportActionChoices();
  //   } else {
  //     if (openFormReport) {
  //       scrollToDialogContentBottom();
  //     }
  //   }
  // }, [openUpdateStatus]);

  // useEffect(() => {
  //   dispatch(setIsMachinePage(true));
  //   return () => {
  //     dispatch(setIsRequestedMachineInfo(""));
  //     dispatch(setIsMachinePage(false));
  //     dispatch(setIdReport(""));
  //     dispatch(setReportType(""));
  //     resetIsContinuousLoading();
  //   };
  // }, []);

  

  return (
    <>

      <DataDialog open={InspectiondialogOpen}
        setOpen={() => setInspectionDialogOpen(false)}

        title={
          <p
            style={{
              fontWeight: "bold",
              fontSize: "24px",
              marginBottom: "0px",
              marginTop: "0px",
              color: "black",
            }}
          >
            {inspectionName}
          </p>
        }

        subTitle={

          <p
            style={{
              color: "#A0A0A0",
              fontSize: "13px",
              margin: "2px",
            }}
          >


            {inspectionTime}

          </p>
        }

        className="dialog-container"
      >
        <div className="radio-group-container mt-16">
          <FormLabel
            component="legend"
            className="radio-group-heading"
            sx={{ color: "black", fontWeight: "bold" }}
          >
            {t("normalabnormal")}
          </FormLabel>
          <RadioGroup
            aria-label="normal-abnormal"
            name="normalAbnormal"
            value={resultValue}
            onChange={(e) => setResultValue(e.target.value)}

            style={{ gap: "0px" }}
          >
            <FormControlLabel
              value="OK"
              control={
                <Radio
                  sx={{
                    color: "primary",
                    "&.Mui-checked": {
                      color: "#140a9a",
                    },
                  }}
                />
              }
              label={t("normal")}
              sx={{ color: "black" }}
              style={{ margin: "0" }}
            />
            <FormControlLabel
              value="ANOMARY"
              control={
                <Radio
                  sx={{
                    color: "primary",
                    "&.Mui-checked": {
                      color: "#140a9a",
                    },
                  }}
                />
              }
              label={t("abnormal")}
              sx={{ color: "black" }}
              style={{ margin: "0" }}
            />
          </RadioGroup>
        </div>


        <div className="wrapper-text-field mt-8">
          <TextField
            multiline
            rows={3}
            className="input-field"
            label="Comment"
            value={inspectionComment}
            fullWidth
            inputProps={{ maxLength: 150 }}
            onChange={handleInputChange}
            error={error}
            helperText={
              error
                ? t("invalidCharacters")
                : ""
            }
            InputProps={{
              endAdornment: (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  {error && (
                    <IconInputError />
                  )}
                </div>
              ),
            }}
          />
          {error && (
            <span className="error-text fs-12 ml-15 txt-red">

            </span>
          )}
        </div>

        <div className="update-media-upload-file mt-24 mb-24 w-100">


        </div>




        <div className="update-media-upload-file mt-24 mb-24 w-100">
          <MediaUpload
            key={refreshKey}
            setFileCount={setFileCount}
            medias={mediaFiles}

            setMedias={(files: File[]) => {
              console.log("Received media files in MediaUpload:", files);
              const existingFiles = mediaFiles.map((file: any) => file.filePath || file.mediaUrl || file.thumbnailUrl
              );
              const newFiles = files.map((file: any) => file.filePath || file.mediaUrl);
              const removedFiles = existingFiles.filter((mediaUrl: string) => !newFiles.includes(mediaUrl));
              if (removedFiles.length > 0) {
                console.log("Removed Media:", removedFiles);
                setRemovedMediaUrls((prev) => [...prev, ...removedFiles]);
              }
              setMediaFiles(files);
              setFileCount(files.length);


            }}
            onPreviewMedia={(mediaUrls: string[]) => {
              console.log("Media URLs sent from MediaUpload:", mediaUrls);

              handlePreviewMedia(mediaUrls);
            }}
            uploadDescription={uploadDescription1}
          />


        </div>






        <div className="warning flex justify-content-start" style={{ marginBottom: 12 }}>
          <IconWarningFormEdit />
          <span style={{ marginLeft: 12, flex: 1 }} className="txt-black-1">
            {t("formEdit.warningUpdateItem")}
          </span>
        </div>

        <div className="flex justify-content-end group-btn-footer"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
            paddingTop: "16px",

            zIndex: 1000,
          }}>
          <Button
            className="btn btn-no-border"
            onClick={() => {
              setInspectionDialogOpen(false);
              setFileCount(0);
              setMediaFiles([])
              setInspectionName("")
              setInspectionTime("")
              setInspectionComment("")
              setResultValue("")
              setSelectedInspection(null);
              setRemovedMediaUrls([])

            }
            }

          >
            {t("dialogReport.cancel")}
          </Button>

          <Button
            className={`btn btn-primary ml-8`}
            onClick={handleUpdateInspectionItem}
            disabled={
              error ||
              resultValue === "ANOMARY" &&
              (!inspectionComment && mediaFiles.length === 0 && fileCount === 0)
            }
          >
            {t("dialogReport.update")}
          </Button>
        </div>

      </DataDialog>


      <TableContainer

        className={`table-inspection-form ${isMobile() && iOSversion() < 15 ? "iOS-less-than-15" : ""
          }`}
        component={Paper}
        style={{
          maxHeight: TABLE_MAX_HEIGHT,
          borderRadius: isMobile() ? "0" : "8px",
        }}
        ref={tableContainerRef}
      >
        <Table

          className="data-table"
          sx={{ minWidth: TABLE_MIN_WIDTH, maxHeight: TABLE_MAX_HEIGHT }}
          stickyHeader
          size="small"
          aria-labelledby="tableTitle"
        >
          <TableHead>

            <TableRow>

              <TableCell
                sx={{
                  padding: "6.625px 12px 6.625px",
                }}
                align="left"
                rowSpan={2}
                style={{
                  minWidth: 197,
                  fontWeight: 700,

                  position: "sticky",
                  top: "-1px",
                  left: 0,
                  zIndex: 20,
                }}
                className="bg-purple txt-black fs-16"
              >
                {t("inspectionItem")}
              </TableCell>

              {Array.from({ length: getDaysInMonth(currentDate) }).map((_day, i) => {
                const columnDate = format(
                  new Date(getYear(currentDate), getMonth(currentDate), i + 1),
                  DATE_TIME_FORMAT_1
                );

                const matchingInspections =
                  displayData?.[0]?.[columnDate]?.map(
                    (record: any) => record.inspectionId
                  ) || [];

                return (
                  <TableCell
                    className="bg-purple fs-16 txt-black-1 fw-700"
                    sx={{
                      padding: "6.625px 12px 6.625px",
                    }}
                    align="center"
                    key={i}
                    style={{ minWidth: 108, maxWidth: 108, position: "sticky" }}
                    colSpan={
                      (displayData?.[0]?.[columnDate] as Array<any>)?.length || 1
                    }
                    onClick={() => {
                      if (onHeaderCellClick) {
                        onHeaderCellClick(matchingInspections);
                      }
                    }}
                  >
                    {i + 1}
                  </TableCell>
                );
              })}

            </TableRow>

            <TableRow>
              {Array.from({ length: getDaysInMonth(currentDate) }).map((_day, i) => {
                const columnDate = format(
                  new Date(getYear(currentDate), getMonth(currentDate), i + 1),
                  DATE_TIME_FORMAT_1
                );

                const records = displayData?.[0]?.[columnDate] as Array<any> || [];

                return records.length > 0 ? (
                  records.map((record: any, idx: number) => (
                    <TableCell
                      style={{ minWidth: 108, maxWidth: 108, cursor: isEditing ? "pointer" : "default", }}
                      align="center"
                      key={`${i}-${idx}`}
                      className="bg-purple"


                      onClick={async () => {
                        if (isEditing) {
                          if (record?.inspectionId && groupId && machineId) {

                            try {



                              handleOpenPopup(record.inspectionId, groupId, machineId);

                            } catch (error) {
                              console.error("Failed to fetch inspection id", error);
                            }
                          } else {
                            console.error("Missing required parameters: groupId or machineId");
                          }
                        }
                      }}

                    >
                      <p className="m-0 txt-gray-3 fw-500 fs-12">
                        {formatTime(record?.inspectionAt, "a")}
                      </p>
                      <p className="m-0 txt-gray-3 fw-500 fs-12">
                        {convertHour(record?.inspectionAt)}
                      </p>
                    </TableCell>
                  ))
                ) : (
                  <TableCell
                    className="bg-purple"
                    key={i}
                    align="center"
                    style={{ minWidth: 108, maxWidth: 108, cursor: "default", }}
                  />
                );
              })}
            </TableRow>


          </TableHead>

          <TableBody>


            <TableRow>

              <TableCell
                align="left"
                style={{
                  minWidth: 197,
                  fontWeight: 700,
                  position: "sticky",
                  left: 0,
                  zIndex: 1,
                }}
                className="bg-purple fs-16 txt-black-1"
              >

              </TableCell>

              {Array.from({ length: getDaysInMonth(currentDate) })?.map((_day, i) => {
                const records = displayData?.[0]?.[
                  format(
                    new Date(getYear(currentDate), getMonth(currentDate), i + 1),
                    DATE_TIME_FORMAT_1
                  )
                ] as any;



                return records?.length ? (
                  records.map((record: any, idx: number) => {

                    return (
                      <TableCell
                        style={{ minWidth: 108, maxWidth: 108 }}
                        align="center"
                        key={idx}

                      >

                        <span
                          className={`flex-centered pointer ${isEditing ? "disabled" : ""}`}
                          onClick={() => handleDownloadClick(record.inspectionId)}
                        >

                          <PictureAsPdfOutlined
                            className="icon-pdf"
                            style={{ fontSize: '24px', color: 'darkblue' }}
                          />

                        </span>

                        <span>
                          {record.time ? format(new Date(record.time), "HH:mm") : t("")}
                        </span>

                      </TableCell>
                    );
                  })
                )



                  : (
                    <TableCell style={{ minWidth: 108, maxWidth: 108 }} key={i} align="center" />
                  );
              })}

            </TableRow>


            {displayData?.map((row: any, index: number) => {
              return (

                <TableRow hover role="checkbox" tabIndex={-1} key={index}>

                  <TableCell
                    align="left"
                    style={{
                      minWidth: 197,
                      fontWeight: 400,
                      position: "sticky",
                      left: 0,
                      zIndex: 1,
                    }}
                    className="bg-purple fs-16 txt-black-1"
                  >
                    <DataTooltip isTextPre width="173px">
                      {row?.name}
                    </DataTooltip>
                  </TableCell>

                  {Array.from({ length: getDaysInMonth(currentDate) })?.map(
                    (_day, i) => {

                      let records = row?.[
                        format(
                          new Date(
                            getYear(currentDate),
                            getMonth(currentDate),
                            i + 1
                          ),
                          DATE_TIME_FORMAT_1
                        )
                      ] as any;
                      return !!records?.length ? (
                        records?.map((record: any, i: number) => {


                          let item = record?.inspectionResults?.find(
                            (ele: any) =>
                              ele?.customInspectionItemId ===
                              row?.customInspectionItemId
                          );

                          let itemShow;

                          if (item) {
                            if (!!item?.itemCode) {
                              itemShow =
                                !!item.result && (
                                  <span className="txt-right w-100">
                                    {formatNumberWithCommas(item.result)}
                                  </span>
                                );
                            } else if (item.result === ItemResultType.OK) {
                              itemShow = !!item?.machineReport?.machineReportId ? (
                                <span
                                  className={`flex-centered ${isEditing ? "" : "pointer"}`}
                                  // {!!machineReportsOpen?.length &&
                                  //   machineReportsOpen.map((report) => (
                                  //     <Button
                                  //       key={report.machineReportId}
                                  //       onClick={() => getMachineReportId("01JJHM5JEQMR7EQ27P43WKWEEB")}
                                  //     >
                                  //       {t("Open Incident Report")}
                                  //     </Button>
                                  //   ))}
                                  onClick={
                                    !isEditing
                                      ? () =>
                                        handleClickIconReport(item?.machineReport?.machineReportId)
                                      //getMachineReportId(item?.machineReport?.machineReportId)
                                      : undefined
                                  }
                                  style={getCircleStyle(true)}
                                >
                                  <IconTick fillColor="#0C7040" />
                                </span>
                              ) : (
                                <span style={getCircleStyle(false)}>
                                  <IconTick fillColor="#0C7040" />
                                </span>
                              );
                            } else {
                              itemShow = (
                                <span
                                  className={`flex-centered ${isEditing ? "" : "pointer"}`}
                                  onClick={
                                    !isEditing
                                      ? () =>
                                        handleClickIconReport(item?.machineReport?.machineReportId)
                                        //getMachineReportId(item?.machineReport?.machineReportId)
                                      : undefined
                                  }
                                >
                                  <IconReportCircle />
                                </span>
                              );
                            }
                          } else {
                            switch (row?.inspectionItemName) {
                              case ItemInspectionEnum.STAFF_NAME:
                                itemShow = (
                                  <DataTooltip isTextPre width={"90px"}>
                                    {`${record?.surname} ${record?.givenName}`}
                                  </DataTooltip>
                                );
                                break;
                              case ItemInspectionEnum.LOCATION:
                                itemShow =
                                  record?.lat && record?.lng ? (
                                    <span
                                      onClick={() => {
                                        if (!isEditing) {
                                          setDataCell(record);
                                          setOpen(true);
                                        }
                                      }}
                                      className={`flex-centered ${isEditing ? "" : "pointer"}`}
                                    >
                                      <IconLocationMachine />
                                    </span>
                                  ) : undefined;
                                break;
                            }
                          }



                          function getCircleStyle(hasBorder = true): React.CSSProperties {
                            return {
                              border: hasBorder ? "2px solid #d3d3d3" : "none",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "30px",
                              height: "30px",
                            };
                          }


                          const isSecondLast = index === displayData.length - 1;
                          const isThirdLast = index === displayData.length - 2;
                          return (

                            <TableCell
                              style={{
                                minWidth: 108, maxWidth: 108, cursor:

                                  isEditing && !isSecondLast && !isThirdLast
                                    ? "pointer"
                                    : "default",
                              }}
                              key={i}
                              align="center"

                              onClick={() => {
                                if (!isEditing) {




                                  const contextualGroupId = groupId;
                                  const recordGroupId = record?.groupId;
                                  const finalGroupId = contextualGroupId || recordGroupId;
                                  const machineId = record?.machineId;
                                  const customInspectionFormId = record?.customInspectionFormId;
                                  const inspectionId = record?.inspectionId;
                                  const customInspectionItemId = row?.customInspectionItemId;
                                  const customInspectionResultId = item?.inspectionResultId;






                                }



                                if (isEditing) {
                                  const field = row?.name;


                                  const fieldMapping: { [key: string]: string } = {
                                    "Service meter/SMR (h)": "SERVICE_METER",
                                    "サービスメーター/SMR（h）": "SERVICE_METER",
                                    "Odometer (km)": "ODOMETER",
                                    "オドメーター（km）": "ODOMETER",
                                  };


                                  const itemCode = fieldMapping[field];

                                  if (itemCode) {
                                    const result = record?.inspectionResults?.find(
                                      (r: { itemCode: string }) => r.itemCode === itemCode
                                    );

                                    if (result) {
                                      handleCellClick(
                                        record.inspectionId,
                                        field,
                                        result.result,
                                        row?.customInspectionItemId,
                                        item?.inspectionResultId
                                      );
                                    }

                                  } else {

                                    InspectionItemDialogOpen(record, row, isEditing, groupId, machineId);
                                  }
                                }
                              }}
                            >

                              {row?.name === "Odometer (km)" && record?.odometerValue ? (
                                formatNumberWithCommas(record?.odometerValue)
                              ) : row?.name === "Service meter/SMR (h)" &&
                                record?.serviceMeterValue ? (
                                formatNumberWithCommas(record?.serviceMeterValue)
                              ) : (
                                ""
                              )}

                              {!!itemShow && (
                                <span className="flex-centered fs-16 txt-black-1 lh-24">
                                  {itemShow}
                                </span>
                              )}


                            </TableCell>




                          );
                        })
                      ) : (
                        <TableCell
                          style={{ minWidth: 108, maxWidth: 108 }}
                          key={i}
                          align="center"
                        />
                      );
                    }
                  )}

                </TableRow>

              );
            })}

            {showDeleteRow && (
              <TableRow>

                <TableCell
                  align="left"
                  style={{
                    minWidth: 197,
                    fontWeight: 700,
                    position: "sticky",
                    left: 0,
                    zIndex: 1,
                  }}
                  className="bg-purple fs-16 txt-black-1"
                >
                  {t("deleteInspection")}
                </TableCell>

                {Array.from({ length: getDaysInMonth(currentDate) })?.map((_day, i) => {
                  const records = displayData?.[0]?.[
                    format(
                      new Date(getYear(currentDate), getMonth(currentDate), i + 1),
                      DATE_TIME_FORMAT_1
                    )
                  ] as any;

                  return !!records?.length ? (
                    records?.map((record: any, idx: number) => (
                      <TableCell
                        key={`${i}-${idx}`}
                        align="center"
                        style={{ minWidth: 108, maxWidth: 108 }}
                      >
                        <span
                          className="flex-centered pointer"
                          onClick={() => handleDeleteClick(record.inspectionId)}

                        >
                          <IconDeleteRow />
                        </span>
                      </TableCell>
                    ))
                  ) : (
                    <TableCell
                      style={{ minWidth: 108, maxWidth: 108 }}
                      key={i}
                      align="center"
                    />
                  );
                })}

              </TableRow>
            )}

          </TableBody>

        </Table>

      </TableContainer>

      <Dialog
        open={isPopupOpen}
        onClose={(event, reason) => {
          if (reason === "backdropClick" || reason === "escapeKeyDown") {

            return;
          }
          handleClosePopup();
        }}
      >
        <DialogTitle>{t("updateinspectiondatetime")}</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", gap: "1rem", alignItems: "center", marginTop: "1rem" }}>
            <div >
              <DatePickerInput
                label={t("report.labelStartDate")}
                value={valueStartDate}
                onChange={(newValue) => handleChangeStartDate(newValue)}
                onAccept={(newValue) => handleChangeStartDate(newValue)}

              />
            </div>

            <div style={{ flex: 1, marginTop: "-7px" }}>
              <TextField
                label={t("time")}
                type="time"
                value={selectedTime}
                onChange={(e) => setSelectedTime(e.target.value)}
                InputLabelProps={{ shrink: true }}
                margin="normal"
                style={{ width: "100%" }}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginTop: "1`rem",
            }}
          >
            <strong>{t("")}</strong>

          </div>

        </DialogContent>

        <DialogActions>
          <Button className="btn btn-no-border" onClick={handleClosePopup}>

            {t("cancel")}
          </Button>
          <Button
           className= {`btn btn-primary ml-8`}
            onClick={updateInspectionAt}

            disabled={!valueStartDate || !selectedTime}
          >
            {t("formAction.update")}
          </Button>

        </DialogActions>


      </Dialog>




      <DataDialog
        open={!!dialogData}
        title={dialogData?.field ? `${t("formAction.update")} ${dialogData.field}` : ""}
        setOpen={() => setDialogData(null)}
      >
        <div style={{ padding: "1rem" }}>
          <TextField
            label={`${t("enter")} ${dialogData?.field} *`}
            className={`input-field ${!dialogData?.value ? "error-border" : ""}`}
            value={dialogData?.value || ""}
            onChange={(e) => {
              const input = e.target.value;


              const validatedInput = input.match(/^\d*\.?\d{0,1}$/) ? input : dialogData?.value || "";

              setDialogData((prev) => (prev ? { ...prev, value: validatedInput } : prev));
            }}
            onBlur={() => {

              if (dialogData?.value) {
                const value = dialogData.value;


                if (/^\d+$/.test(value)) {
                  setDialogData((prev) => (prev ? { ...prev, value: `${value}.0` } : prev));
                }


                else if (value.endsWith(".")) {
                  setDialogData((prev) => (prev ? { ...prev, value: `${value}0` } : prev));
                }
              }
            }}
            fullWidth
            inputProps={{
              step: "0.1",
              min: "0",
            }}
            error={!dialogData?.value}
            helperText={!dialogData?.value ? t("required") : null}
            FormHelperTextProps={{
              className: "error-text",
            }}
          />
          <div style={{ marginTop: "1rem", display: "flex", justifyContent: "flex-end", gap: "10px" }}>
            <Button className="btn btn-no-border" onClick={() => setDialogData(null)}>
              {t("cancel")}
            </Button>

            <Button
              className= {`btn btn-primary ml-8`}
              onClick={handleUpdateInspection}
              disabled={!dialogData?.value}
            >
              {t("formAction.update")}
            </Button>
          </div>
        </div>
      </DataDialog>

      <DataDialog
        title={t("inspectionDelete.title")}
        open={openFormDelete}
        setOpen={setOpenFormDelete}
      >
        <div className="form-confirm-delete">
          <p className="txt-gray-1 fs-16">{t("inspectionDelete.content")}</p>
          <div className="button-form-delete flex justify-content-end">
            <Button
              onClick={() => setOpenFormDelete(false)}
              className="btn-cancel"
            >
              {t("inspectionDelete.cancel")}
            </Button>

            <Button onClick={confirmDelete} className="btn-delete">
              {t("inspectionDelete.delete")}
            </Button>
          </div>
        </div>
      </DataDialog>







      {dataCell && (
        <DataDialog
          title={t("location")}
          open={open}
          setOpen={setOpen}
          className="dialog-location-report"
        >
          <iframe
            title="map-location"
            className="map-iframe"
            loading="lazy"
            src={`https://maps.google.com/maps?&q="+${dataCell?.lat}, ${dataCell?.lng}"&output=embed`}
          />
          <span className="fs-14 txt-gray-1 label-dialog">{t("dateTime")}</span>
          <p className="fs-16 txt-gray mt-4">
            {dataCell?.inspectionAt &&
              formatTime(new Date(dataCell?.inspectionAt), DATE_TIME_FORMAT)}
          </p>
          <span className="fs-14 txt-gray-1 label-dialog">
            {t("tableMachineDetail.reporter")}
          </span>
          <div className="flex-centered justify-content-start mt-8">
            {dataCell?.userPictureUrl ? (
              dataCell?.userPictureUrl === INVALID_URL ? (
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconInvalidUrl />
                </Box>
              ) : (
                <Avatar
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                  alt="avatar-reporter"
                  src={dataCell?.userPictureUrl}
                  imgProps={{
                    onError: () => {
                      setDataCell({ ...dataCell, userPictureUrl: INVALID_URL });
                    },
                  }}
                />
              )
            ) : (
              <Box
                sx={{
                  width: 24,
                  height: 24,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconUser />
              </Box>
            )}
            <span className="fs-16 txt-gray ml-5 word-break txt-pre-wrap">
              {`${dataCell?.surname} ${dataCell?.givenName}`}
            </span>
          </div>
          <div className="flex justify-content-end">
            <Button onClick={() => setOpen(false)} className="btn btn-no-border">
              {t("close")}
            </Button>
          </div>
        </DataDialog>
      )}

      {!!dbMachine?.machineReportResponses?.length && (
        <DataDialog
          title={dbMachine?.reportTitle}
          open={openDialogReport}
          setOpen={setOpenDialogReport}
          className="dialog-report dialog-machine-report"
          dialogActions={
            <div className="flex justify-content-end">
              <Button
                onClick={() => {
                  setOpenDialogReport(false);
                  setDbMachine("");
                }}
                className="btn btn-no-border"
              >
                {t("close")}
              </Button>
            </div>
          }
        >
          {dbMachine?.machineReportResponses?.map(
            (item: any, index: number) => {
              return (
                <div
                  className="content pb-0 pt-0 ml-24 mr-24 bg-white"
                  key={index}
                >
                  <div className="report-card">
                    <div className="content-report border-0">
                      {!!item?.machineReportMedias?.length && (
                        <Swiper
                          cssMode={true}
                          navigation={true}
                          pagination={true}
                          mousewheel={true}
                          keyboard={true}
                          modules={[
                            Navigation,
                            Pagination,
                            Mousewheel,
                            Keyboard,
                          ]}
                          className="swiper mb-16"
                        >
                          {item?.machineReportMedias.map(
                            (media: any, mediaIndex: number) => (
                              <SwiperSlide key={mediaIndex}>
                                <div className="wrapper-file">
                                  {media?.mediaUrl === INVALID_URL ? (
                                    <Box
                                      sx={{
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <IconInvalidUrl />
                                    </Box>
                                  ) : media?.mimeType.includes("video") ? (
                                    <Video
                                      media={media}
                                      onError={() =>
                                        handleMediaOnError1(index, mediaIndex)
                                      }
                                    />
                                  ) : (
                                    <Box
                                      className="swiper-image img-report"
                                      component={`${media.mimeType.includes("video")
                                        ? "video"
                                        : "img"
                                        }`}
                                      controls
                                      alt="swiper-image"
                                      src={media.mediaUrl}
                                      onError={() =>
                                        handleMediaOnError1(index, mediaIndex)
                                      }
                                    />
                                  )}
                                </div>
                                <p
                                  className="created-at txt-gray-1 fs-12"
                                  style={{
                                    textAlign: "right",
                                    marginRight: "10px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {media.captureMediaAt ? (
                                    isNaN(new Date(media.captureMediaAt).getTime()) ? (
                                      <span style={{ color: "red" }}></span>
                                    ) : (
                                      `${format(
                                        new Date(
                                          new Date(media.captureMediaAt).toLocaleString("en-US", {
                                            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                          })
                                        ),
                                        "yyyy-MM-dd HH:mm:ss"
                                      )}`
                                    )
                                  ) : (
                                    ""
                                  )}
                                </p>

                              </SwiperSlide>
                            )
                          )}
                        </Swiper>
                      )}
                      {!!item?.reportComment && (
                        <p className="fs-16 mt-0 txt-black txt-pre-wrap word-break">
                          {item?.reportComment}
                        </p>
                      )}
                      <p className="fs-14 txt-gray-1 m-0">
                        {t("tableMachineDetail.reporter")}
                      </p>
                      <div className="flex-centered justify-content-start mt-8">
                        {!!item?.userPictureUrl ? (
                          item.userPictureUrl === INVALID_URL ? (
                            <Box
                              sx={{
                                width: 24,
                                height: 24,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <IconInvalidUrl />
                            </Box>
                          ) : (
                            <Avatar
                              sx={{
                                width: 24,
                                height: 24,
                              }}
                              alt="avatar-reporter"
                              src={item?.userPictureUrl}
                              imgProps={{
                                onError: () => {
                                  const newDbMachine = {
                                    ...dbMachine,
                                  };
                                  newDbMachine.machineReportResponses[
                                    index
                                  ].userPictureUrl = INVALID_URL;
                                  setDbMachine(newDbMachine);
                                },
                              }}
                            />
                          )
                        ) : (
                          <Box
                            sx={{
                              width: 24,
                              height: 24,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <IconUser />
                          </Box>
                        )}
                        <span className="fs-16 txt-black-1 ml-5 word-break">
                          {`${item?.surname} ${item?.givenName}`}
                        </span>
                      </div>
                      <p className="fs-14 txt-gray-1 mt-16 mb-0">
                        {t("reportedAt")}
                      </p>
                      <p className="fs-16 txt-black-1 mt-5 mb-0">
                        {dbMachine?.reportedAt &&
                          format(
                            new Date(dbMachine?.reportedAt),
                            DATE_TIME_FORMAT
                          )}
                      </p>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </DataDialog>


      )}

      {/* <DataDialog


        open={openFormReport === INCIDENT_REPORTS}
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "60%",
                fontSize: "21px",
                fontWeight: "bold",
              }}
              title={targetRow?.reportTitle || ""}
            >
              {targetRow?.reportTitle || ""}
            </span>
            <Button
              className="ml-8 btn btn-primary flex"
              style={{

                whiteSpace: "nowrap",
                gap: "8px",

              }}
              onClick={handleOutputPdf}
            >
              <PictureAsPdfOutlined style={{ fontSize: "16px" }} />
              {t("pdfOutput")}
            </Button>

            
          </div>
        }
        subTitle={
          <>
            {subTitleDialog(targetRow)}


          </>
        }


        className={`dialog-report ${openUpdateStatus ? "full-height" : ""
          }  ${openFormReport === INSPECTION ? "d-none-imp" : "d-block"}`}

        dialogActions={
          <div className="flex justify-content-end group-btn-footer">
            <Button
              className="btn btn-no-border"
              onClick={handleClickConfirm}
            >
              {t("dialogReport.cancel")}
            </Button>

            <Button
              className={`btn btn-primary ml-8 ${openUpdateStatus &&
                screenPermission?.allowCreateInspectionAndReport
                ? "d-block"
                : "d-none-imp"
                }`}
              onClick={handleSubmit(onSubmit)}
              disabled={!updateBtnDisableStatus()}
            >
              {t("dialogReport.update")}
            </Button>

            <Button
              className={`ml-8 btn btn-primary ${openUpdateStatus ||
                !screenPermission?.allowCreateInspectionAndReport
                ? "d-none-imp"
                : "flex"
                }`}
              type="button"
              onClick={() => setOpenUpdateStatus(true)}
            >
              <IconUpdate />
              <span className="ml-8">
                {t("dialogReport.updateStatus")}
              </span>
            </Button>

          </div>
        }
      >
        <Box component="form" autoComplete="off">

          <div
            ref={(ref) => setContentRef(ref)}
            className={`content ${openUpdateStatus ? "show-update" : "un-show-update"
              }`}
          >
            {openFormReport === INCIDENT_REPORTS &&
              targetRow?.machineReportResponses.map(
                (item: any, index: number) => {
                  const userPicture = getUserPicture(item, index);
                  const {
                    serviceMeterInHour,
                    isChangeStatus,
                    reportActionChoices,
                    reportResponseStatus: itemReportResponseStatus,
                    machineReportMedias: itemMachineReportMedias,
                    reportComment: itemReportComment,
                    subtype,
                  } = item;
                  const hasReportActionChoices =
                    !!reportActionChoices.length;
                  const isReportResponseStatusResolved =
                    itemReportResponseStatus === ResponseStatus.RESOLVED;
                  const itemMachineReportMediasLength =
                    itemMachineReportMedias.length;
                  return (
                    <div className="report-card" key={item}>

                      <div className="content-report">
                        <div className="header flex align-items-center">
                          {userPicture}
                          <div className="report-detail">
                            <h3 className="report-name txt-black-1 word-break txt-pre-wrap">{`${item.surname} ${item.givenName}`}</h3>
                            <p className="report-time txt-black-1">
                              {item.timeSinceCommentCreation}
                            </p>
                          </div>
                        </div>
                        {!!item.machineReportMedias?.length && (
                          <Swiper
                            cssMode={true}
                            navigation={true}
                            pagination={true}
                            mousewheel={true}
                            keyboard={true}
                            modules={[
                              Navigation,
                              Pagination,
                              Mousewheel,
                              Keyboard,
                            ]}
                            className="swiper mt-20"
                          >
                            {itemMachineReportMedias.map(
                              (media: any, mediaIndex: number) => {
                                return (
                                  <SwiperSlide
                                    key={`${media}_${mediaIndex}`}
                                  >
                                    <div className="wrapper-file">
                                      {renderMedia(
                                        media,
                                        mediaIndex,
                                        index
                                      )}

                                    </div>

                                    <p
                                      className="created-at txt-gray-1 fs-12"
                                      style={{ textAlign: "right", marginRight: "10px", fontWeight: "bold", }}
                                    >
                                      {media.captureMediaAt ? (
                                        isNaN(new Date(media.captureMediaAt).getTime()) ? (
                                          <span style={{ color: "red" }}></span>
                                        ) : (
                                          `${format(
                                            new Date(
                                              new Date(media.captureMediaAt).toLocaleString("en-US", {
                                                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                              })
                                            ),
                                            "yyyy-MM-dd HH:mm:ss"
                                          )}`
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </SwiperSlide>
                                );
                              }
                            )}
                          </Swiper>
                        )}

                        <div className="comment-section">



                          {(itemMachineReportMediasLength < 2 ||
                            itemReportComment) && (


                              <p
                                className={`text-content txt-black-1 txt-pre-wrap word-break ${itemMachineReportMediasLength > 1 &&
                                  itemReportComment &&
                                  "pt-0"
                                  }`}
                              >
                                {itemReportComment}

                              </p>
                            )}
                        </div>

                        {subtype !== STATUS_UPDATES && (
                          <div className="text-content">
                            <p className="txt-gray-1 fs-12 mt-0 mb-0 txt-pre-wrap">
                              {t("serviceMeterOrSMR")}
                            </p>
                            <p className="txt-black-1 fs-16 mt-0 mb-0 txt-pre-wrap">
                              {serviceMeterInHour !== null
                                ? formatNumberWithCommas(
                                  serviceMeterInHour,
                                  "h"
                                )
                                : "-"}
                            </p>
                          </div>
                        )}
                      </div>

                      {(isChangeStatus || hasReportActionChoices) && (
                        <div className="status">
                          <div className="content-status">
                            {hasReportActionChoices && (
                              <div className="txt-gray-1">
                                {t("tableMachineDetail.repairMethod")}:
                                {handleShowReportAction(
                                  reportActionChoices
                                )}
                              </div>
                            )}
                            {isChangeStatus && (
                              <div
                                className={`${hasReportActionChoices ? "pt-44" : ""
                                  } txt-gray-1`}
                              >
                                {t("dialogReport.status")}:
                                {isReportResponseStatusResolved ? (
                                  <span
                                    className={`status-resolved ml-5 active`}
                                  >
                                    {t("resolved")}
                                  </span>
                                ) : (
                                  <span className="status-resolved ml-5">
                                    {t("open")}
                                  </span>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                }
              )}
          </div>

          {openUpdateStatus && (
            <div className="update-status">
              <FormGroup>

                <div>
                  <span className="fs-14 txt-gray-3 mr-15">
                    {t("resolved")}
                  </span>
                  <Switch
                    className={`btn-switch ${isResolved && "checked"} `}
                    inputProps={{ "aria-label": "Switch A" }}
                    checked={isResolved}
                    onChange={() => {
                      setIsResolved(!isResolved);
                      setValue(
                        "status",
                        !isResolved
                          ? ResponseStatus.RESOLVED
                          : ResponseStatus.UNADDRESSED
                      );
                    }}
                  />
                </div>


                <div className="flex align-items-start repair">
                  <p className="label">
                    {t("dialogReport.repairAction")}
                  </p>
                  <div>
                    {machineReportActionChoices?.map((choice, index) => (
                      <FormControlLabel
                        className="checkbox"
                        key={index}
                        control={
                          <Checkbox
                            defaultChecked={currentMachineReportResponseIds.includes(
                              choice.reportActionChoiceId
                            )}
                            value={choice.reportActionChoiceId}
                            onChange={handleOnChangeReportActionChoice}
                          />
                        }
                        label={choice.reportActionChoiceName}
                        style={{
                          display: "block",
                          marginBottom: index ? 0 : 16,
                        }}
                      />
                    ))}
                  </div>
                </div>

                <div className="wrapper-text-field mt-8">
                  <TextField
                    multiline
                    rows={3}
                    className="input-field"
                    label={t("dialogReport.comment")}
                    id={
                      errors?.reportComment?.type
                        ? "outlined-error"
                        : "outlined-controlled"
                    }
                    error={
                      !!errors?.reportComment?.type &&
                      errors?.reportComment?.type !== "maxLength"
                    }
                    {...register("reportComment", {
                      validate: {
                        hasEmoji: (value) => REGEX.emojiRegex.test(value),
                        hasInvalidChar: (value) =>
                          REGEX.commonNameFieldCharacterMuiltiLineRegex.test(
                            value
                          ),
                      },
                      maxLength: 1000,
                      onBlur: (e) => {
                        if (errors?.reportComment?.type === "maxLength") {
                          setValue("reportComment", prevReportComment);
                        } else {
                          setValue(
                            "reportComment",
                            e.target.value?.trim()
                          );
                        }
                        setIsReportCommentInputFocus(false);
                      },
                      onChange: (e) => {
                        if (
                          !(errors?.reportComment?.type === "maxLength")
                        ) {
                          setPrevReportComment(
                            e.target.value.slice(0, -1)
                          );
                        }
                      },
                    })}
                    onFocus={() => {
                      setIsReportCommentInputFocus(true);
                    }}
                    onClick={() => {
                      if (
                        isReportCommentInputFocus &&
                        errors?.reportComment?.type === "maxLength"
                      ) {
                        setValue("reportComment", prevReportComment);
                      }
                    }}
                    inputProps={{ maxLength: 1000 }}
                  />
                  {errors?.reportComment?.type &&
                    errors?.reportComment?.type !== "maxLength" && (
                      <IconInputError />
                    )}
                </div>


                {errors?.reportComment?.type && (
                  <p className="txt-red fs-12 mt-0 mb-0 ml-15">
                    {errors?.reportComment?.type === "hasInvalidChar" &&
                      t("messages.validCharacters")}
                    {errors?.reportComment?.type === "hasEmoji" &&
                      t("messages.validCharacters")}
                  </p>
                )}

                <div className="update-media-upload-file mt-24 mb-24 w-100">
                  <MediaUpload
                    setMedias={(files: File[]) => {
                      setValue("machineReportMedias", files);
                    }}
                    uploadDescription={uploadDescription}
                    errMessage={mediaUploadErrorMsg}
                  />
                </div>

              </FormGroup>
            </div>

          )}

        </Box>
      </DataDialog> */}


      {/* <ConfirmDialog
        open={openDialogConfirm}
        setOpen={setOpenDialogConfirm}
        {...confirmDialog}
      /> */}

    </>
  );
}
