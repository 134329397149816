import type { FC } from "react";
import { memo, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDrag, useDrop } from "react-dnd";
import {
  DragIndicator,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { DialogTypes, ItemTypes, TYPE_FILE_UPLOAD } from "@app/constants";
import { TextField } from "@mui/material";
import { IconDelete, IconInputError } from "@app/components/icons";
import { useTranslation } from "react-i18next";
import { ConfirmDialog, MediaUpload } from "@app/pages/Features";
import { Button } from "@app/components/atoms";
import { REGEX, isMobileDevice } from "@app/helpers";
import { InspectionItem } from "@app/types";
import { setShowAlert, store } from "@app/store";
import type { Identifier } from "dnd-core";

export interface CardProps {
  id: string | number;
  index: number;
  card: InspectionItem;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  deleteCard: (index: number) => void;
  updateCard: (index: number, object: Object) => void;
  setValue: any;
  mediaUploadErrorMsg: string;
}

interface DragItem {
  id: string;
  index: number;
}

export const Card: FC<CardProps> = memo(function Card({
  id,
  card,
  moveCard,
  index,
  deleteCard,
  updateCard,
  setValue,
  mediaUploadErrorMsg,
}) {
  const { t } = useTranslation();
  const originalIndex = index;
  const [prevCardName, setPrevCardName] = useState(card.previousName);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isInspectionItemNameInputFocus, setIsInspectionItemNameInputFocus] =
    useState(false);
  const [
    isInspectionItemDescriptionInputFocus,
    setIsInspectionItemDescriptionInputFocus,
  ] = useState(false);
  const [prevCardDescription, setPrevCardDescription] = useState(
    card.description
  );

  const {
    register,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext<{
    inspectionItems: InspectionItem[];
  }>();

  const inspectionItemName = watch(`inspectionItems.${originalIndex}.name`);
  const previousName = watch(`inspectionItems.${originalIndex}.previousName`);
  const inspectionItemIsOpen = watch(`inspectionItems.${originalIndex}.isOpen`);
  const inspectionItemNameErrors =
    errors?.inspectionItems?.[originalIndex]?.name;
  const inspectionItemInspection = watch(
    `inspectionItems.${originalIndex}.description`
  );

  const [, drag] = useDrag({
    type: ItemTypes.CARD,
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem) {
      const dragIndex = item.index;
      const hoverIndex = index;
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const handleDeleteCard = () => {
    store.dispatch(setShowAlert(t("alreadyDeleted")));
    deleteCard(originalIndex);
  };

  const confirmProps = {
    typeDialog: DialogTypes.ERROR,
    title: "titleDeleteCard",
    content: "desDeleteCard",
    btnSubmitText: "delete",
    onCancel() {
      setIsOpenDeleteDialog(false);
    },
    onSubmit() {
      handleDeleteCard();
      setIsOpenDeleteDialog(false);
    },
    classBtnSubmit: "btn-primary bg-red",
  };

  const uploadDescription = [
    `${t("textDesUploadFile.pleaseUploadAFileShowingTheInspectionPoints")}`,
    `${t("textDesUploadFile.fileFormatsThatCanBeUploadedAre")}`,
  ];

  useEffect(() => {
    setPrevCardName(previousName);
  }, [previousName]);

  return (
    <li
      className="card-item card-item-draggable bg-gray"
      ref={(node) => {
        if (isMobileDevice || inspectionItemIsOpen) {
          return;
        }
        drag(drop(node));
      }}
    >
      <div
        className="card-item-title flex pointer"
        onClick={() =>
          setValue(
            `inspectionItems.${originalIndex}.isOpen`,
            !inspectionItemIsOpen
          )
        }
      >
        <div
          ref={(node) => {
            if (inspectionItemIsOpen) {
              return;
            }
            drag(drop(node));
          }}
          className="wrapper-drap-icon flex align-items-center"
        >
          <DragIndicator className="drag-icon" />
        </div>
        <div className="flex align-items-center justify-content-between w-100 wrapper-title-card">
          <span className="txt-card txt-pre-wrap word-break">
            {!inspectionItemNameErrors?.type && !isInspectionItemNameInputFocus
              ? inspectionItemName
              : prevCardName}
          </span>
          {inspectionItemIsOpen ? (
            <KeyboardArrowUp className="arrow-icon ml-10" />
          ) : (
            <KeyboardArrowDown className="arrow-icon ml-10" />
          )}
        </div>
      </div>
      <div className={`card-item-content ${inspectionItemIsOpen && "d-block"}`}>
        <div className="wrapper-text-field mt-8">
          <TextField
            id={
              inspectionItemNameErrors ? "outlined-error" : "outlined-required"
            }
            label={`${t("itemName")}*`}
            className="input-field"
            {...register(`inspectionItems.${originalIndex}.name`, {
              onBlur: (e) => {
                if (!e.target.value?.trim()) {
                  trigger(`inspectionItems.${originalIndex}.name`);
                }
                setValue(
                  `inspectionItems.${originalIndex}.name`,
                  e.target.value?.trim()
                );
                setIsInspectionItemNameInputFocus(false);
                if (!inspectionItemNameErrors || !inspectionItemName) {
                  setPrevCardName(inspectionItemName);
                  setValue(
                    `inspectionItems.${originalIndex}.previousName`,
                    inspectionItemName
                  );
                }
              },
              validate: {
                hasEmoji: (value) => REGEX.emojiRegex.test(value),
                hasInvalidChar: (value) =>
                  REGEX.commonNameFieldRegex.test(value),
                required: (value) => !!value.trim(),
              },
            })}
            inputProps={{ maxLength: 100 }}
            onFocus={() => {
              if (!inspectionItemNameErrors || !inspectionItemName) {
                setPrevCardName(inspectionItemName);
                setValue(
                  `inspectionItems.${originalIndex}.previousName`,
                  inspectionItemName
                );
              }
              setIsInspectionItemNameInputFocus(true);
            }}
            value={inspectionItemName}
            error={!!inspectionItemNameErrors}
          />
          {inspectionItemNameErrors?.type && <IconInputError />}
        </div>
        {inspectionItemNameErrors?.type ? (
          <p className="txt-red fs-12 mt-0 mb-0 ml-15">
            {inspectionItemNameErrors?.type === "required" &&
              t("messages.require")}
            {inspectionItemNameErrors?.type === "hasInvalidChar" &&
              t("messages.validCharacters")}
            {inspectionItemNameErrors?.type === "hasEmoji" &&
              t("messages.validCharacters")}
          </p>
        ) : (
          <span className="fs-12 ml-15 txt-gray-1">&#42;{t("required")}</span>
        )}
        <div className="wrapper-text-field mt-24">
          <TextField
            multiline
            maxRows={3}
            className="input-field input-text-area"
            id={
              errors?.inspectionItems?.[originalIndex]?.description?.type
                ? "outlined-error"
                : "outlined-controlled"
            }
            label={t("explanation")}
            error={
              !!errors?.inspectionItems?.[originalIndex]?.description &&
              errors?.inspectionItems?.[originalIndex]?.description?.type !==
                "maxLength"
            }
            {...register(`inspectionItems.${originalIndex}.description`, {
              validate: {
                hasEmoji: (value) => REGEX.emojiRegex.test(value),
                hasInvalidChar: (value) =>
                  REGEX.commonNameFieldCharacterMuiltiLineRegex.test(value),
              },
              maxLength: 150,
              onBlur: (e) => {
                if (
                  errors?.inspectionItems?.[originalIndex]?.description
                    ?.type === "maxLength"
                ) {
                  setValue(
                    `inspectionItems.${originalIndex}.description`,
                    prevCardDescription
                  );
                } else {
                  setValue(
                    `inspectionItems.${originalIndex}.description`,
                    e.target.value?.trim()
                  );
                }
                setIsInspectionItemDescriptionInputFocus(false);
              },
              onChange: (e) => {
                if (
                  !(
                    errors?.inspectionItems?.[originalIndex]?.description
                      ?.type === "maxLength"
                  )
                ) {
                  setPrevCardDescription(e.target.value.slice(0, -1));
                }
              },
            })}
            onFocus={() => {
              setIsInspectionItemDescriptionInputFocus(true);
            }}
            onClick={() => {
              if (
                isInspectionItemDescriptionInputFocus &&
                errors?.inspectionItems?.[originalIndex]?.description?.type ===
                  "maxLength"
              ) {
                setValue(
                  `inspectionItems.${originalIndex}.description`,
                  prevCardDescription
                );
              }
            }}
            inputProps={{ maxLength: 150 }}
            value={inspectionItemInspection}
          />
          {errors?.inspectionItems?.[originalIndex]?.description?.type &&
            errors?.inspectionItems?.[originalIndex]?.description?.type !==
              "maxLength" && <IconInputError />}
        </div>
        {errors?.inspectionItems?.[originalIndex]?.description?.type && (
          <p className="txt-red fs-12 mt-0 mb-24 ml-15">
            {errors?.inspectionItems?.[originalIndex]?.description?.type ===
              "hasInvalidChar" && t("messages.validCharacters")}
            {errors?.inspectionItems?.[originalIndex]?.description?.type ===
              "hasEmoji" && t("messages.validCharacters")}
          </p>
        )}
        <div className="mt-16">
          <MediaUpload
            medias={card.customInspectionItemMedias}
            setMedias={(files: File[]) => {
              if (files?.length) {
                updateCard(originalIndex, {
                  ...card,
                  isOpen: inspectionItemIsOpen,
                  customInspectionItemMedias: files,
                  name: inspectionItemName,
                  description: inspectionItemInspection,
                });
              }
            }}
            id={id}
            typeFile={TYPE_FILE_UPLOAD.image}
            uploadDescription={uploadDescription}
            errMessage={mediaUploadErrorMsg}
          />
        </div>
        <div className="flex-centered justify-content-end mt-24">
          <Button
            className="btn-no-border"
            onClick={() => setIsOpenDeleteDialog(true)}
          >
            <IconDelete />
            <span className="txt-red ml-10 fs-14">{t("delete")}</span>
          </Button>
        </div>
      </div>
      <ConfirmDialog
        open={isOpenDeleteDialog}
        setOpen={setIsOpenDeleteDialog}
        {...confirmProps}
      ></ConfirmDialog>
    </li>
  );
});
