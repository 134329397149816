export const ENDPOINTS = {
  login: "login",
  groups: "latest/groups",
  user: "latest/users/me",
  userSettings: "latest/users/settings",
  groupDetail: (id: string) => `latest/groups/${id}`,
  groupDetailCondition: (id: string) => `latest/groups/${id}/conditions`,


  groupDetailInspectionForms: (id: string) =>
    `latest/groups/${id}/inspection-forms`,


  groupDetailInspectionDeleteOrUpdate: (
    groupId: string,
    machineId: string,
    customInspectionFormId: string
  ) =>
    `latest/groups/${groupId}/machines/${machineId}/inspection-forms/${customInspectionFormId}`,


  // Endpoint utility function for URL construction

  updateInspectionFormAndItemsWeb: (
    groupId: string,
    machineId: string,
    customInspectionFormId: string
  ) =>
    `latest/groups/${groupId}/machines/${machineId}/inspection-forms/${customInspectionFormId}/items/Web`,

    
    // updateInspectionFormStatusArchive: (
    //   groupId: string,
    //   machineId: string,
    //   customInspectionFormId: string
    // ) =>
    //   `latest/groups/${groupId}/machines/${machineId}/inspection-forms/${customInspectionFormId}/status_form_archive`,

    //   updateInspectionFormStatusPublish: (
    //     groupId: string,
    //     machineId: string,
    //     customInspectionFormId: string
    //   ) =>
    //     `latest/groups/${groupId}/machines/${machineId}/inspection-forms/${customInspectionFormId}/status_form_unarchive`,

    updateInspectionFormStatus: (
      groupId: string,
      machineId: string,
      customInspectionFormId: string
    ) =>
      `latest/groups/${groupId}/machines/${machineId}/inspection-forms/${customInspectionFormId}/status`,
    

  customInspectionFormDetail: (
    groupId: string,
    machineId: string,
    customInspectionFormId: string
  ) =>
    `latest/groups/${groupId}/machines/${machineId}/inspection-forms/${customInspectionFormId}`,


  deleteInspection: (
    groupId: string,
    machineId: string,
    inspectionId: string
  ) =>
    `latest/groups/${groupId}/machines/${machineId}/inspections/${inspectionId}`,

  // Endpoint for getting inspection details
  inspectionDetail: (
    groupId: string,
    machineId: string,
    inspectionId: string
  ) =>
    `latest/groups/${groupId}/machines/${machineId}/inspections/${inspectionId}`,


    groupDetailInspectionFormStatus: (id: string) =>
    `latest/groups/${id}/inspection-form-status`,

  


  groupMachinesDetail: (id: string) => `latest/groups/${id}/machines`,

  updateMachineInGroup: (groupId: string, machineId: string) =>
    `latest/groups/${groupId}/machines/${machineId}`,

  updateMachineInGroupStatus: (groupId: string, machineId: string) =>
    `latest/groups/${groupId}/machines/${machineId}/machine-conditions`,

  postMachineInGroup: (groupId: string) =>
    `latest/groups/${groupId}/machines`,

  groupMachineManufacturers: (groupId: string) =>
    `latest/groups/${groupId}/machineManufacturers`,

  groupMachineTypes: (groupId: string) =>
    `latest/groups/${groupId}/machinetype`,
  machineInfo: (groupId: string, machineId: string) =>
    `latest/groups/${groupId}/machines/${machineId}`,

  machineDetailInfo: (groupId: string, machineId: string) =>
    `latest/groups/${groupId}/machines/${machineId}`,
  
  machineReport: (groupId: string, machineId: string) =>
    `latest/groups/${groupId}/machines/${machineId}/machine-reports`,

  inspectionFormTemplates: (groupId: string, machineId: string) =>
    `latest/groups/${groupId}/machines/${machineId}/inspection-form-templates`,

  inspectionForms: (groupId: string, machineId: string) =>
    `latest/groups/${groupId}/machines/${machineId}/inspection-forms`,

  inspectionFormsWeb: (groupId: string, machineId: string) =>
    `latest/groups/${groupId}/machines/${machineId}/inspection-forms/web`,

  customInspectionFormDetails: (
    groupId: string,
    machineId: string,
    customInspectionFormId: string
  ) =>
    `latest/groups/${groupId}/machines/${machineId}/inspection-forms/${customInspectionFormId}/web`,
  
  inspectionFormDetail: (
    groupId: string,
    machineId: string,
    inspectionFormId: string
  ) =>
    `latest/groups/${groupId}/machines/${machineId}/inspection-form-templates/${inspectionFormId}`,


    updateInspectionItemStatus: (groupId: string, machineId: string, inspectionItemId: string) =>
      `latest/groups/${groupId}/machines/${machineId}/inspection-forms-items/${inspectionItemId}`,
  


  inspectionForm: (groupId: string, machineId: string) =>
    `latest/groups/${groupId}/machines/${machineId}/inspection-forms`,
  uploadUrls: "/latest/blob-storage/generate-video-upload-urls",
  uploadStore: "",

  machineReportResponses: (
    groupId: string,
    machineId: string,
    machineReportId: string
  ) =>
    `latest/groups/${groupId}/machines/${machineId}/machine-reports/${machineReportId}/machine-report-responses`,

  machineReportStatus: (
    groupId: string,
    machineId: string,
    machineReportId: string
  ) =>
    `latest/groups/${groupId}/machines/${machineId}/machine-reports/${machineReportId}/read-status`,


  inspections: (groupId: string, machineId: string) =>
    `latest/groups/${groupId}/machines/${machineId}/inspections`,

  machineReportActionChoices: () => `latest/report-action-choices`,
  machineReportDetail: (
    groupId: string,
    machineId: string,
    machineReportId: string
  ) =>
    `/latest/groups/${groupId}/machines/${machineId}/machine-reports/${machineReportId}`,
    
  inspectionPdf: (groupId: string, machineId: string) =>
    `/latest/groups/${groupId}/machines/${machineId}/inspection-pdf`,

  inspectionsPdf: (groupId: string, machineId: string, inspectionId: string) =>
    `/latest/groups/${groupId}/machines/${machineId}/inspections/${inspectionId}/pdf`,

  machinesPdf: (groupId: string, machineId: string, machineReportId: string) =>
    `/latest/groups/${groupId}/machines/${machineId}/machineReportId/${machineReportId}/pdf`,

  machinesMaintenancePdf: (groupId: string, machineId: string, machineReportId: string) =>
    `/latest/groups/${groupId}/machines/${machineId}/machineMaintenanceReportId/${machineReportId}/pdf`,

  machineOperationReport: (
    groupId: string,
    machineId: string,
    machineReportId: string
  ) =>
    `latest/groups/${groupId}/machines/${machineId}/machine-operation-reports/${machineReportId}`,
  machineReportInfo: (groupId: string, machineId: string) =>
    `/latest/groups/${groupId}/machines/${machineId}/machine-report-info`,
  machineSummary: (groupId: string, machineId: string) =>
    `/latest/groups/${groupId}/machines/${machineId}/summary`,
  maintenanceReport: (
    groupId: string,
    machineId: string,
    machineReportId: string
  ) =>
    `/latest/groups/${groupId}/machines/${machineId}/maintenance-reports/${machineReportId}`,



  createInspection: (groupId: string, machineId: string) =>
    `latest/groups/${groupId}/machines/${machineId}/inspections`,


  getInspectionDetail: (groupId: string, machineId: string, inspectionId: string) =>
    `latest/groups/${groupId}/machines/${machineId}/inspections/${inspectionId}`,

  getInspectionItemDetail: (groupId: string, machineId: string, inspectionId: string, inspectionItemId: string) =>
    `latest/groups/${groupId}/machines/${machineId}/inspections/${inspectionId}/customInspectionItemId/${inspectionItemId}`,

  updateInspection: (groupId: string, machineId: string, inspectionId: string) =>
    `latest/groups/${groupId}/machines/${machineId}/inspections/${inspectionId}`,

  updateInspectionFiltered: (groupId: string, machineId: string, inspectionId: string) =>
    `latest/groups/${groupId}/machines/${machineId}/inspections/${inspectionId}/filtered`,

  updateInspectionItem: (groupId: string, machineId: string, inspectionId: string, inspectionResultId: string,) =>
    `latest/groups/${groupId}/machines/${machineId}/inspections/${inspectionId}/inspectionResultId/${inspectionResultId}`,

  getInspectionAt: (groupId: string, machineId: string, inspectionId: string) =>
    `latest/groups/${groupId}/machines/${machineId}/inspections/${inspectionId}/inspection-at`,

  updateInspectionAt: (groupId: string, machineId: string, inspectionId: string) =>
    `latest/groups/${groupId}/machines/${machineId}/inspections/${inspectionId}/inspection-at`,
  
};
