import { useEffect, useRef, useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, Menu, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IconInvalidUrl, IconPlay } from "@app/components/icons";
import EXIF from "exif-js";


interface MediaUploadMenuProps {
  media: File | (File & { duration: number }) | any;
  index: number;
  onUploadFile: (
    e: React.ChangeEvent<HTMLInputElement>,
    action: string,
    index?: number
  ) => void;
  deleteFile: (index: number) => void;
  typeFile: string;
  onPreviewMedia?: (mediaUrl: string | string[]) => void;
}

export const MediaUploadMenu = ({
  media,
  index,
  onUploadFile,
  deleteFile,
  typeFile,
  onPreviewMedia,
}: MediaUploadMenuProps) => {
  const [isOpenActionMenu, setIsOpenActionMenu] = useState(false);

  const { t } = useTranslation();
  const anchorElRef = useRef(null);
  const [mediaSrc, setMediaSrc] = useState(
    media?.mediaUrl || URL.createObjectURL(media)
  );


  const handleOpenMenu = () => {
    setIsOpenActionMenu(true);
  };

  const handleCloseMenu = () => {
    setIsOpenActionMenu(false);
  };


  const handlePreview = () => {
    try {
      if (Array.isArray(media)) {

        const mediaUrls = media.map((file: any) => {
          const url = file.mediaUrl || URL.createObjectURL(file);
          if (!url) {
            console.error("Error generating media URL for:", file);
            throw new Error("Unable to generate media URL.");
          }
          return url;
        });

        console.log("Media URLs for preview:", mediaUrls);
        mediaUrls.forEach((url) => {
          window.open(url, "_blank", "noopener,noreferrer");
        });
      } else {

        const mediaUrl = media?.mediaUrl || URL.createObjectURL(media);
        const mediaThumbnailUrl = media?.thumbnailUrl || null;

        if (!mediaUrl) {
          console.error("Error generating media URL for:", media);
          throw new Error("Unable to generate media URL.");
        }

        console.log("Single Media URL for preview:", mediaUrl);
        if (mediaThumbnailUrl) {
          console.log("Thumbnail URL for preview:", mediaThumbnailUrl);
        } else {
          console.warn("Thumbnail URL not found. Opening the main media URL instead.");
        }


        window.open(mediaUrl, "_blank", "noopener,noreferrer");
      }
    } catch (error) {
      console.error("An error occurred while handling the media preview:");
    }
  };





  return (
    <div
      className="preview-container pointer"
      key={`${media?.name || media?.fileName}_${media?.lastModified}_${index}`}
    >
      <div className="preview-file" onClick={handleOpenMenu} ref={anchorElRef}>
        {mediaSrc ? (
          media?.type?.includes("video") ? (
            <>
              <img
                // src={media?.thumbnailUrl}
                src={media?.poster}
                alt="video thumbnail"
                // alt="preview"
                onError={() => {
                  console.log("Error loading thumbnail, fallback to default.");
                  setMediaSrc(null);
                }}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              <Box
                sx={{
                  width: 20,
                  height: 20,
                }}
                className={`btn-play pointer d-flex`}
              >
                <IconPlay />
              </Box>
            </>
          ) : (
            <img
              src={mediaSrc}
              alt="preview"
              onError={() => {
                setMediaSrc(null);
              }}
            />
          )
        ) : (
          <Box
            className="invalid-src"
            sx={{
              width: 40,
              height: 40,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <IconInvalidUrl /> */}
            <IconPlay />
            
          </Box>
        )}

        <p
          style={{
            fontSize: "12px",
            fontWeight: "normal",
            margin: "0",
          }}
        >
          {media?.name || media?.fileName}
        </p>




        {isOpenActionMenu ? (
          <KeyboardArrowUp className="arrow-icon ml-10 txt-gray-1 fs-16" />
        ) : (
          <KeyboardArrowDown className="arrow-icon ml-10 txt-gray-1 fs-16" />
        )}
      </div>
      <Menu
        sx={{ mt: "40px" }}
        anchorEl={anchorElRef.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isOpenActionMenu}
        onClose={handleCloseMenu}
        className="menu-preview-file"
      >
        <MenuItem disableRipple className="menu-item">
          <label htmlFor={`replace-file${index}`}>
            <span className="fs-16 txt-black-1 pointer">{t("chooseFile")}</span>
            <input
              type="file"
              accept={typeFile}
              className="btn-secondary"
              id={`replace-file${index}`}
              hidden
              onChange={(e) => {
                onUploadFile(e, "replace", index);
                handleCloseMenu();
              }}
            />
          </label>
        </MenuItem>

        <MenuItem disableRipple className="menu-item" onClick={handlePreview}>
          <span className="fs-16 txt-black-1 pointer">{t("preview")}</span>
        </MenuItem>
        <MenuItem
          disableRipple
          className="menu-item"
          onClick={() => deleteFile(index)}
        >
          <span className="fs-16 txt-red pointer">{t("deleteFile")}</span>
        </MenuItem>
      </Menu>
    </div>
  );
};
