import { useEffect, useState, useCallback, useRef } from "react";
import { CardUI } from "@app/pages/Features/GroupList/GroupDetail/InspectionTab/UpdateCard";
import { DataTable, DataDialog, DataPagination } from "@app/components/atoms";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  ToggleButtonGroup,
  ToggleButton,
  Avatar,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  TextField,
  Box,
  debounce,
  Switch,
} from "@mui/material";
import {
  IconPlus,
  IconActionOverflow,
  IconDeleteRow,
  IconEdit,
  IconCopy,
  IconWarningFormEdit,
  IconUser,
  IconInputError,
  IconInvalidUrl,
  IconDelete,
} from "@app/components/icons";

import {
  IconPublishedRow,
} from "@app/components/icons/IconPublishedRow/IconPublishedRow";
import {
  ConfirmDialog,
  ConfirmDialogProps,
  CreateInspectionForm,
  InspectionFormDialog,
  MediaUpload,
} from "@app/pages/Features";
import {
  REGEX,
  isMobile,
  resetIsContinuousLoading,
  scrollToTop,
  setItem,
} from "@app/helpers";
import {
  CURRENT_PAGE,
  DATE_TIME_FORMAT,
  DEBOUNCE_TIME,
  DialogTypes,
  INVALID_URL,
  LIMIT_PAGE,
  StorageKey,
  TOTAL_PAGE,
  TYPE_FILE_UPLOAD,
} from "@app/constants";
import {
  apiGroupDetailInspectionFormDelete,
  apiGroupDetailInspectionFormStatus,
  apiGroupDetailInspectionForms,
  getCustomInspectionFormTemplates,
  getInspectionItems,
  updateInspectionFormAndItemsApiCallWeb,
  updateInspectionFormStatusApiCall,

} from "@app/api";
import { format } from "date-fns";
import {
  store,
  setShowAlert,
  setCurrentInspectionInfo,
  RootState,
  setInspectionsTemplate,
  setMachineId,
  setIsEditDraft,
} from "@app/store";
import { useSelector } from "react-redux";
import {
  UploadFileType
} from "@app/constants";
import {
  handleUploadStore,
} from "@app/helpers";
import { Add } from "@mui/icons-material";
import React from "react";

enum INSPECTION_TYPE {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  ARCHIVED = "ARCHIVED",
}

type InspectionType = {
  id: number;
  currentStatus?: string;
  givenName?: string;
  inspectionFormName?: string;
  lastStatusUpdatedAt?: string;
  machineId?: string;
  machineName?: string;
  machinePictureUrl?: string;
  machineTypePictureUrl?: string;
  surname?: string;
  userPictureUrl?: string;
};

const INSPECTION_STATUS = {
  [INSPECTION_TYPE.DRAFT]: "draft",
  [INSPECTION_TYPE.PUBLISHED]: "published",
  [INSPECTION_TYPE.ARCHIVED]: "archived",
};

const dataInspectionFormStatus = [
  {
    label: "filterInspection.all",
    key: "inspectionFormCount",
    inspectionStatus: "",
    count: 0,
  },
  {
    label: "filterInspection.published",
    key: "publishedStatusCount",
    inspectionStatus: "PUBLISHED",
    count: 0,
  },
  {
    label: "filterInspection.draft",
    key: "draftStatusCount",
    inspectionStatus: "DRAFT",
    count: 0,
  },

];

type InspectionItem = {
  inspectionItemId: string;
  name: string;
  description: string;
  isRequired?: boolean;
  position?: number;
  inspectionItemMedias: {
    fileName: string;
    filePath: string;
    mediaUrl: string;
    mimeType: string;
  }[];
  status?: string;
};

export function InspectionTab() {

  const [inspectionItems, setInspectionItems] = useState<InspectionItem[]>([]);
  const [validationStates, setValidationStates] = useState<boolean[]>([]);
  const [validationStatesDesc, setValidationStatesDesc] = useState<boolean[]>([]);
  const [loadingItems, setLoadingItems] = useState<boolean>(false);
  const [openStates, setOpenStates] = useState<boolean[]>([]);
  const { t } = useTranslation();
  const [openInspectionsForm, setOpenInspectionsForm] = useState(false);
  const { id: groupId } = useParams();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [targetRow, setTargetRow] = useState<any>(null);
  const [inspectionStatus, setInspectionStatus] = useState<
    INSPECTION_TYPE | ""
  >("");
  const [open, setOpen] = useState(false);
  const [openFormDelete, setOpenFormDelete] = useState(false);
  const [openAddNewItem, setOpenAddNewItem] = useState(false);
  const [openFormArchive, setOpenFormArchive] = useState(false);
  const [openFormPublish, setOpenFormPublish] = useState(false);
  const [openFormName, setOpenFormName] = useState(false);
  const openFormAction = Boolean(anchorEl);
  const [dataSource, setDataSource] = useState<any[]>();
  const [order, setOrder] = useState<string | undefined>();
  const [field, setField] = useState<string>();
  const [requestData, setRequestData] = useState(new Date());
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogProps>({});
  const [isInit, setIsInit] = useState(true);
  const [deletedItemIds, setDeletedItemIds] = useState<string[]>([]);
  const [serviceMeterName, setServiceMeterName] = useState<string[]>([]);
  const [odoMeterName, setOdoMeterName] = useState<string[]>([]);
  const [odoMeterIsRequired, setOdoMeterIsRequired] = useState<boolean>(false);
  const [odoMeterID, setOdoMeterID] = useState<string[]>([]);
  const [removedMediaStates, setRemovedMediaStates] = useState<Record<string, string[]>>({});
  const [hiddenItems, setHiddenItems] = useState<string[]>([]);
  const [mediaStates, setMediaStates] = useState<Record<string, File[]>>({});
  const isAnyCardOpen = openStates.some((isOpen) => isOpen);
  const [itemName, setItemName] = useState("");
  const [itemDesc, setItemDesc] = useState("");
  const [isItemNameValid, setIsItemNameValid] = useState(true);
  const [isItemDescValid, setIsItemDescValid] = useState(true);
  const [isDraggingCard, setIsDraggingCard] = useState(false);
  const isUpdateDisabled = validationStates.some((isValid) => !isValid);
  const [showDragIcons, setShowDragIcons] = useState(true);

  const itemNameRefs = useRef<(HTMLInputElement | null)[]>([]);

  const [newCardIndex, setNewCardIndex] = useState<number | null>(null);

  const [pageInfo, setPageInfo] = useState({
    total: TOTAL_PAGE,
    page: CURRENT_PAGE,
    limit: LIMIT_PAGE,
  });

  const [dataInspectionTabList, setDataInspectionTabList] = useState(
    dataInspectionFormStatus
  );

  const currentInspectionInfo = useSelector(
    (state: RootState) => state?.inspection?.currentInspectionInfo
  );

  const screenPermission = useSelector(
    (state: RootState) => state?.screenPermission.value
  );

  const { isRequestedGroupDetail } = useSelector(
    (state: RootState) => state.groupDetail
  );

  const [
    allowCreateEditDeleteInspectionForm,
    setAllowCreateEditDeleteInspectionForm,
  ] = useState<boolean>(false);

  const {
    register,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const columns = [
    {
      key: "machinePictureUrl",
      title: "",
      dataIndex: "machinePictureUrl",
      forceRender: true,
      render: (_: any, record: any) => {
        return (
          <>
            {record.machinePictureUrl ? (
              <Avatar
                variant="square"
                src={record.machinePictureUrl}
                imgProps={{
                  onError: () => {
                    const newDataSource = dataSource?.map((item) => {
                      if (item.id === record.id) {
                        item.machinePictureUrl = null;
                      }
                      return item;
                    });
                    setDataSource(newDataSource);
                  },
                  sx: {
                    objectFit: "contain",
                  },
                }}
              />
            ) : (
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconInvalidUrl />
              </Box>
            )}
          </>
        );
      },
      className: "pr-4",
      tableHeadingClassName: "d-none-imp",
      width: 56,
    },
    {
      key: "machineName",
      title: "inspectionTable.customerMachineNo",
      dataIndex: "machineName",
      sorted: true,
      tableHeadingColspan: 2,
      className: "pl-0 txt-pre-wrap",
      whiteSpace: "nowrap",
      style: {
        minWidth: isMobile() ? 178 : 198,
      },
    },
    {
      key: "inspectionFormName",
      title: "inspectionTable.inspectionName",
      dataIndex: "inspectionFormName",
      sorted: true,
      style: {
        minWidth: isMobile() ? 220 : 515,
      },
      render: (value: string) => {
        return <span className="txt-pre-wrap">{value}</span>;
      },
      className: "txt-pre-wrap",
    },
    {
      key: "currentStatus",
      title: "inspectionTable.status",
      dataIndex: "currentStatus",
      style: {
        minWidth: 105,
      },
      render: (value: INSPECTION_TYPE) => {
        return (
          <div
            className={`status txt-nowrap ${value === INSPECTION_TYPE.PUBLISHED ? "active" : ""
              }`}
          >
            {t(`filterInspection.${INSPECTION_STATUS[value]}`)}
          </div>
        );
      },
    },
    {
      key: "lastStatusUpdatedAt",
      title: "inspectionTable.lastUpdate",
      dataIndex: "lastStatusUpdatedAt",
      sorted: true,
      align: "right",
      style: {
        minWidth: isMobile() ? 162 : 212,
      },
      render: (value: string) => {
        return (
          <span className="txt-nowrap">
            {format(new Date(value), DATE_TIME_FORMAT)}
          </span>
        );
      },
    },
    {
      key: "userPictureUrl",
      title: "",
      dataIndex: "userPictureUrl",
      render: (_: any, record: any) => {
        return (
          <>
            {!!record.userPictureUrl ? (
              record.userPictureUrl === INVALID_URL ? (
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconInvalidUrl />
                </Box>
              ) : (
                <Avatar
                  sx={{ width: 24, height: 24 }}
                  src={record.userPictureUrl}
                  imgProps={{
                    onError: () => {
                      const newDataSource = dataSource?.map((item) => {
                        if (item.id === record.id) {
                          item.userPictureUrl = INVALID_URL;
                        }
                        return item;
                      });
                      setDataSource(newDataSource);
                    },
                  }}
                />
              )
            ) : (
              <Box
                sx={{
                  width: 24,
                  height: 24,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconUser />
              </Box>
            )}
          </>
        );
      },
      forceRender: true,
      className: "pr-4",
      tableHeadingClassName: "d-none-imp",
      width: 40,
    },
    {
      key: "updatedBy",
      title: "inspectionTable.updatedBy",
      dataIndex: "updatedBy",
      sorted: true,
      tableHeadingColspan: 2,
      className: "pl-0 txt-pre-wrap",
      style: {
        minWidth: isMobile() ? 120 : 185,
      },
    },
    {
      key: "id",
      title: "",
      dataIndex: "id",
      render: (_: string, record: any) => (
        <Button
          className="bnt-action-form"
          onClick={(e) => handleClick(e, record)}
        >
          <IconActionOverflow />
        </Button>
      ),
      className: "add",
      width: 48,
      align: "center",
    },
  ];

  const handleChangePage = (page: number) => {
    setPageInfo({
      ...pageInfo,
      page,
    });
  };

  const handleSort = (order: string | undefined, property: string) => {
    setOrder(order);
    setField(property);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeInspectionStatus = (
    _: React.MouseEvent<HTMLElement>,
    inspectionStatus: INSPECTION_TYPE | ""
  ) => {
    setPageInfo({
      ...pageInfo,
      page: CURRENT_PAGE,
    });
    setInspectionStatus(inspectionStatus);
  };

  const handleFetchInspectionsForm = () => {
    setOpenInspectionsForm(true);
    setOpen(false);
  };

  const convertDataRow = (data: InspectionType[]) => {
    return data.map((row: any) => ({
      ...row,
      id: row?.inspectionFormId,
      updatedBy: `${row?.surname} ${row?.givenName}`,
    }));
  };

  const getGroupDetailInspectionFormStatus = (id: string) => {
    return apiGroupDetailInspectionFormStatus(id).then((res) => {

      const data = res.data.data as any;
      const newDataInspectionTabs = dataInspectionTabList.map((tab) => {
        tab.count = data[tab.key as keyof any];
        return tab;
      });

      setDataInspectionTabList(newDataInspectionTabs);
    }).catch((error) => {

    });
  };

  const getGroupDetailInspectionForms = (id: string, params = {}) => {
    return apiGroupDetailInspectionForms(id, params).then((res) => {

      const { data, meta } = res.data;
      const formatData = convertDataRow(data);

      setDataSource(formatData);
      handlePageInfo(meta, !data.length);

      if (!data.length) {

        initScrollToTop();
        resetIsContinuousLoading();
      }
    }).catch((error) => {

    });
  };

  useEffect(() => {
    if (!Object.keys(screenPermission).length) {
      return;
    }
    if (!!screenPermission?.allowCreateEditDeleteInspectionForm) {
      setAllowCreateEditDeleteInspectionForm(
        screenPermission.allowCreateEditDeleteInspectionForm
      );
    } else {
      window.location.href = `/groups/${groupId}`;
    }
  }, [screenPermission]);

  useEffect(() => {
    getGroupDetailInspectionFormStatus(`${groupId}`);
  }, [requestData]);

  useEffect(() => {
    if (isRequestedGroupDetail) {
      setItem(StorageKey.IS_CONTINUOUS_LOADING, "true");
      getGroupDetailInspectionForms(`${groupId}`, {
        limit: LIMIT_PAGE,
        page: pageInfo.page,
        ...(inspectionStatus ? { currentStatus: inspectionStatus } : {}),
        ...(field && order ? { orderBys: [{ field, order }] } : {}),
      });
    }
  }, [
    pageInfo.page,
    field,
    order,
    inspectionStatus,
    requestData,
    isRequestedGroupDetail,
  ]);

  useEffect(() => {
    return () => {
      resetIsContinuousLoading();
    };
  }, []);

  const handlePageInfo = (meta: any, hasData: boolean) => {
    if (meta.pageInfo.total) {
      if (hasData) {
        meta.pageInfo.page = meta.pageInfo.page - 1;
      }
    } else {
      setInspectionStatus("");
    }
    setPageInfo(meta.pageInfo);
  };

  const handleDeleteRow = debounce(() => {
    if (!targetRow) {
      return;
    }
    const { inspectionFormId, machineId } = targetRow;
    return apiGroupDetailInspectionFormDelete(
      `${groupId}`,
      machineId,
      inspectionFormId
    )
      .then((res: any) => {
        store.dispatch(setShowAlert(res.data.meta.successMessage));
        setRequestData(new Date());
      })
      .finally(() => {
        setOpenFormDelete(false);
        setAnchorEl(null);
      });
  }, DEBOUNCE_TIME);

  const handleUpdateStatus = debounce((status: 'ARCHIVED' | 'PUBLISHED') => {
    if (!targetRow) {
      return;
    }
    const { inspectionFormId, machineId } = targetRow;
    return updateInspectionFormStatusApiCall(
      `${groupId}`,
      machineId,
      inspectionFormId,
      status
    )
      .then((res: any) => {
        store.dispatch(setShowAlert(res.data.meta.successMessage));
        setRequestData(new Date());
      })
      .catch((error) => {
        store.dispatch(setShowAlert("Failed to update inspection form status."));
      })
      .finally(() => {
        if (status === 'ARCHIVED') {
          setOpenFormArchive(false);
        } else if (status === 'PUBLISHED') {
          setOpenFormPublish(false);
        }
        setAnchorEl(null);
      });
  }, DEBOUNCE_TIME);

  const handleEditRow = debounce(async () => {
    if (!targetRow) return;

    const { inspectionFormId, machineId } = targetRow;
    if (!groupId || !machineId || !inspectionFormId) return;

    const inspectionFormName = getValues("inspectionFormName") || "";

    const updatedItems = await Promise.all(
      inspectionItems.map(async (item) => {
        const existingMedia = (item.inspectionItemMedias || []).filter(
          (media) => !(removedMediaStates[item.inspectionItemId] || []).includes(media.filePath)
        );

        const newMedia = await handleConvertMedia(
          (mediaStates[item.inspectionItemId] || []).filter((file) => file instanceof File)
        );

        const isHidden = hiddenItems.includes(item.inspectionItemId);
        return {
          customInspectionItemId: item.inspectionItemId,
          name: item.name,
          description: item.description,
          isRequired: item.isRequired,
          position: item.position,
          medias: [...existingMedia, ...newMedia],
          removedMedias: removedMediaStates[item.inspectionItemId] || [],
          status: isHidden ? "DELETED" : item.status,
        };
      })
    );

    const deletedItemsPayload = deletedItems.map((item) => ({
      customInspectionItemId: item.inspectionItemId,
      name: item.name,
      description: item.description,
      isRequired: item.isRequired,
      position: item.position,
      medias: [],
      removedMedias: item.inspectionItemMedias.map((media) => media.filePath),
      status: "DELETED",
    }));

    const odometerItem = {
      customInspectionItemId: odoMeterID[0],
      name: odoMeterName[0],
      description: "",
      isRequired: odoMeterIsRequired,
      medias: [],
      removedMedias: [],
    };

    const deletedItemsPayloadOriginal = originalInspectionItems.map((item) => ({
      customInspectionItemId: item.inspectionItemId,
      name: item.name,
      description: item.description,
      isRequired: item.isRequired,
      position: item.position,
      medias: [],
      removedMedias: item.inspectionItemMedias.map((media) => media.filePath),
      status: "DELETED",
    }));

    const payload = {
      formName: inspectionFormName,
      items: [...updatedItems, ...deletedItemsPayload, odometerItem],

    };



    console.log("Payload for API call:", payload);

    try {
      const response = await updateInspectionFormAndItemsApiCallWeb(
        groupId,
        machineId,
        inspectionFormId,
        payload
      );

      console.log("API Response:", response);

      store.dispatch(setShowAlert(t("inspectionformupdatedsuccess")));
      setRequestData(new Date());
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setOpenFormName(false);
      setAnchorEl(null);
      setMediaStates({});
      setRemovedMediaStates({});
      setDeletedItemIds([]);
      setHiddenItems([]);
      setShowDragIcons(true);
      setDeletedItems([]);
      setInspectionItems([]);
      //setCopiedItems([]);
      setOriginalInspectionItems([]);
    }
  }, DEBOUNCE_TIME);

  const handleShowCopyInspectionForm = () => {
    if (!!groupId && !!currentInspectionInfo) {
      store.dispatch(setMachineId(currentInspectionInfo.machineId));
      getCustomInspectionFormTemplates(
        groupId,
        currentInspectionInfo.machineId,
        currentInspectionInfo.inspectionFormId
      ).then((db) => {
        store.dispatch(setInspectionsTemplate(db));
        setOpenInspectionsForm(true);
      });
    }
  };

  const initScrollToTop = () => {
    if (isInit) {
      scrollToTop();
      setIsInit(false);
    }
  };

  const handleClick = async (event: React.MouseEvent<HTMLElement>, target: any) => {
    setAnchorEl(event.currentTarget);

    setTargetRow({
      ...target,
      groupId,
    });

    store.dispatch(setCurrentInspectionInfo(target));

    if (groupId && target.machineId && target.inspectionFormId) {
      try {
        await fetchInspectionItems(
          groupId,
          target.machineId,
          target.inspectionFormId,
          setLoadingItems,
          setInspectionItems,
          setMediaStates,
        );

      } catch (error) {

      }
    }
  };
  const [originalInspectionItems, setOriginalInspectionItems] = React.useState<InspectionItem[]>([]);
  useEffect(() => {
    console.log("Original Items:", originalInspectionItems);
    console.log("Modified Items:", inspectionItems);
  }, [inspectionItems]);
  const fetchInspectionItems = async (
    groupId: string,
    machineId: string,
    customInspectionFormId: string,
    setLoadingItems: React.Dispatch<React.SetStateAction<boolean>>,
    setInspectionItems: React.Dispatch<React.SetStateAction<InspectionItem[]>>,
    setMediaStates: React.Dispatch<React.SetStateAction<Record<string, File[]>>>,

  ) => {
    try {
      setLoadingItems(true);

      const items = await getInspectionItems(groupId, machineId, customInspectionFormId);
      const filteredItemss = items.filter((item: { itemCode: string }) =>
        item.itemCode === 'SERVICE_METER' || item.itemCode === 'ODOMETER'
      );

      console.log("Filtered Items:", filteredItemss);

      const serviceMeterName = filteredItemss
        .filter((item: { itemCode: string; }) => item.itemCode === 'SERVICE_METER')
        .map((item: { name: any; }) => item.name);

      console.log("SERVICE_METER Name:", serviceMeterName);

      const odoMeterName = filteredItemss
        .filter((item: { itemCode: string; }) => item.itemCode === 'ODOMETER')
        .map((item: { name: any; }) => item.name);

      console.log("ODOMETER Name:", odoMeterName);

      const odometerID = filteredItemss
        .filter((item: { itemCode: string; }) => item.itemCode === 'ODOMETER')
        .map((item: { id: any; }) => item.id);

      console.log("ODOMETER ID:", odometerID);

      const odoMeterIsRequiredValue =
        filteredItemss.find((item: { itemCode: string; }) => item.itemCode === "ODOMETER")?.isRequired || false;


      const itemsToProcess = items.slice(0, -2);

      const filteredItems = itemsToProcess.map(
        (item: {
          isRequired: any; id: any; name: any; description: any; position: any; inspectionItemMedias: any
        }) => ({
          inspectionItemId: item.id,
          name: item.name,
          description: item.description,
          isRequired: item.isRequired,
          position: item.position,
          inspectionItemMedias: item.inspectionItemMedias || [],
        })
      );

      setOriginalInspectionItems(filteredItems);

      const newMediaStates = filteredItems.reduce((acc: Record<string, File[]>, item: { inspectionItemId: string | number; inspectionItemMedias: never[]; }) => {
        acc[item.inspectionItemId] = item.inspectionItemMedias || [];
        return acc;
      }, {});


      console.log(odoMeterIsRequired)


      setOdoMeterIsRequired(odoMeterIsRequiredValue);
      setServiceMeterName(serviceMeterName);
      setOdoMeterName(odoMeterName);
      setOdoMeterID(odometerID);

      setInspectionItems(filteredItems);
      setMediaStates(newMediaStates);


    } catch (error) {

      setInspectionItems([]);

      setServiceMeterName([]);
      setOdoMeterName([]);
      setOdoMeterIsRequired(false);


    } finally {
      setLoadingItems(false);
    }
  };

  // const handleUpdateItemName = useCallback((index: number, newName: string) => {
  //   setInspectionItems((prevItems) => {
  //     const updatedItems = [...prevItems];
  //     updatedItems[index] = { ...updatedItems[index], name: newName };


  //     setValidationStates((prevStates) => {
  //       const updatedStates = [...prevStates];
  //       updatedStates[index] = newName.trim() !== "";
  //       return updatedStates;
  //     });

  //     return updatedItems;
  //   });
  // }, []);
 
  // const handleUpdateItemName = useCallback(
  //   (index: number, newName: string) => {
  //     setInspectionItems((prevItems) => {
  //       const updatedItems = [...prevItems];

       
  //       const originalItem = originalInspectionItems[index];
  //       const isNameChanged = originalItem?.name !== newName;

  //       if (isNameChanged && originalItem) {
         
  //         setDeletedItems((prevDeleted) => {
  //           const alreadyDeleted = prevDeleted.some(
  //             (item) => item.inspectionItemId === originalItem.inspectionItemId
  //           );
  //           if (alreadyDeleted) return prevDeleted;

  //           return [
  //             ...prevDeleted,
  //             {
  //               ...originalItem,
  //               status: "DELETED",
  //             },
  //           ];
  //         });

          
  //         updatedItems[index] = {
  //           ...updatedItems[index],
  //           name: newName,
  //           status: "CREATED",
  //         };
  //       } else if (!isNameChanged) {
          
  //         const { status, ...itemWithoutStatus } = updatedItems[index];
  //         updatedItems[index] = {
  //           ...itemWithoutStatus,
  //           name: newName,
  //         };

         
  //         setDeletedItems((prevDeleted) =>
  //           prevDeleted.filter(
  //             (item) => item.inspectionItemId !== originalItem?.inspectionItemId
  //           )
  //         );
  //       }

        
  //       setValidationStates((prevStates) => {
  //         const updatedStates = [...prevStates];
  //         updatedStates[index] = newName.trim() !== "";
  //         return updatedStates;
  //       });

  //       return updatedItems;
  //     });
  //   },
  //   [originalInspectionItems]
  // );
  // const handleUpdateItemName = useCallback(
  //   (index: number, newName: string) => {
  //     setInspectionItems((prevItems) => {
  //       const updatedItems = [...prevItems];
  
       
  //       const originalItem = originalInspectionItems[index];
  //       const isNameChanged = originalItem?.name !== newName;
  
  //       if (isNameChanged && originalItem) {
         
  //         setDeletedItems((prevDeleted) => {
          
  //           const alreadyDeleted = prevDeleted.some(
  //             (item) => item.inspectionItemId === originalItem.inspectionItemId
  //           );
  //           if (alreadyDeleted) return prevDeleted;
  
  //           return [
  //             ...prevDeleted,
  //             {
  //               ...originalItem,
  //               status: "DELETED",
  //             },
  //           ];
  //         });
  //       }
  
      
  //       updatedItems[index] = {
  //         ...updatedItems[index],
  //         name: newName,
  //         status: "CREATED", 
  //       };
  
       
  //       setValidationStates((prevStates) => {
  //         const updatedStates = [...prevStates];
  //         updatedStates[index] = newName.trim() !== "";
  //         return updatedStates;
  //       });
  
  //       return updatedItems;
  //     });
  //   },
  //   [originalInspectionItems]
  // );

  const handleUpdateItemName = useCallback(
    (index: number, newName: string) => {
      setInspectionItems((prevItems) => {
        const updatedItems = [...prevItems];
  
        // Check if the item exists in the originalInspectionItems (pre-existing items)
        const originalItem = originalInspectionItems[index];
  
        if (originalItem) {
          // Check if the name has actually changed
          const isNameChanged = originalItem.name !== newName;
  
          if (isNameChanged) {
            // Add the original item to the deletedItems list with status: "DELETED"
            setDeletedItems((prevDeleted) => {
              const alreadyDeleted = prevDeleted.some(
                (item) => item.inspectionItemId === originalItem.inspectionItemId
              );
              if (alreadyDeleted) return prevDeleted;
  
              return [
                ...prevDeleted,
                {
                  ...originalItem,
                  status: "DELETED",
                },
              ];
            });
  
            // Mark the modified item with status: "CREATED"
            updatedItems[index] = {
              ...updatedItems[index],
              name: newName,
              status: "CREATED",
            };
          } else {
            // If the name is reverted to the original value, remove "CREATED" status and delete it from `deletedItems`
            const { status, ...itemWithoutStatus } = updatedItems[index];
            updatedItems[index] = {
              ...itemWithoutStatus,
              name: newName,
            };
  
            setDeletedItems((prevDeleted) =>
              prevDeleted.filter(
                (item) => item.inspectionItemId !== originalItem.inspectionItemId
              )
            );
          }
        } else {
          // For newly added items, simply update the name without affecting the status
          updatedItems[index] = {
            ...updatedItems[index],
            name: newName,
          };
        }
  
        // Update validation state for the name field
        setValidationStates((prevStates) => {
          const updatedStates = [...prevStates];
          updatedStates[index] = newName.trim() !== "";
          return updatedStates;
        });
  
        return updatedItems;
      });
    },
    [originalInspectionItems]
  );
  

  const handleUpdateItemDescription = useCallback(
    (index: number, newDescription: string) => {
      setInspectionItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems[index] = { ...updatedItems[index], description: newDescription };
        return updatedItems;
      });
    },
    []
  );

  const handleClickConfirm = () => {
    setOpenDialogConfirm(true);
    setConfirmDialog({
      classBtnSubmit: "btn-primary",
      typeDialog: DialogTypes.CONFIRM,
      title: "titleConfirm",
      content: "",
      btnSubmitText: "ok",
      onCancel() {
        setOpenDialogConfirm(false);
      },
      onSubmit() {
        setOpenDialogConfirm(false);
        reset({ inspectionFormName: "" });
        setOpenFormName(false);
        setMediaStates({});
        setRemovedMediaStates({});
        setDeletedItemIds([]);
        setHiddenItems([]);
        setValidationStatesDesc([]);
        setShowDragIcons(true);
        setDeletedItems([]);
        //setCopiedItems([]);
        setOriginalInspectionItems([]);

      },
    });
  };

  const handleMediaUpload = (id: string, files: File[]) => {
    const existingFiles = mediaStates[id]?.map((file: any) => file.filePath) || [];
    const removedFiles = existingFiles.filter(
      (filePath: string) => !files.find((file: any) => file.filePath === filePath)
    );

    if (removedFiles.length > 0) {
      setRemovedMediaStates((prev) => ({
        ...prev,
        [id]: [...(prev[id] || []), ...removedFiles],
      }));
    }

    setMediaStates((prevState) => ({
      ...prevState,
      [id]: files,
    }));


  };

  useEffect(() => {
  }, [removedMediaStates]);

  const handleConvertMedia = async (files: File[]) => {
    const mediaArray = [];
    for await (const file of files) {
      if (file instanceof File) {
        const uploadedMedia = await handleUploadStore(UploadFileType.MACHINE_INSPECTIONS, file);
        if (uploadedMedia) {
          mediaArray.push({
            fileName: uploadedMedia.name || file.name,
            filePath: uploadedMedia.filePath,
            mediaUrl: uploadedMedia.filePath,
            mimeType: uploadedMedia.type || file.type,
          });
        }
      }
    }
    return mediaArray.filter((media) => media.fileName);
  };

  useEffect(() => {
    setValidationStates(inspectionItems.map((item) => item.name.trim() !== ""));
  }, [inspectionItems]);

  useEffect(() => {

  }, [validationStates, inspectionItems]);

  const handleToggleCard = (index: number) => {
    console.log(`Toggling card at index: ${index}`);
    setOpenStates((prevStates) => {
      const updatedStates = [...prevStates];
      updatedStates[index] = !updatedStates[index];
      console.log("Updated openStates after toggle:", updatedStates);
      return updatedStates;
    });
  };

  useEffect(() => {

    setOpenStates(Array(inspectionItems.length).fill(false));
  }, [inspectionItems]);

  const swapItems = (index1: number, index2: number) => {
    setInspectionItems((prevItems) => {
      const updatedItems = [...prevItems];
      [updatedItems[index1], updatedItems[index2]] = [updatedItems[index2], updatedItems[index1]];
      return updatedItems;
    });
  };

  const uploadDescription = [
    `${t("textDesUploadFile.pleaseUploadAFileShowingTheInspectionPoints")}`,
    `${t("textDesUploadFile.fileFormatsThatCanBeUploadedAre")}`,
  ];

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    if (dragIndex === hoverIndex) return;

    setInspectionItems((prevItems) => {
      const updatedItems = [...prevItems];
      const [draggedItem] = updatedItems.splice(dragIndex, 1);
      updatedItems.splice(hoverIndex, 0, draggedItem);


      const reorderedItems = updatedItems.map((item, index) => ({
        ...item,
        position: index,
      }));

      console.log(
        "Updated Items with Positions:",
        reorderedItems.map((item) => ({ id: item.inspectionItemId, position: item.position }))
      );

      return reorderedItems;
    });
  };

  useEffect(() => {

    setValidationStatesDesc(
      inspectionItems.map((item) =>
        item.description.trim() === "" ||
        (REGEX.commonNameFieldCharacterMuiltiLineRegex.test(item.description) &&
          REGEX.emojiRegex.test(item.description))
      )
    );
  }, [inspectionItems]);

  useEffect(() => {
    console.log("Hidden Items:", hiddenItems);
  }, [hiddenItems]);

  useEffect(() => {
    console.log("Items:", inspectionItems);
  }, [inspectionItems]);
  const [deletedItems, setDeletedItems] = useState<InspectionItem[]>([]);

  const handleDeleteCard = (id: string) => {
    setInspectionItems((prevItems) => {

      const itemToDelete = prevItems.find((item) => item.inspectionItemId === id);

      if (itemToDelete && itemToDelete.status !== "CREATED") {

        setDeletedItems((prevDeletedItems) => [...prevDeletedItems, itemToDelete]);
      }


      return prevItems.filter((item) => item.inspectionItemId !== id);
    });


    store.dispatch(setShowAlert(t("alreadyDeleted")));

    console.log(`Item with ID ${id} has been moved to the deleted list`);
  };
  console.log(deletedItems)

  const handleItemNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;


    if (
      REGEX.commonNameFieldRegex.test(value) &&
      REGEX.emojiRegex.test(value)
    ) {
      setItemName(value);
      setIsItemNameValid(value.trim() !== "");
    } else {
      setIsItemNameValid(false);
    }
  };

  const handleItemDescChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;


    if (
      value.trim() === "" ||
      (REGEX.commonNameFieldCharacterMuiltiLineRegex.test(value) &&
        REGEX.emojiRegex.test(value))
    ) {
      setItemDesc(value);
      setIsItemDescValid(true);
    } else {
      setIsItemDescValid(false);
    }
  };

  const handleAddClickItem = () => {
    if (!itemName.trim()) {
      setIsItemNameValid(false);
      return;
    }
    if (targetRow && groupId && targetRow.machineId && targetRow.inspectionFormId) {
      console.log("Group ID:", groupId);
      console.log("Machine ID:", targetRow.machineId);
      console.log("Form ID:", targetRow.inspectionFormId);
    } else {
      console.error("Missing IDs. Ensure groupId, machineId, and inspectionFormId are available.");
    }


    setOpenAddNewItem(false);
    setOpenFormName(false);
    setItemName("");
    setItemDesc("");
    setIsItemNameValid(true);
    setIsItemDescValid(true);
    setOpenAddNewItem(false);
  };

  const handleValidationChange = (isValid: boolean, index: number) => {
    setValidationStates((prevStates) => {
      const updatedStates = [...prevStates];
      updatedStates[index] = isValid;
      return updatedStates;
    });
  };

  const handleValidationChangeDesc = (isValid: boolean, index: number) => {
    setValidationStatesDesc((prevStates) => {
      const updatedStates = [...prevStates];
      updatedStates[index] = isValid;
      return updatedStates;
    });
  };

  useEffect(() => {
    if (newCardIndex !== null) {

      setTimeout(() => setNewCardIndex(null), 0);
    }
  }, [newCardIndex]);








  return (
    <div className="inspection-tab">
      <div
        className={`flex group-button ${dataInspectionTabList[0].count
          ? "justify-content-between"
          : "justify-content-end"
          }`}
        style={{ marginTop: "24px" }}
      >
        <div className="toggle-btn-group">
          {!!dataInspectionTabList[0].count && (
            <ToggleButtonGroup
              className="filter-button"
              value={inspectionStatus}
              exclusive
            >
              {dataInspectionTabList.map((tab, index) => (
                <ToggleButton
                  key={index}
                  disabled={!tab.count}
                  value={tab.inspectionStatus}
                  className="flex-centered filter-tab"
                  onClick={handleChangeInspectionStatus}
                >
                  {typeof tab.label === "string" ? t(tab.label) : tab.label}
                  <span style={{ marginLeft: "8px" }}>({tab.count})</span>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        </div>

        {allowCreateEditDeleteInspectionForm && (
          <Button
            variant="contained"
            disableElevation
            className="btn-add-inspect"
            onClick={() => setOpen(true)}
          >
            <IconPlus />
            <span style={{ marginLeft: "8px" }}>{t("addInspection")}</span>
          </Button>
        )}

      </div>
      <div className="inspection-table-container">
        <DataTable
          dataSource={dataSource}
          columns={columns}
          textEmptyData="noInspectionForms"
          onRequestSort={handleSort}
          didScrollTop={() => {
            initScrollToTop();
            resetIsContinuousLoading();
          }}
        />
        <DataPagination
          isHidden={!dataSource?.length}
          className="mt-25 sp-p24"
          currentPage={pageInfo.page}
          limitPage={pageInfo.limit}
          totalPage={pageInfo.total}
          handleChangePage={handleChangePage}
        />
      </div>
      <CreateInspectionForm
        open={open}
        setOpen={setOpen}
        clickSubmit={async () => handleFetchInspectionsForm()}
      />
      <InspectionFormDialog
        open={openInspectionsForm}
        setOpen={setOpenInspectionsForm}
        onRequestData={() => setRequestData(new Date())}
      />
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={openFormAction}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {targetRow?.currentStatus === INSPECTION_TYPE.PUBLISHED && (
          <MenuItem
            onClick={() => {
              if (targetRow) {
                const { inspectionFormName } = targetRow;
                setValue("inspectionFormName", inspectionFormName);
              }
              setOpenFormName(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <IconEdit />
            </ListItemIcon>
            <ListItemText>
              {t("formAction.editSheet")}
            </ListItemText>
          </MenuItem>
        )}

        {targetRow?.currentStatus === INSPECTION_TYPE.DRAFT && (
          <MenuItem
            onClick={() => {
              if (targetRow) {
                const { inspectionFormName } = targetRow;
                setValue("inspectionFormName", inspectionFormName);
              }
              store.dispatch(setIsEditDraft(true));
              setOpenInspectionsForm(true);
              handleShowCopyInspectionForm();
              handleClose();
            }}
          >
            <ListItemIcon>
              <IconEdit />
            </ListItemIcon>
            <ListItemText>
              {t("formAction.edit")}
            </ListItemText>
          </MenuItem>
        )}

        {targetRow?.currentStatus === INSPECTION_TYPE.PUBLISHED && (
          <MenuItem
            onClick={() => {
              handleShowCopyInspectionForm();
              handleClose();
            }}
          >
            <ListItemIcon>
              <IconCopy />
            </ListItemIcon>
            <ListItemText>{t("formAction.copy")}</ListItemText>
          </MenuItem>
        )}

        {targetRow?.currentStatus === INSPECTION_TYPE.DRAFT && (
          <MenuItem
            onClick={() => {
              setOpenFormDelete(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <IconDeleteRow />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body2" className="txt-red fs-16">
                  {t("formAction.delete")}
                </Typography>
              }
            />
          </MenuItem>
        )}

        {targetRow?.currentStatus === INSPECTION_TYPE.PUBLISHED && (

          <MenuItem
            onClick={() => {
              setOpenFormArchive(true);
              handleClose();
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 'auto',
                marginRight: '-5px',
                marginLeft: '5px',
                '& svg': {
                  fontSize: '2.5rem',
                },
              }}
            >
              <IconDelete />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body2" className="txt-red fs-16">
                  {t('formAction.delete')}
                </Typography>
              }
            />
          </MenuItem>
        )}

        {targetRow?.currentStatus === INSPECTION_TYPE.ARCHIVED && (
          <MenuItem
            onClick={() => {
              setOpenFormPublish(true);
              handleClose();
            }}
          >
            <ListItemIcon>

              <IconPublishedRow />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body2" className="txt-green fs-16">
                  {t("formAction.publish")}
                </Typography>
              }
            />
          </MenuItem>
        )}


      </Menu>

      <DataDialog
        title={t("formDelete.title")}
        open={openFormDelete}
        setOpen={setOpenFormDelete}
      >
        <div className="form-confirm-delete">
          <p className="txt-gray-1 fs-16">{t("formDelete.content")}</p>
          <div className="button-form-delete flex justify-content-end">
            <Button
              onClick={() => setOpenFormDelete(false)}
              className="btn-cancel"
            >
              {t("formDelete.cancel")}
            </Button>

            <Button className="btn-delete" onClick={handleDeleteRow}>
              {t("formDelete.delete")}
            </Button>
          </div>
        </div>
      </DataDialog>

      <DataDialog
        title={t("formArchive.title")}
        open={openFormArchive}
        setOpen={setOpenFormArchive}
      >
        <div className="form-confirm-delete">
          <p className="txt-gray-1 fs-16">{t("formArchive.content")}</p>
          <div className="button-form-delete flex justify-content-end">
            <Button
              onClick={() => setOpenFormArchive(false)}
              className="btn-cancel"
            >
              {t("formArchive.cancel")}
            </Button>



            <Button
              className="btn-delete"
              onClick={() => handleUpdateStatus('ARCHIVED')}
            >
              {t("formAction.delete")}
            </Button>
          </div>
        </div>
      </DataDialog>

      <DataDialog
        title={t("formPublish.title")}
        open={openFormPublish}
        setOpen={setOpenFormPublish}
      >
        <div className="form-confirm-delete">
          <p className="txt-gray-1 fs-16">{t("formPublish.content")}</p>
          <div className="button-form-delete flex justify-content-end">
            <Button
              onClick={() => setOpenFormPublish(false)}
              className="btn-cancel"
            >
              {t("formPublish.cancel")}
            </Button>


            <Button
              className="btn btn-primary btn-save ml-10"
              onClick={() => handleUpdateStatus('PUBLISHED')}
            >
              {t("formPublish.publish")}
            </Button>
          </div>
        </div>
      </DataDialog>

      <DataDialog
        open={openFormName}
        setOpen={setOpenFormName}
        title={t("formEdit.title")}
        className="dialog-inspection-form"

      >
        <div className="form-edit-name">


          <div className="wrapper-text-field mt-8">
            <TextField
              id={
                errors?.inspectionFormName?.type
                  ? "outlined-error"
                  : "outlined-controlled"
              }
              {...register("inspectionFormName", {
                validate: {
                  hasEmoji: (value) => REGEX.emojiRegex.test(value),
                  hasInvalidChar: (value) =>
                    REGEX.commonNameFieldRegex.test(value),
                  required: (value) => !!value.trim(),
                },
                onBlur: (e) =>
                  setValue("inspectionFormName", e.target.value?.trim()),
              })}
              className="input-field"
              error={
                !!errors.inspectionFormName?.type &&
                errors?.inspectionFormName?.type !== "maxLength"
              }
              label={`${t("formEdit.checkListName")}*`}
              inputProps={{ maxLength: 50 }}
            />
            {errors?.inspectionFormName?.type && <IconInputError />}
          </div>


          {errors?.inspectionFormName?.type && (
            <p className="txt-red fs-12 mt-0 mb-0 ml-15">
              {errors?.inspectionFormName?.type === "required" &&
                t("messages.require")}
              {errors?.inspectionFormName?.type === "hasInvalidChar" &&
                t("messages.validCharacters")}
              {errors?.inspectionFormName?.type === "hasEmoji" &&
                t("messages.validCharacters")}
            </p>
          )}

          <div className="inspection-items mt-16" style={{ marginBottom: "0px" }}>

            {loadingItems ? (
              <p></p>
            ) : inspectionItems.length > 0 ? (

              <ul className="inspection-items-list">
                {inspectionItems
                  .filter((item) => !hiddenItems.includes(item.inspectionItemId))
                  .map((item, index) => (
                    <CardUI
                      key={item.inspectionItemId}

                      id={item.inspectionItemId}
                      index={index}
                      moveCard={moveCard}
                      inspectionItemName={item.name}

                      inspectionItemNameErrors={null}
                      inspectionItemDesErrors={null}
                      prevCardName={item.name}
                      prevCardDescription={item.description}
                      errors={errors}
                      onUpdateItemName={(newName) => handleUpdateItemName(index, newName)}
                      onUpdateItemDescription={(newDescription) =>
                        handleUpdateItemDescription(index, newDescription)
                      }
                      mediaFiles={mediaStates[item.inspectionItemId] || []}
                      onMediaUpload={(files) =>
                        handleMediaUpload(item.inspectionItemId, files)
                      }
                      inspectionItemId={item.inspectionItemId}
                      setMediaStates={setMediaStates}
                      removedMediaStates={setRemovedMediaStates}
                      isOpen={openStates[index]}
                      onToggle={() => handleToggleCard(index)}
                      onDeleteCard={handleDeleteCard}
                      onValidationChange={handleValidationChange}
                      onValidationChangeDesc={handleValidationChangeDesc}
                      showDragIcon={showDragIcons}
                      focusOnName={newCardIndex === index}
                    />
                  ))}
              </ul>
            ) : (
              <p>{t("noItemsFound")}</p>
            )}

          </div>

          <div className="flex-centered mt-24">
            <Button
              style={{
                backgroundColor: "#eaf4ff",
                border: "none",
                borderRadius: "20px",
                padding: "10px 20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                color: "black",
                fontSize: "14px",
                fontWeight: "500",
                cursor: "pointer",
                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                transition: "background-color 0.3s ease",
                textTransform: "none",
              }}

              onClick={() => {

                const generateUniqueInspectionItemId = (existingIds: { has: (arg0: string) => any; }) => {
                  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                  let id;
                  do {
                    id = "";
                    for (let i = 0; i < 26; i++) {
                      id += characters.charAt(Math.floor(Math.random() * characters.length));
                    }
                  } while (existingIds.has(id));
                  return id;
                };


                const existingIds = new Set(inspectionItems.map((item) => item.inspectionItemId));

                const newCard = {
                  inspectionItemId: generateUniqueInspectionItemId(existingIds),
                  name: "",
                  description: "",
                  isRequired: true,
                  position: inspectionItems.length,
                  status: "CREATED",
                  inspectionItemMedias: [],
                };

                setOpenStates((prevStates) => {
                  const updatedStates = [...prevStates, true];
                  console.log("Updated openStates in setOpenStates:", updatedStates);
                  return updatedStates;
                });
                setInspectionItems([...inspectionItems, newCard]);
                setValidationStates((prevStates) => [...prevStates, false]);
                setValidationStatesDesc((prevStates) => [...prevStates, true]);

                setOpenStates((prevStates) => [...prevStates, true]);


                setNewCardIndex(inspectionItems.length);


              }}

            >
              <Add style={{ fontSize: "16px" }} />
              {t("addInspectionItem")}
            </Button>
          </div>

          <div className="flex-centered mt-24" style={{ marginBottom: "0px" }}>

          </div>


          <ul className="cards bg-gray" style={{ borderRadius: "0" }}>
            <li className="card-item">
              <div
                className={`card-item-title flex-centered justify-content-between "have-switch"`}
                style={{ paddingBottom: "8px", marginBottom: "8px" }}
              >
                <span
                  className="txt-card txt-gray-2"
                  style={{ fontSize: "16px", color: "#6B7280" }}
                >

                  {serviceMeterName.length > 0
                    ? `${serviceMeterName.join(", ")}*`
                    : t("smr")}

                </span>
              </div>
            </li>
            <li className="card-item">
              <div
                className={`card-item-title flex-centered justify-content-between "have-switch"`}
              >
                <span
                  className="txt-card"
                  style={{ fontSize: "16px", color: "#374151" }}
                >

                  {odoMeterName.length > 0
                    ? `${odoMeterName.join(", ")}${odoMeterIsRequired ? "*" : ""}`
                    : t("odo")}

                </span>
                <div className="d-flex align-items-center">
                  <span
                    className="fs-14 txt-gray-3 mr-15"
                    style={{ color: "#6B7280" }}
                  >
                    {t("required")}
                  </span>
                  <Switch
                    className={`btn-switch ${odoMeterIsRequired ? "checked" : ""}`}
                    checked={odoMeterIsRequired}
                    onChange={(event) => setOdoMeterIsRequired(event.target.checked)}
                  />
                </div>
              </div>
            </li>
          </ul>

          <div className="flex justify-content-end btn-group-footer mt-8"
            style={{
              position: "sticky",
              bottom: 0,
              backgroundColor: "#fff",
              paddingTop: "16px",

              zIndex: 10,
              marginRight: "-10px"
            }}>
            <Button onClick={handleClickConfirm} className="btn btn-no-border"
            >
              {t("cancel")}

            </Button>

            <Button

              disabled={

                !!errors.inspectionFormName?.type ||
                validationStates.some((isValid) => !isValid) ||
                inspectionItems.some((item) => !item.name.trim()) ||
                isUpdateDisabled ||
                validationStatesDesc.some((isValid) => !isValid)

              }
              className="btn btn-primary btn-save ml-10"
              onClick={handleEditRow}
            >
              {t("formAction.update")}
            </Button>

          </div>
        </div>
      </DataDialog>

      <DataDialog
        title={t("formDelete.titleNew")}
        open={openAddNewItem}
        setOpen={setOpenAddNewItem}
        groupId={groupId}
        machineId={targetRow?.machineId}
        formId={targetRow?.inspectionFormId}
      >
        <div className="form-confirm-delete"
        >

          <div className="card-item-content d-block">
            <div className="wrapper-text-field mt-8" style={{ position: "relative" }}>
              <TextField
                label={`${t("itemName")}*`}
                className="input-field"
                inputProps={{ maxLength: 100 }}
                value={itemName}
                onChange={handleItemNameChange}
                error={!isItemNameValid}

              />
              {!isItemNameValid && (
                <div
                  style={{
                    position: "absolute",
                    right: "5px",
                    top: "40%",
                    transform: "translateY(-50%)",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconInputError />
                </div>
              )}
              <span className="fs-12 ml-15 txt-gray-1">&#42;{t("required")}</span>
            </div>

            <div
              className="wrapper-text-field mt-8"

            >

              <TextField
                multiline
                maxRows={3}
                className="input-field input-text-area"

                label={t("explanation")}
                inputProps={{ maxLength: 150 }}
                value={itemDesc}
                onChange={handleItemDescChange}


              />

            </div>


            <div className="mt-16">

              <MediaUpload

                setMedias={(files: File[]) => {

                }}

                typeFile={TYPE_FILE_UPLOAD.image}
                uploadDescription={uploadDescription}
              />

            </div>


          </div>

          <div className="button-form-delete flex justify-content-end">
            <Button
              onClick={() => {
                setItemName("");
                setItemDesc("");
                setIsItemNameValid(true);
                setIsItemDescValid(true);
                setOpenAddNewItem(false);

              }}
              className="btn-cancel"
            >
              {t("formDelete.cancel")}
            </Button>

            <Button


              className="btn btn-primary btn-save ml-10"
              disabled={!itemName.trim()}
              onClick={handleAddClickItem}
            >
              {t("formAction.add")}
            </Button>
          </div>
        </div>
      </DataDialog>

      <ConfirmDialog
        open={openDialogConfirm}
        setOpen={setOpenDialogConfirm}
        {...confirmDialog}
      />
    </div>
  );
}
