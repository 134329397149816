export enum ReportType {
  MACHINE = "MACHINE",
  INSPECTION = "INSPECTION",
}

export enum ResponseStatus {
  UNADDRESSED = "UNADDRESSED",
  RESOLVED = "RESOLVED",
  DELETED = "DELETED",
}

export enum ReportActions {
  SELF_REPAIR = "SELF_REPAIR",
  REPAIR_REQUEST = "REPAIR_REQUEST",
}

export enum UploadFileType {
  USER = "user",
  MACHINE_INSPECTIONS = "machine-inspections",
  MACHINE_REPORT = "machine-reports",
  MACHINE = "machines"
}

export enum ReportTypeDialog {
  MACHINE_OPERATION_REPORTS = "MACHINE_OPERATION_REPORTS",
  MAINTENANCE_REPORTS = "MAINTENANCE_REPORTS",
  INCIDENT_REPORTS = "INCIDENT_REPORTS",
  INSPECTION = "INSPECTION",
}

export const MACHINE_OPERATION_REPORTS = "MACHINE_OPERATION_REPORTS";
export const FUEL_MAINTENANCE_REPORTS = "FUEL_MAINTENANCE_REPORTS";
export const INCIDENT_REPORTS = "INCIDENT_REPORTS";
export const INSPECTION = "INSPECTION";
export const MAINTENANCE_REPORTS = "MAINTENANCE_REPORTS";
export const STATUS_UPDATES = "STATUS_UPDATES";
