import {
  ArrowForwardIos,
  ArrowBackIos,
  PictureAsPdfOutlined,
} from "@mui/icons-material";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Divider,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Button, DataTooltip } from "@app/components/atoms";
import { useTranslation } from "react-i18next";
import { IconBox, IconDelete, IconDeleteGray, IconEdit } from "@app/components/icons";
import { addMonths, format, formatISO, getDaysInMonth, getMonth, getYear } from "date-fns";
import {
  getInspectionFormsWeb,
  getInspections,
  getInspectionPDF,
  getInspectionsPDF,
  getUser,
  getInspectionItemsFormStatus,
  getMachineReports,
  getMachineMaintenancePDF,
  getCustomInspectionFormTemplatess
} from "@app/api";
import { useParams } from "react-router-dom";
import { CustomInspectionFormsType, Machine } from "@app/types";
import {
  DATE_TIME_FORMAT_1,
  DATE_TIME_FORMAT_YEAR_AND_MONTH,
  DATE_TIME_FORMAT_YEAR_AND_MONTH_1,
  ItemInspectionEnum,
  MIN_DATE_CREATE_INSPECTION,
  ResponseStatus,
} from "@app/constants";
import { MachineOpen, TableInspectionFormTab } from "@app/pages/Features";
import { useSelector } from "react-redux";
import { RootState, setShowAlert, store } from "@app/store";
import {
  formatTime,
  isMobileDevice,
  scrollToTop,
  resetIsContinuousLoading,
  isSafari,
  isMobile,
  getCurrentUtc,
} from "@app/helpers";
// import {
//   DatePickerInput,
// } from "@app/components/atoms";

import {
  DatePickerInput,
} from "@app/pages/Features/MachineDetail/Components/InspectionHistory/DatePickerInputUpdate";

import { getCustomInspectionFormTemplates, apiPostInspectionForms, apiCreateInspection } from "@app/api";
import { AxiosError } from "axios";
import { useOutletContext } from "react-router-dom";
import DescriptionIcon from '@mui/icons-material/Description';
import React from "react";
//import { Machine } from "@app/types";

// type MachineOpenProps = {
//   dbMachineOpen?: Machine[];
//   machineReportIdCard?: (id: string) => void;
// };

export function InspectionHistory({
  // machineReportIdCard,
}) {

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");


  const [selectedTime, setSelectedTime] = useState(() => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedTime = `${formattedHours}:${formattedMinutes} ${period}`;


    return formattedTime;
  });

  const [selectedItem, setSelectedItem] = useState("");
  const [itemsList, setItemsList] = useState<string[]>([]);
  const { t } = useTranslation();
  const { groupId, machineId, customInspectionFormId } = useParams();
  const [dateInspection, setDateInspection] = useState(new Date());
  const [dbTemplateList, setDbTemplateList] =
    useState<CustomInspectionFormsType[]>();

  const [inspectionFormName, setInspectionFormName] = useState<string>();

  const [displayData, setDisplayData] = useState([]);
  const { isRequestedMachineInfo } = useSelector(
    (state: RootState) => state.inspection
  );
  const [isRequestInspections, setIsRequestInspections] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const [inspectionFormNameStatus, setInspectionFormNameStatus] =
    useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setInspectionFormName(event.target.value);
  };

  const handleChangeMonth = (value: number) => {
    setDateInspection(addMonths(dateInspection, value));
  };
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteRow, setShowDeleteRow] = useState(false);


  const handleOpenPopup = () => {
    const now = new Date();


    const formattedDate = now.toLocaleDateString("en-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });


    const formattedTime = now.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });


    setSelectedDate(formattedDate);
    setSelectedTime(formattedTime);
    setIsPopupOpen(true);
  };


  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedDate("");
    setSelectedTime("");
    setSelectedItem("");
  };


  const handleAddEntry = () => {
    if (!selectedDate || !selectedTime) {
      alert(t("Please provide a valid date and time"));
      return;
    }

    const entry = {
      inspectionAt: `${selectedDate}T${selectedTime}`,
    };


    handleClosePopup();
  };



  const handleChangeStartDate = (newValue: Date) => {
    const formattedDate = new Intl.DateTimeFormat("en-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(newValue);
    setSelectedDate(formattedDate);
  };



  const { refreshMachineData } = useOutletContext<{ refreshMachineData: () => void }>();


  // const getFormattedDateTime = () => {
  //   if (!selectedDate || !selectedTime) return "";
  //   const combinedDate = new Date(`${selectedDate}T${selectedTime}`);
  //   const formattedDate = format(combinedDate, "yyyy-MM-dd HH:mm:ss.SSSSSSS");
  //   const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //   return `${formattedDate}`;
  // };

  // const getFormattedDateTime = () => {
  //   if (!selectedDate || !selectedTime) return "";

  //   // Combine date and time
  //   const combinedDate = new Date(`${selectedDate}T${selectedTime}`);

  //   // Convert to ISO format with timezone
  //   const formattedDateWithTimeZone = formatISO(combinedDate, { representation: 'complete' });

  //   return formattedDateWithTimeZone; // This includes the timezone offset
  // };

  const getFormattedDateTime = () => {
    if (!selectedDate || !selectedTime) return ""; // Ensure both date and time are provided

    // Extract year and month from dateInspection
    const year = getYear(dateInspection); // Extract year
    const month = getMonth(dateInspection); // Extract month (0-based index)

    // Extract day from the selectedDate
    const day = new Date(selectedDate).getDate(); // Extract day from the selected date

    // Combine year, month, and day
    const combinedDate = new Date(year, month, day);

    // Combine with the selected time
    const timeString = selectedTime; // Example: "HH:mm:ss"
    const combinedDateTime = new Date(`${formatISO(combinedDate, { representation: "date" })}T${timeString}`);

    // Format the combined date and time with timezone
    const formattedDateWithTimeZone = formatISO(combinedDateTime, { representation: "complete" });

    return formattedDateWithTimeZone; // Return the complete ISO 8601 date-time string
  };



  // const getFormattedDateTime = () => {
  //   if (!selectedDate || !selectedTime) return "";


  //   const isoString = `${selectedDate}T${selectedTime}`;
  //   const combinedDate = new Date(isoString);


  //   const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //   const zonedDate = toZonedTime(combinedDate, timeZone);


  //   const formattedDate = format(zonedDate, "yyyy-MM-dd HH:mm:ss.SSSSSSS");

  //   return formattedDate;
  // };


  const handleEditForm = () => {
    setIsEditing(true);
    setShowDeleteRow(true);

  };



  const handleSaveForm = () => {
    setIsEditing(false);
    setShowDeleteRow(false);
    refreshMachineData();
    refreshInspectionData();
  };

  const handleOutputPdf = () => {
    !!groupId &&
      !!machineId &&
      !!inspectionFormName &&
      getInspectionsPDF(groupId, machineId, {
        monthYear: dateInspection,
        customInspectionFormId: inspectionFormName,
        utc: getCurrentUtc(),
      });
  };



  const [inspectionId, setInspectionId] = useState<string | null>(null);

  useEffect(() => {
    if (inspectionId) {
      console.log(`handleOutputIDPdf called with Inspection ID: ${inspectionId}`);
      handleOutputIDPdf();
    }
  }, [inspectionId]);



  const handleOutputIDPdf = () => {
    if (!!groupId && !!machineId && !!inspectionFormName && !!inspectionId) {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's time zone
      console.log(`User Time Zone: ${userTimeZone}`);

      getInspectionPDF(groupId, machineId, inspectionId, userTimeZone); // Pass time zone
    } else {
      console.error("Missing required parameters: groupId, machineId, inspectionFormName, or inspectionId");
    }
  };

  // const handleOutputMaintenanceReportPdf = () => {
  //   if (groupId && machineId) {
  //     const inspectionId = "01JGDNHVA63W5SPESBMWSYCP12"; // Example inspectionId
  //     getMachineMaintenancePDF(groupId, machineId, inspectionId);
  //   } else {
  //     console.error("Missing required parameters: groupId or machineId");
  //   }
  // };





  const handleTouchStart = (event: any) => {
    const { target } = event;
    const selectPaperProps = document.querySelector(".select-options-js");
    if (!selectPaperProps?.contains(target)) {
      setInspectionFormNameStatus(false);
    }
  };

  const initScrollToTop = () => {
    if (isInit) {
      scrollToTop();
      setIsInit(false);
    }
  };

  useEffect(() => {
    !!isRequestedMachineInfo &&
      !!groupId &&
      !!machineId &&
      getInspectionFormsWeb(groupId, machineId)
        .then((db) => {
          setDbTemplateList(db);
          setInspectionFormName(db[0]?.customInspectionFormId);
          if (!db.length) {
            initScrollToTop();
          }
        })
        .finally(() => {
          setIsRequestInspections(true);
        });
  }, [isRequestedMachineInfo]);




  useEffect(() => {
    if (
      !!isRequestInspections &&
      !!groupId &&
      !!machineId &&
      !!inspectionFormName
    ) {
      getInspections(groupId, machineId, {
        monthYear: format(
          new Date(dateInspection),
          DATE_TIME_FORMAT_YEAR_AND_MONTH_1
        ),
        utc: getCurrentUtc(),
        customInspectionFormId: inspectionFormName,
      })
        .then((db) => {
          let result = [
            ...(db?.customInspectionItems || {}),
            ...[
              {
                name: t("reporter"),
                inspectionItemName: ItemInspectionEnum.STAFF_NAME,
              },
              {
                name: t("machineLocation"),
                inspectionItemName: ItemInspectionEnum.LOCATION,
              },
            ],
          ].map((item: any) => {
            let ob = { ...item } as any;
            Array.from({ length: getDaysInMonth(dateInspection) })?.forEach(
              (_day, i) => {
                ob[
                  format(
                    new Date(
                      getYear(dateInspection),
                      getMonth(dateInspection),
                      i + 1
                    ),
                    DATE_TIME_FORMAT_1
                  )
                ] = [];
              }
            );
            return ob;
          });
          db?.customInspections?.forEach((item: any) => {
            result.forEach((element: any) => {
              element?.[
                formatTime(item?.inspectionAt, DATE_TIME_FORMAT_1)
              ]?.push(item);
            });
          });
          setDisplayData(result as any);
        })
        .finally(() => {
          resetIsContinuousLoading();
        });
    } else {
      resetIsContinuousLoading();
    }
  }, [
    dateInspection,
    groupId,
    inspectionFormName,
    machineId,
    isRequestInspections,
  ]);

  useEffect(() => {
    if (isMobileDevice && isSafari) {
      document.addEventListener("touchstart", handleTouchStart);
      return () => {
        document.removeEventListener("touchstart", handleTouchStart);
      };
    }
  }, []);


  const [valueStartDate, setValueStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate());
    return date;
  });



  const [clickedInspectionIds, setClickedInspectionIds] = useState<string[]>([]);

  const handleHeaderCellClick = (inspectionIds: string[]) => {
    setClickedInspectionIds(inspectionIds);

  };


  const fetchAndLogInspectionItems = async () => {
    if (!groupId || !machineId || !inspectionFormName) {
      console.warn("Missing required parameters: groupId, machineId, or inspectionFormName");
      return;
    }

    try {
      const response = await getCustomInspectionFormTemplates(groupId, machineId, inspectionFormName);

    } catch (error) {
      console.error("Error fetching inspection items: ", error);
    }
  };


  const fetchTransformAndPostInspectionItems = async () => {
    if (!groupId || !machineId || !inspectionFormName || !selectedDate || !selectedTime) {
      alert("Please provide all required inputs.");
      return;
    }


    try {

      const response = await getCustomInspectionFormTemplatess(groupId, machineId, inspectionFormName);


      const { inspectionFormId, type, inspectionItems } = response;

      if (!inspectionItems || !Array.isArray(inspectionItems)) {
        console.error("Inspection items are invalid:", inspectionItems);
        alert("Invalid inspection form data.");
        return;
      }


      const transformedItems = inspectionItems.map((item: {
        resultType: string;
        inspectionItemId: string;
        itemCode: string | null;
        isRequired: boolean;
      }) => ({
        inspectionItemId: item.inspectionItemId,
        resultType: item.resultType,
        itemCode: item.itemCode || null,
        isRequired: item.isRequired,
        isImmutable: false,
        isForcedRequired: false,
        result: item.resultType === "OK_OR_ANOMARY" ? "OK" :
          item.resultType === "NUMERIC" ? "0" :
            "UNKNOWN",
      }));


      const payload = {
        inspectionFormId,
        type: type || "CUSTOM",
        name: "Inspection Form Test",
        currentStatus: "POSTED",
        inspectionAt: getFormattedDateTime(),
        inspectionItems: transformedItems,
      };




      const postResponse = await apiCreateInspection(groupId, machineId, payload);
      store.dispatch(setShowAlert(postResponse.data.meta.successMessage || "Inspection submitted successfully."));
      setIsPopupOpen(false);
      refreshInspectionData();

    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        console.error("Error during submission:", error.message);
        if (error.response?.data) {
          console.error("Backend Error Response:", error.response.data);
          alert(`Backend Error: ${error.response.data.error?.message || "Submission failed"}`);
        }
      } else if (error instanceof Error) {
        console.error("Unexpected Error:", error.message);
        alert("An unexpected error occurred.");
      } else {
        console.error("An unknown error occurred:", error);
        alert("Failed to submit inspection.");
      }
    }
  };

  const refreshInspectionData = () => {
    if (!groupId || !machineId || !inspectionFormName) return;

    getInspections(groupId, machineId, {
      monthYear: format(new Date(dateInspection), DATE_TIME_FORMAT_YEAR_AND_MONTH_1),
      utc: getCurrentUtc(),
      customInspectionFormId: inspectionFormName,
    })
      .then((db) => {
        let result = [
          ...(db?.customInspectionItems || {}),
          ...[
            {
              name: t("reporter"),
              inspectionItemName: ItemInspectionEnum.STAFF_NAME,
            },
            {
              name: t("machineLocation"),
              inspectionItemName: ItemInspectionEnum.LOCATION,
            },
          ],
        ].map((item: any) => {
          let ob = { ...item } as any;
          Array.from({ length: getDaysInMonth(dateInspection) })?.forEach((_day, i) => {
            ob[
              format(
                new Date(getYear(dateInspection), getMonth(dateInspection), i + 1),
                DATE_TIME_FORMAT_1
              )
            ] = [];
          });
          return ob;
        });
        db?.customInspections?.forEach((item: any) => {
          result.forEach((element: any) => {
            element?.[
              formatTime(item?.inspectionAt, DATE_TIME_FORMAT_1)
            ]?.push(item);
          });
        });
        setDisplayData(result as any);
      })
      .finally(() => {
        resetIsContinuousLoading();
      });
  };

  const [refreshKey, setRefreshKey] = useState(0);

  const handleTableRefresh = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const handleApiClick = async () => {
    try {
      const response = await getUser();
      const isoLocaleCode = response?.data?.data?.isoLocaleCode;
      console.log("Extracted isoLocaleCode:", isoLocaleCode);
    } catch (error) {
      console.error("Failed to fetch user data:", error);

    }
  };


  const [isButtonVisible, setIsButtonVisible] = useState(false);

  const fetchInspectionItems = async (
    groupId: string,
    machineId: string,
    customInspectionFormId: string
  ) => {
    try {
      const response = await getInspectionItemsFormStatus(groupId, machineId, customInspectionFormId);

      if (response?.data) {
        console.log("Current Status:", response.data.currentStatus);


        const currentStatus = response.data.currentStatus;
        if (currentStatus === "PUBLISHED") {
          setIsButtonVisible(true);

          setIsEditing(false);
          setShowDeleteRow(false);
        } else if (currentStatus === "ARCHIVED") {
          setIsButtonVisible(false);

          setIsEditing(false);
          setShowDeleteRow(false);
        }
      } else {
        setIsButtonVisible(false);

        setIsEditing(false);
        setShowDeleteRow(false);
      }
    } catch (error) {
      console.error("Error fetching inspection items:", error);
      setIsButtonVisible(false);

      setIsEditing(false);
      setShowDeleteRow(false);
    }
  };

  useEffect(() => {
    if (groupId && machineId && inspectionFormName) {
      fetchInspectionItems(groupId, machineId, inspectionFormName);
    }
  }, [groupId, machineId, inspectionFormName]);

  useEffect(() => {
    if (dbTemplateList?.length) {
      // Find the first item without an icon (not archived)
      const firstNonArchivedItem = dbTemplateList.find(
        (item) => item.currentStatus !== 'ARCHIVED'
      );
  
      // Set the inspectionFormName to the first non-archived item's ID, or fallback to the first item
      setInspectionFormName(firstNonArchivedItem?.customInspectionFormId || dbTemplateList[0].customInspectionFormId);
    }
  }, [dbTemplateList]);
  
  // const handleClick = (id: string) => {
  //   if (machineReportIdCard) {
  //     machineReportIdCard("01JJ3HK2R755SJVYYKEP1H08KK");
  //   } else {
  //     console.warn("machineReportIdCard is not defined");
  //   }
  // };
  const [machineReportsOpen, setMachineReportsOpen] = useState<Machine[]>();
  const [isRequestedReports, setIsRequestedReports] = useState(false);
  const convertDataRow = (data: Machine[]) => {
    return data.map((row: any) => ({
      ...row,
      id: row?.machineReportId,
      reporterName: `${row?.surname} ${row?.givenName}`,
    }));
  };
   const getMachineInfoPage = (groupId: string, machineId: string) => {
      getMachineReports(groupId, machineId, {
        responseStatus: ResponseStatus.UNADDRESSED,
      })
        .then((db: { data: Machine[]; meta: any }) => {
          setMachineReportsOpen(convertDataRow(db?.data));
        })
        .finally(() => {
          setIsRequestedReports(true);
        });
    };
    
  return (
    <>
{/* <MachineOpen
                    dbMachineOpen={[]}
                    machineReportIdCard={(id) => ("01JJ3HK2R755SJVYYKEP1H08KK")}
                  /> */}
{/* <Button
      onClick={() =><MachineOpen
        dbMachineOpen={machineReportsOpen}
        machineReportIdCard={() => ("01JJ3HK2R755SJVYYKEP1H08KK")}

      />}
    >
      Machine Open
    </Button> */}

      <Dialog
        open={isPopupOpen}
        onClose={(event, reason) => {
          if (reason === "backdropClick" || reason === "escapeKeyDown") {

            return;
          }
          handleClosePopup();
        }}
      >
        <DialogTitle>{t("addInspections")}</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", gap: "1rem", alignItems: "center", marginTop: "1rem" }}>
            <div >
              {/* <DatePickerInput
                label={t("report.labelStartDate")}
                value={valueStartDate}
                onChange={(newValue) => handleChangeStartDate(newValue)}
                onAccept={(newValue) => handleChangeStartDate(newValue)}
                views={["year", "month", "day"]} // Restrict to year, month, and day views
                minDate={new Date(2025, 0, 1)} // Minimum date: January 1, 2025
                maxDate={new Date(2025, 11, 31)} // Maximum date: December 31, 2025
                disableFuture // Disable future dates

              /> */}
              <DatePickerInput
                label={t("report.labelStartDate")}
                value={new Date(getYear(dateInspection), getMonth(dateInspection), Math.min(new Date().getDate(), getDaysInMonth(dateInspection)))} // Set to the year and month from dateInspection
                onChange={(newValue) => handleChangeStartDate(newValue)}
                onAccept={(newValue) => handleChangeStartDate(newValue)}
                views={["year", "month", "day"]} // Restrict to year, month, and day views
                minDate={new Date(getYear(dateInspection), getMonth(dateInspection), 1)} // Minimum date set to the year and month of dateInspection
                maxDate={new Date(getYear(dateInspection), getMonth(dateInspection) + 1, 0)} // Maximum date: Last day of the same month
                disableFuture // Disable future dates
              />
            </div>

            <div style={{ flex: 1, marginTop: "-7px" }}>
              <TextField
                label={t("time")}
                type="time"
                value={selectedTime}
                onChange={(e) => setSelectedTime(e.target.value)}
                InputLabelProps={{ shrink: true }}
                margin="normal"
                style={{ width: "100%" }}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginTop: "1`rem",
            }}
          >
            <strong>{t("")}</strong>
            {/* <p>{getFormattedDateTime()}</p> */}
          </div>

        </DialogContent>

        <DialogActions>
          <Button className="btn-white disabled" onClick={handleClosePopup}>
            {t("cancel")}
          </Button>
          <Button
            className="btn-primary"

            onClick={fetchTransformAndPostInspectionItems}
            disabled={!valueStartDate || !selectedTime}
          >
            {t("formAction.add")}
          </Button>

        </DialogActions>


      </Dialog>



      {dbTemplateList &&
        (!!dbTemplateList?.length ? (
          <div className="inspection-form-tab">
            <div className="wrap-action-inspection flex-centered justify-content-between sp-p24 mb-24 mt-24">
              <div className="wrap-date flex-centered justify-content-start">
                <h3 className="m-0 fs-24 txt-black-1 fw-700">
                  {format(dateInspection, DATE_TIME_FORMAT_YEAR_AND_MONTH)}
                </h3>
                <div>

                  <Button
                    className="btn-no-border btn-action-icon ml-20"
                    disabled={
                      format(
                        dateInspection,
                        DATE_TIME_FORMAT_YEAR_AND_MONTH
                      ) === MIN_DATE_CREATE_INSPECTION
                    }
                    onClick={() => handleChangeMonth(-1)}
                  >
                    <ArrowBackIos className="pointer fs-12" />
                  </Button>
                  <Button
                    className="btn-no-border btn-action-icon"
                    disabled={
                      format(
                        dateInspection,
                        DATE_TIME_FORMAT_YEAR_AND_MONTH
                      ) === format(new Date(), DATE_TIME_FORMAT_YEAR_AND_MONTH)
                    }
                    onClick={() => handleChangeMonth(1)}
                  >
                    <ArrowForwardIos className="pointer fs-12" />
                  </Button>
                </div>
                {/* <FormControl className="select-field select-inspection ml-16">
                  <Select
                    open={inspectionFormNameStatus}
                    onOpen={() => setInspectionFormNameStatus(true)}
                    onClose={() => setInspectionFormNameStatus(false)}
                    defaultValue=""
                    id="grouped-select"
                    value={inspectionFormName}
                    onChange={handleChange}
                    renderValue={(selected) => {
                     
                      const selectedItem = dbTemplateList.find(
                        (item: any) => item.customInspectionFormId === selected
                      );
                      return selectedItem ? selectedItem.name : '';
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 216,
                          width: isMobile() ? window.innerWidth - 48 : 342,
                        },
                        className: `select-options-js ${inspectionFormNameStatus ? 'd-block' : 'd-none-imp'
                          }`,
                      },
                    }}
                  >
                    {!!dbTemplateList &&
                      dbTemplateList?.map((option: any) => (
                        <MenuItem
                          key={option?.customInspectionFormId}
                          value={option?.customInspectionFormId}
                        >
                          <DataTooltip isTextPre width="100%">
                            {option.name}
                          </DataTooltip>
                          <div style={{ marginLeft: 'auto' }}>
                            <DescriptionIcon /> 
                          </div>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl> */}

{/* <FormControl className="select-field select-inspection ml-16">
  <Select
    open={inspectionFormNameStatus}
    onOpen={() => setInspectionFormNameStatus(true)}
    onClose={() => setInspectionFormNameStatus(false)}
    defaultValue=""
    id="grouped-select"
    value={inspectionFormName}
    onChange={handleChange}
    renderValue={(selected) => {
      const selectedItem = dbTemplateList.find(
        (item: any) => item.customInspectionFormId === selected
      );
      return selectedItem ? selectedItem.name : '';
    }}
    MenuProps={{
      PaperProps: {
        sx: {
          maxHeight: 216,
          width: isMobile() ? window.innerWidth - 48 : 342,
        },
        className: `select-options-js ${
          inspectionFormNameStatus ? 'd-block' : 'd-none-imp'
        }`,
      },
    }}
  >
    {!!dbTemplateList &&
      // Sort the list: published first, archived later
      dbTemplateList
        .sort((a, b) => {
          if (a.currentStatus === 'ARCHIVED' && b.currentStatus !== 'ARCHIVED') {
            return 1; // Move archived to the bottom
          }
          if (a.currentStatus !== 'ARCHIVED' && b.currentStatus === 'ARCHIVED') {
            return -1; // Keep published at the top
          }
          return 0; // Maintain relative order for same status
        })
        .map((option: any) => {
          const isArchived = option.currentStatus === 'ARCHIVED';

          return (
            <MenuItem
              key={option?.customInspectionFormId}
              value={option?.customInspectionFormId}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px 16px',
              }}
            >
              {isArchived && (
                <div style={{ marginRight: '10px', marginTop: '5px' }}>
                  <IconDeleteGray />
                </div>
              )}
              <DataTooltip isTextPre width="100%">
                {option.name}
              </DataTooltip>
            </MenuItem>
            
            
          );
        })}
  </Select>
</FormControl> */}
<FormControl className="select-field select-inspection ml-16">
  <Select
    open={inspectionFormNameStatus}
    onOpen={() => setInspectionFormNameStatus(true)}
    onClose={() => setInspectionFormNameStatus(false)}
    id="grouped-select"
    value={inspectionFormName || ''} // Use the state for controlled selection
    onChange={handleChange}
    renderValue={(selected) => {
      const selectedItem = dbTemplateList.find(
        (item) => item.customInspectionFormId === selected
      );
      return selectedItem ? selectedItem.name : '';
    }}
    MenuProps={{
      PaperProps: {
        sx: {
          maxHeight: 216,
          width: isMobile() ? window.innerWidth - 48 : 342,
        },
        className: `select-options-js ${
          inspectionFormNameStatus ? 'd-block' : 'd-none-imp'
        }`,
      },
    }}
  >
    {!!dbTemplateList &&
      dbTemplateList
        .sort((a, b) => {
          if (a.currentStatus === 'ARCHIVED' && b.currentStatus !== 'ARCHIVED') {
            return 1; // Move archived to the bottom
          }
          if (a.currentStatus !== 'ARCHIVED' && b.currentStatus === 'ARCHIVED') {
            return -1; // Keep published at the top
          }
          return 0; // Maintain relative order for same status
        })
        .map((option: any) => {
          const isArchived = option.currentStatus === 'ARCHIVED';

          return (
            <MenuItem
              key={option?.customInspectionFormId}
              value={option?.customInspectionFormId}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px 16px',
              }}
            >
              {isArchived && (
                <div style={{ marginRight: '10px', marginTop: '5px' }}>
                  <IconDeleteGray />
                </div>
              )}
              <DataTooltip isTextPre width="100%">{option.name}</DataTooltip>
            </MenuItem>
          );
        })}
  </Select>
</FormControl>



              </div>


              <div className="wrap-btns flex-centered justify-content-start">

                {/* {!isEditing && (
                  <Button
                    className="btn-primary"
                    onClick={handleOutputMaintenanceReportPdf}
                  >
                    <PictureAsPdfOutlined className="icon-pdf fs-18" />
                    {t("pdfOutput")}
                  </Button>

                )} */}


                {isEditing && (
                  <div style={{ marginRight: "10px" }}>
                    <Button
                      className="btn-primary"
                      onClick={handleOpenPopup}
                    >
                      {t("formAction.add")}
                    </Button>
                  </div>
                )}

                {isEditing && (
                  <div style={{ marginRight: "10px" }}>
                    <Button
                      className="btn-primary"
                      onClick={handleSaveForm}
                    >

                      {t("formAction.save")}
                    </Button>
                  </div>
                )}




                <div style={{ marginRight: "10px" }}>
                  {isButtonVisible && (
                    <Button
                      className={isEditing ? "btn-white disabled" : "btn-primary"}
                      onClick={() => {
                        if (!isEditing) {
                          handleEditForm();
                        }
                      }}
                      disabled={isEditing}
                    >
                      <IconEdit
                        className="icon-edit"
                        fillColor={isEditing ? "#d3d3d3" : "white"}
                      />
                      {t("formAction.edit")}
                    </Button>
                  )}
                </div>





                {!isEditing && (
                  <Button
                    className="btn-primary"
                    onClick={handleOutputPdf}
                  >
                    <PictureAsPdfOutlined className="icon-pdf fs-18" />
                    {t("pdfOutput")}
                  </Button>

                )}



              </div>

            </div>
            <TableInspectionFormTab
              key={refreshKey}
              displayData={displayData}
              currentDate={dateInspection}
              didScrollTop={initScrollToTop}
              showDeleteRow={showDeleteRow}
              isEditing={isEditing}
              onHeaderCellClick={handleHeaderCellClick}
              setDisplayData={setDisplayData}
              refreshInspectionData={refreshInspectionData}
              forceTableRefresh={refreshKey}
              onDownloadPdf={(inspectionId) => {

                setInspectionId(null);
                setTimeout(() => setInspectionId(inspectionId), 0);

              }}
             
            />
          </div>
        ) : (
          <div className="empty-table bg-white mt-24">
            <div className="text-center">
              <IconBox />
              <p className="text-content">{t("emptyInspectionData")}</p>
            </div>
          </div>
        ))}
    </>
  );
};
