import React, { FC, memo, useState, useCallback, useEffect, useRef } from "react";
import { KeyboardArrowDown, KeyboardArrowUp, ArrowUpward, ArrowDownward, DragIndicator } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IconDelete, IconInputError } from "@app/components/icons";
import { DialogTypes, TYPE_FILE_UPLOAD } from "@app/constants";
import { ConfirmDialog, MediaUpload } from "@app/pages/Features";
import { REGEX } from "@app/helpers";
import { useDrag, useDrop } from "react-dnd";
const ITEM_TYPE = "CARD";

interface DragItem {
  inspectionItemId: number;
  index: number;
}

interface CardUIProps {

  id: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;

  inspectionItemName: string;
  inspectionItemNameErrors: any;
  inspectionItemDesErrors: any;
  prevCardName: string;
  prevCardDescription: string;
  errors: any;
  onUpdateItemName: (name: string) => void;
  onUpdateItemDescription: (description: string) => void;
  mediaFiles: File[];
  onMediaUpload: (files: File[]) => void;
  inspectionItemId: string;
  setMediaStates: React.Dispatch<React.SetStateAction<Record<string, File[]>>>;
  removedMediaStates: React.Dispatch<React.SetStateAction<Record<string, string[]>>>;
  isOpen: any;
  onToggle: any;
  onDeleteCard: (id: string) => void;
  onValidationChange: (isValid: boolean, index: number) => void; // New prop
  onValidationChangeDesc: (isValid: boolean, index: number) => void; // New prop
  showDragIcon: Boolean
  focusOnName: boolean;
}

export const CardUI: FC<CardUIProps> = memo(function CardUI({
  inspectionItemName,
  inspectionItemNameErrors,
  inspectionItemDesErrors,
  prevCardName,
  prevCardDescription,
  onUpdateItemName,
  onUpdateItemDescription,
  mediaFiles,
  onMediaUpload,
  inspectionItemId,
  removedMediaStates,
  onDeleteCard, 
  onValidationChange,
  index,
  moveCard,
  onValidationChangeDesc,
  showDragIcon = true,
  isOpen: initialIsOpen,
  onToggle,
  focusOnName,
}) {

  useEffect(() => {
    setFileCount(mediaFiles.length);
    setLocalMediaFiles([...mediaFiles]);
  }, [mediaFiles]);

  useEffect(() => {
    setLocalMediaFiles([...mediaFiles]);
  }, [mediaFiles]);

  useEffect(() => {
    if (mediaFiles && mediaFiles.length > 0) {
      setLocalMediaFiles(mediaFiles);
    }
  }, [mediaFiles]);

  const { t } = useTranslation();
  //const [isOpen, setIsOpen] = useState(false);
  const [localName, setLocalName] = useState(inspectionItemName);
  const [nameError, setNameError] = useState<string | null>(null);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = React.useState(false);
  const [descriptionError, setDescriptionError] = useState<string | null>(null);
  const [localMediaFiles, setLocalMediaFiles] = useState<File[]>(mediaFiles || []);
  //const [showDragIcon, setShowDragIcon] = useState(true);

  // const toggleCard = useCallback(() => {
  //   setIsOpen((prev) => !prev);
  // }, []);

  const toggleCard = useCallback(() => {
    setIsOpen((prev: any) => !prev);
    onToggle(); // Inform parent about toggle state
  }, [onToggle]);
  

  const [isOpen, setIsOpen] = useState(initialIsOpen);
  useEffect(() => {
    //console.log(`Card with ID: ${inspectionItemId} is now ${isOpen ? "open" : "closed"}`);
  }, [isOpen, inspectionItemId]);

  // useEffect(() => {
  //   // Focus the name input when the card is opened
  //   if (isOpen && nameInputRef.current) {
  //     nameInputRef.current.focus();
  //   }
  // }, [isOpen]);

  useEffect(() => {
    if (focusOnName && isOpen && nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, [focusOnName, isOpen]);


  const handleNameChange = (value: string) => {
    setLocalName(value);

    const isNonEmpty = value.trim() !== "";
    const isValid = isNonEmpty && REGEX.commonNameFieldRegex.test(value) && REGEX.emojiRegex.test(value);

    if (isValid) {
      setNameError(null);
      onUpdateItemName(value);
      onValidationChange(true, index);
    } else if (!isNonEmpty) {
      setNameError(t("Pleaseenteratleast1character"));
      onValidationChange(false, index);
    } else if (!REGEX.commonNameFieldRegex.test(value)) {
      setNameError(t("invalidCharacters"));
      onValidationChange(false, index);
    } else if (!REGEX.emojiRegex.test(value)) {
      setNameError(t("emojisarenotallowed"));
      onValidationChange(false, index);
    }
  };

  const handleNameBlur = (e: { target: { blur: () => void } }) => {
    const trimmedName = localName.trim();

    if (trimmedName === "") {
      setNameError(t("Pleaseenteratleast1character"));
      onUpdateItemName("");
      onValidationChange(false, index);
      e.target.blur();
    } else if (!REGEX.commonNameFieldRegex.test(trimmedName)) {
      setNameError(t("invalidCharacters"));
      onUpdateItemName("");
      onValidationChange(false, index);
      e.target.blur();
    } else if (!REGEX.emojiRegex.test(trimmedName)) {
      setNameError(t("emojisarenotallowed"));
      onUpdateItemName("");
      onValidationChange(false, index);
      e.target.blur();
    } else {
      setNameError(null);
      onUpdateItemName(trimmedName);
      onValidationChange(true, index);
    }
  };

  const handleMediaUpload = (files: File[]) => {
    const existingFiles = localMediaFiles.map((file: any) => file.filePath);
    const newFiles = files.map((file: any) => file.filePath);

    const removedFiles = existingFiles.filter((filePath: string) => !newFiles.includes(filePath));


    if (removedFiles.length > 0) {
      removedMediaStates((prevState) => ({
        ...prevState,
        [inspectionItemId]: [...(prevState[inspectionItemId] || []), ...removedFiles],
      }));
    }

    setLocalMediaFiles(files);
    onMediaUpload(files);
  };



  const uploadDescription = [
    `${t("textDesUploadFile.pleaseUploadAFileShowingTheInspectionPoints")}`,
    `${t("textDesUploadFile.fileFormatsThatCanBeUploadedAre")}`,
  ];

  const [refreshKey, setRefreshKey] = useState(0);
  const [fileCount, setFileCount] = useState(0);
  const refreshMediaUpload = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };


  const styles = {
    cardItem: {
      marginBottom: "0px",
      paddingTop: "16px",
      paddingBottom: "10px",
      borderBottom: "1.5px solid #eee",
      paddingLeft: "16px",
      paddingRight: "16px",


    },
    cardTitle: {
      marginBottom: "8px",
    },
    textFieldWrapper: {
      marginTop: "0px",
    },
    arrowIcon: {
      color: "#ccc",
      fontSize: "24px",
    },
    text: {
      fontSize: "16px",
    },
    buttonGroup: {
      display: "flex",
      flexDirection: "column" as "column",
      alignItems: "center",
      gap: "8px",
    },
    button: {
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      padding: "4px",
    },
    disabledButton: {
      opacity: 0.5,
      cursor: "not-allowed",
    },
    cardContainer: {
      borderRadius: "0px",
      padding: "16px",
      backgroundColor: "#f5f6f9",
      maxWidth: "100%",
      overflow: "hidden",
      borderBottom: "1px solid #eeeeee",
    },
  };



  const handleDescriptionChange = (value: string) => {

    const isValid =
      value.trim() === "" ||
      (REGEX.commonNameFieldCharacterMuiltiLineRegex.test(value) &&
        REGEX.emojiRegex.test(value));

    if (!isValid) {
      setDescriptionError(t("invalidCharacters"));
      onValidationChangeDesc(false, index);
    } else {
      setDescriptionError(null);
      onValidationChangeDesc(true, index);
    }
    //console.log("Validation State Desc:", setDescriptionError);

    onUpdateItemDescription(value);
  };

  const handleDescriptionBlur = (e: { target: { blur: () => void } }) => {
    const trimmedDescription = prevCardDescription.trim();

    const isValid =
      trimmedDescription === "" ||
      (REGEX.commonNameFieldCharacterMuiltiLineRegex.test(trimmedDescription) &&
        REGEX.emojiRegex.test(trimmedDescription));

    if (!isValid) {
      setDescriptionError(t("invalidCharacters"));
      onValidationChangeDesc(false, index);
    } else {
      setDescriptionError(null);
      onValidationChangeDesc(true, index);
    }

    e.target.blur();
  };

  const ref = useRef<HTMLDivElement>(null);
  const dragIconRef = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: string | symbol | null }>({
    accept: ITEM_TYPE,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover(item: DragItem, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ITEM_TYPE,
    item: { inspectionItemId, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    
  });


  drag(dragIconRef);
  drop(ref);


  const confirmProps = {
    typeDialog: DialogTypes.ERROR,
    title: "titleDeleteCard",
    content: "desDeleteCard",
    btnSubmitText: "delete",
    onCancel() {
      setIsOpenDeleteDialog(false);
    },
    onSubmit() {
      onDeleteCard(inspectionItemId);
      //setShowDragIcon(false);
      setIsOpenDeleteDialog(false);
      // setNameError(null)

    },
    classBtnSubmit: "btn-primary bg-red",
  };
  const nameInputRef = useRef<HTMLInputElement>(null);

  return (
    <div
      ref={ref}
      className="card-item card-item-draggable bg-gray"
      style={{ ...styles.cardContainer, opacity: isDragging ? 0.5 : 1 }}
      data-handler-id={handlerId || undefined}

    >
      <div
        className="card-item-title flex"
        style={{ justifyContent: "space-between", alignItems: "center" }}
        onClick={toggleCard}
      >
        <div className="flex align-items-center" style={{ gap: "15px" }}>
          {/* <div
            ref={dragIconRef}
            className="wrapper-drag-icon flex align-items-center"
            style={{ cursor: "pointer" }}
          >
            <DragIndicator className="drag-icon" style={{ color: "#adaaaf" }} />
          </div> */}
          {showDragIcon && ( // Conditionally render drag icon
            <div
              ref={dragIconRef}
              className="wrapper-drag-icon flex align-items-center"
              style={{ cursor: "pointer" }}
            >
              <DragIndicator className="drag-icon" style={{ color: "#adaaaf" }} />
            </div>
          )}
          <span
            className="txt-card txt-pre-wrap word-break"
            style={styles.text}
          >
            {!inspectionItemNameErrors?.type ? inspectionItemName : prevCardName}
          </span>
        </div>

        <button

          style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            padding: 0,
            color: "#cdcdcd",
          }}
        >
          {isOpen ? (
            <KeyboardArrowUp className="arrow-icon ml-10" />
          ) : (
            <KeyboardArrowDown className="arrow-icon ml-10" />
          )}
        </button>
      </div>



      <div
        className="card-item-content"
        style={{
          maxHeight: isOpen ? "500px" : "0px",
          opacity: isOpen ? 1 : 0,
          overflow: "hidden",
          transition: "max-height 0.3s ease, opacity 0.3s ease",
        }}
      >
        {isOpen && (
          <div className="card-item-content d-block">
            <div
              className="wrapper-text-field mt-8"
              style={styles.textFieldWrapper}
            >
              <TextField
                id={
                  inspectionItemNameErrors || nameError
                    ? "outlined-error"
                    : "outlined-required"
                }
                
                label={`${t("itemName")}*`}
                className="input-field"
                inputProps={{ maxLength: 100 }}
                error={!!inspectionItemNameErrors || !!nameError}

                value={localName}
                onChange={(e) => handleNameChange(e.target.value)}

                onBlur={(e) => handleNameBlur(e)}
                InputProps={{
                  endAdornment: (inspectionItemNameErrors || nameError) && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <IconInputError />
                    </div>
                  ),
                }}
                inputRef={nameInputRef} 
              />
              {nameError ? (
                <span className="error-text fs-12 ml-15 txt-red">{nameError}</span>
              ) : (
                <span className="fs-12 ml-15 txt-gray-1">&#42;{t("required")}</span>
              )}
            </div>

            <div
              className="wrapper-text-field mt-8"
              style={styles.textFieldWrapper}
            >

              <TextField
                multiline
                maxRows={3}
                className="input-field input-text-area"
                id={
                  inspectionItemDesErrors || descriptionError
                    ? "outlined-error"
                    : "outlined-required"
                }
                label={t("explanation")}
                inputProps={{ maxLength: 150 }}
                error={!!inspectionItemDesErrors || !!descriptionError}
                value={prevCardDescription}
                onChange={(e) => handleDescriptionChange(e.target.value)}
                onBlur={(e) => handleDescriptionBlur(e)}
                InputProps={{
                  endAdornment: (inspectionItemDesErrors || descriptionError) && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <IconInputError />
                    </div>
                  ),
                }}
              />
              {descriptionError ? (
                <span className="error-text fs-12 ml-15 txt-red">{descriptionError}</span>
              ) : (
                <span className="fs-12 ml-15 txt-gray-1">{t("")}</span>
              )}
            </div>

            <div className="mt-16">

              <MediaUpload
                key={refreshKey}
                setFileCount={setFileCount}
                medias={localMediaFiles}
                setMedias={(files: File[]) => {
                  handleMediaUpload(files);
                }}
                id={inspectionItemId}
                typeFile={TYPE_FILE_UPLOAD.image}
                uploadDescription={uploadDescription}
              />

            </div>

            <div className="flex-centered justify-content-end mt-24">
              <Button
                className="btn-no-border"
                onClick={() => setIsOpenDeleteDialog(true)}
              >
                <IconDelete />
                <span className="txt-red ml-10 fs-14">{t("delete")}</span>
              </Button>
            </div>
          </div>
        )}
      </div>
      <ConfirmDialog
        open={isOpenDeleteDialog}
        setOpen={setIsOpenDeleteDialog}
        {...confirmProps}
      />
    </div>
  );
});
