import axios, { AxiosResponse } from "axios";
import { api, ENDPOINTS } from "@app/api";
import {
  GroupDetailInspectionFormsRequestDef,
  GroupMachinesRequestDef,
  GroupsRequestDef,
} from "@app/types";

export const apiGroups = (params: GroupsRequestDef): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.groups, { params });
};

export const apiGroupDetail = (id: string): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.groupDetail(id));
};

export const apiGroupDetailCondition = (id: string): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.groupDetailCondition(id));
};

export const apiGroupDetailInspectionForms = (
  id: string,
  params: GroupDetailInspectionFormsRequestDef
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.groupDetailInspectionForms(id), { params });
};

export const apiGroupDetailInspectionFormDelete = (
  groupId: string,
  machineId: string,
  customInspectionFormId: string,
): Promise<AxiosResponse> => {
  return api.delete(ENDPOINTS.groupDetailInspectionDeleteOrUpdate(groupId, machineId, customInspectionFormId));
};

export const apiGroupDetailInspectionFormUpdate = (
  groupId: string,
  machineId: string,
  customInspectionFormId: string,
  params: any
): Promise<AxiosResponse> => {
  return api.put(ENDPOINTS.groupDetailInspectionDeleteOrUpdate(groupId, machineId, customInspectionFormId), params);
};



export const apiGroupDetailInspectionFormStatus = (
  id: string
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.groupDetailInspectionFormStatus(id));
};

// export const executeUpdateInspectionsItemsStatusDeleteProcedure = (
//   inspectionItemId: string
// ): Promise<AxiosResponse> => {
//   return api.get(ENDPOINTS.executeUpdateInspectionsItemsStatusDeleteProcedure(inspectionItemId));
// };

export const apiGroupMachinesDetail = (
  id: string,
  params: GroupMachinesRequestDef
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.groupMachinesDetail(id), { params });
};

export const apiUpdateMachineInGroup = (
  groupId: string,
  machineId: string,
  payload: any
): Promise<AxiosResponse> => {
  return api.put(
    ENDPOINTS.updateMachineInGroup(groupId, machineId),
    payload
  );
};

export const apiUpdateMachineInGroupStatus = (
  groupId: string,
  machineId: string,
  payload: any
): Promise<AxiosResponse> => {
  return api.put(
    ENDPOINTS.updateMachineInGroupStatus(groupId, machineId),
    payload
  );
};

export const apiUpdateInspectionItemStatus = (
  groupId: string,
  machineId: string,
  inspectionItemId: string,
): Promise<AxiosResponse> => {
  return api.put(
    ENDPOINTS.updateInspectionItemStatus(groupId, machineId, inspectionItemId),
  );
};



export const apiPostMachineInGroup = (
  groupId: string,
  payload: any
): Promise<AxiosResponse> => {
  return api.post(
    ENDPOINTS.postMachineInGroup(groupId),
    payload
  );
};

export const apiGroupMachineManufacturers = (
  groupId: string
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.groupMachineManufacturers(groupId));
};

export const apiGroupMachineTypes = (
  groupId: string
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.groupMachineTypes(groupId));
};

export const apiGetMachineDetailInfo = (
  groupId: string,
  machineId: string,
): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.machineDetailInfo(groupId, machineId));
};



type UpdateInspectionFormItemsRequest = {
  formName: string;
  items: {
    customInspectionItemId: string;
    name: string;
    description: string;
  }[];
};

type UpdateInspectionFormItemsResponse = {};


export const updateInspectionFormAndItemsApiCallWeb = (
  groupId: string,
  machineId: string,
  customInspectionFormId: string,
  body: UpdateInspectionFormItemsRequest
): Promise<AxiosResponse<UpdateInspectionFormItemsResponse>> => {
  const url = ENDPOINTS.updateInspectionFormAndItemsWeb(
    groupId,
    machineId,
    customInspectionFormId
  );

  

  return api.put(url, body, {
    headers: {
      "Content-Type": "application/json", 
    },
  });
};



// type UpdateInspectionFormStatusArchive = {};

// export const updateInspectionFormStatusArchiveApiCall = (
//   groupId: string,
//   machineId: string,
//   customInspectionFormId: string
// ): Promise<AxiosResponse<UpdateInspectionFormStatusArchive>> => {
//   const url = ENDPOINTS.updateInspectionFormStatusArchive(
//     groupId,
//     machineId,
//     customInspectionFormId
//   );

//   console.log("Final API URL:", url);

//   return api.put(
//     url,
//     {}, 
//     {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     }
//   );
// };

// type UpdateInspectionFormStatusPublish = {};

// export const updateInspectionFormStatusPublishApiCall = (
//   groupId: string,
//   machineId: string,
//   customInspectionFormId: string
// ): Promise<AxiosResponse<UpdateInspectionFormStatusPublish>> => {
//   const url = ENDPOINTS.updateInspectionFormStatusPublish(
//     groupId,
//     machineId,
//     customInspectionFormId
//   );

//   console.log("Final API URL:", url);

//   return api.put(
//     url,
//     {}, 
//     {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     }
//   );
// };

type UpdateInspectionFormStatus = {};

export const updateInspectionFormStatusApiCall = (
  groupId: string,
  machineId: string,
  customInspectionFormId: string,
  status: 'ARCHIVED' | 'PUBLISHED'
): Promise<AxiosResponse<UpdateInspectionFormStatus>> => {
  const url = ENDPOINTS.updateInspectionFormStatus(
    groupId,
    machineId,
    customInspectionFormId
  );

  console.log("Final API URL:", url);

  return api.put(
    url,
    { status }, // Pass the status in the request body
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};





// Define the response type for the API
type DeleteInspectionResponse = {};

// Define the API call function
export const deleteInspectionApiCall = (
  groupId: string,
  machineId: string,
  inspectionId: string
): Promise<AxiosResponse<DeleteInspectionResponse>> => {
  const url = ENDPOINTS.deleteInspection(
    groupId,
    machineId,
    inspectionId
  );

  console.log("Final API URL:", url);

  // Make the DELETE request using axios
  return api.delete(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
