export function IconDelete() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 12 15"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 0.75V1.5H12V3H11.25V12.75C11.25 13.575 10.575 14.25 9.75 14.25H2.25C1.425 14.25 0.75 13.575 0.75 12.75V3H0V1.5H3.75V0.75H8.25ZM2.25 12.75H9.75V3H2.25V12.75ZM3.75 4.5H5.25V11.25H3.75V4.5ZM8.25 4.5H6.75V11.25H8.25V4.5Z"
        fill="#DA093B"
      />
    </svg>
  );
}
